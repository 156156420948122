@import 'assets/styles/scss/variables';

$select-height: 48px;

.logo {
	display: flex;
	width: 40px;
	margin-right: 0;
	height: 40px;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 72px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	& svg path {
		stroke: $fullBlack;
	}
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	height: 40px;
	border-radius: 8px;
	background: $table-link-background;
	cursor: pointer;
}

.head {
	display: flex;
	gap: 16px;
	margin-bottom: 32px;
	align-items: center;
}

.title {
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0;
	color: $globalTextColorMain;
}

.subtitle {
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
	text-align: left;
	color: $button-disable-border;
}

.content {
	padding: 32px;
	min-width: 480px;
}

.balance {
	margin: 12px 0 24px 16px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	&__value {
		color: $fullBlack;
		margin-right: 4px;
	}
}

.reverse {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px auto;
	width: 48px;
	height: 48px;
	background: $globalMainBlue;
	border-radius: 50%;
	cursor: pointer;
	& path {
		stroke: $globalWhite;
	}
}

.info {
	padding: 24px;
	border-radius: 12px;
	gap: 8px;
	background: $button-hover-close;
	display: flex;
	flex-direction: column;
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.status {
	display: flex;
	align-items: center;
	padding: 12px 16px 12px 16px;
	border-radius: 8px;
	border: 1px solid $globalMainBlue;
	background: $globalWhite;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	text-align: left;
	color: $fullBlack;
}

.buttons {
	display: flex;
	gap: 8px;
	margin: 24px 0;
}

.button {
	width: 50%;
	border: 1px solid $button-secondary-border;
}

.description {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.autoExchange {
	font-size: 14px;
	font-weight: 600;
	line-height: 23px;
	letter-spacing: 0;
	text-align: center;
	color: $globalMainBlue;
	margin-top: 8px;
	padding: 16px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	cursor: pointer;
}

.body {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
	&__title {
		color: $globalTextSecondary;
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
	}
}

.bodyReverse {
	flex-direction: column-reverse;
}

.qrCode {
	margin-top: 16px;
	margin-bottom: 24px;
	height: 205.5px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.copy {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
}

.warning {
	display: flex;
	align-items: flex-start;
	gap: 16px;
	border: 1px solid $globalOrange;
	border-radius: 8px;
	padding: 16px;
	margin-bottom: 24px;
	&__icon {
		min-width: 32px;
		min-height: 32px;
	}
	&__title {
		font-size: 18px;
		font-weight: 700;
		line-height: 27px;
		letter-spacing: 0;
		color: $globalTextColorMain;
		margin-bottom: 4px;
	}
	&__text {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
}

.input {
	width: 100%;
	position: relative;
}

.select {
	position: relative;
	width: 100%;
}

.value {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $select-height;
	padding: 12px 16px;
	border-radius: 8px;
	border: 1px solid $globalStrokeAndDivider;
	cursor: pointer;
}

.option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.label {
	position: absolute;
	top: 0;
	background: $globalWhite;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
	padding: 0 2px;
	left: 14px;
	transform: translateY(-50%);
	color: $button-disable-border;
}

.icon {
	width: 24px;
	height: 24px;
}

.name {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $fullBlack;
}
