@import '../../../../../assets/styles/scss/variables';

$transition: 0.2s;

.accounts {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 16px;
	margin: 16px 0;
	&__drawer {
		width: 337px;
	}
}

.cancel {
	background: $globalWhite;
	border: 1px solid $button-secondary-border;
	color: $globalBlack;
	&:hover {
		border-color: $globalMainBlue;
		background: $globalWhite;
	}
}
