body {
	.main-content {
		.tabs__item {
			text-align: center;
		}
	}
	.checkbox {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: auto;
		min-width: 0px;
		height: 0px;
		grid-gap: 0px;
		gap: 0;
		padding: 0 0px;
		background: none;
	}
	.table--market-limitations .tr {
		grid-template-columns: 1.5fr 1.5fr 1.5fr 1.75fr 1.2fr 1.25fr 0.85fr;
	}
	.fee-manegenent-line {
		margin-bottom: 15px;
		.input-notification--error {
			position: absolute;
		}
	}
}
