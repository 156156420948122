@import 'assets/styles/scss/variables';

$transition: 0.2s;

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 0;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.projects {
	margin-top: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 16px;
	gap: 16px;
}

.info {
	display: flex;
	gap: 32px;
}

.append {
	padding: 24px;
	background: $globalWhite;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 2px dashed $globalStrokeAndDivider;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	min-height: 412px;
	transition: 0.2s;
	&__icon {
		width: 40px;
		height: 40px;
		background: $globalMainBlue;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $transition;
	}
	&__text {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: $globalBlack;
		transition: $transition;
	}
	&__union {
		position: absolute;
		bottom: 59px;
		right: 41px;
		transition: $transition;
	}
	& path {
		transition: $transition;
	}
	&:hover {
		border: 2px dashed $globalWhite;
		background: $globalMainBlue;
		.append__icon {
			background: $globalWhite;
			svg,
			path {
				stroke: $globalMainBlue;
			}
		}
		.append__union {
			svg,
			path {
				fill: $globalWhite;
			}
		}
		.append__text {
			color: $globalWhite;
		}
	}
}
