@import 'assets/styles/scss/variables';

.mv_3 {
	margin: 24px 0;
}

.table {
	border: none;
	border-radius: 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	&__body {
		margin-top: 16px;
	}
}

.trBalances  {
	grid-template-columns: 1.7fr 2.3fr 1.25fr 1.25fr 1.34fr 1.95fr minmax(220px, 2.5fr);
	&_fiat {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
