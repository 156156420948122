@import 'assets/styles/scss/variables';

.tabs {
	margin: 0 0 24px -24px;
	width: calc(100% + 48px);
	padding: 0 24px 16px;
	border-bottom: 1px solid $globalStrokeAndDivider;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	&__item {
		text-align: center;
		padding: 0 8px;
		position: relative;
		font-weight: 500;
		font-size: 16px;
		color: $globalTextSecondary;
		min-width: auto;
		&:not(:last-of-type) {
			margin-right: 16px;
		}
		&::before {
			display: none;
			content: "";
			position: absolute;
			left: 0;
			bottom: -16px;
			width: 100%;
			height: 4px;
			background: $globalMainBlue;
			border-radius: 2px 2px 0 0;
		}
		&.active {
			color: $globalTextColorMain;
			&::before {
				display: block;
			}
		}
	}
}


.tabsSwitch {
	width: auto;
	display: inline-flex;
	border: none;
	background: $globalWhite;
	border-radius: 11px;
	padding: 4px;
	align-items: center;
	margin: 16px 0;
	gap: 4px;
	& .tabs__item {
		padding: 8.5px 16px;
		border-radius: 8px;
		color: $globalBlack;
		font-size: 14px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: 0;
		margin-right: 0;
		&.active {
			color: $globalWhite;
			background: $globalMainBlue;
			&::before {
				display: none;
			}
		}
	}
}
