@import 'assets/styles/scss/variables';

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 72px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	& svg path {
		stroke: $fullBlack;
	}
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	height: 40px;
	border-radius: 8px;
	background: $table-link-background;
	cursor: pointer;
}

.description {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 32px;
}

.autoExchange {
	font-size: 14px;
	font-weight: 600;
	line-height: 23px;
	letter-spacing: 0;
	text-align: center;
	color: $globalMainBlue;
	margin-top: 8px;
	padding: 16px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	cursor: pointer;
	margin-bottom: 32px;
}
