@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;
	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 8px;
		border-radius: 6px;
		box-shadow: 0 0 10px rgba(175, 183, 202, 0.28);
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.filter {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	.mb_0 {
		margin-bottom: 0;
	}
}
.filters__button {
	padding: 0;
}

.header {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	align-items: center;
	margin-bottom: 16px;
	&__button {
		height: 40px;
		padding: 8px 16px;
	}
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin: 0;
}
