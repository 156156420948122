.td-value--custom {
	text-transform: uppercase;
}

.select .select__current-arrow-custom {
	display: flex;
	justify-content: space-between;
}

.table--account-fiat .tr-custom {
	grid-template-columns: 1.5fr 1.4fr 1.4fr 1.4fr 3fr;
}

.button--size-td-custom {
	color: #4285f5;
}

.button--mt-custom {
	text-align: center;
}

.link--mt-custom {
	display: inline-block;
}

.input-item--custom {
	line-height: 50px;
}

.select-info--custom {
	display: flex;
	justify-content: flex-end;
}

.select-block--custom {
	margin-bottom: 0;
}

.progress-line .progress-line__full {
	position: absolute;
	width: auto;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 20px;
	background-color: #fd3232;
}

.limit-color-custom {
	color: #fd3232;
}

.message-error {
	color: #fd3232;

	&::first-letter {
		text-transform: uppercase;
	}
}

.wrapper-address-custom {
	display: flex;
}

.currency-custom {
	white-space: nowrap;
	align-self: center;
}

ul li p .currency-address-custom {
	white-space: nowrap;
	align-self: center;
	color: #202020;
}

.deposit-info-pop ul li .wrapper-address-custom span {
	color: #202020;
	text-align: right;

	&:first-child {
		margin-right: 10px;
	}
}

.header-custom {
	white-space: nowrap;
	align-self: center;
}

.custom-text {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 600px);
}

.tooltip-item-custom .tooltip {
	display: block;
}

// .table--finance-settings-limitations-merchant .tr {
// 	grid-template-columns: 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 0.8fr;
// }
.table--finance-settings-limitations-merchant .tr {
	grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr minmax(50px, 0.4fr);
}

.table--finance-settings-limitations-provider .tr {
	grid-template-columns: 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 0.6fr;
}

.select--max-width-155 {
	max-width: 155px;
}
