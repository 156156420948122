@import 'assets/styles/scss/variables';

.tooltip__item {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	height: 40px;
	border-radius: 8px;
	background: $table-link-background;
	cursor: pointer;
	position: relative;
	&:hover {
		.tooltip {
			display: block;
		}
	}
	&_disabled {
		cursor: default !important;
		& svg path {
			stroke: $globalGrey;
		}
		&:hover {
			.tooltip {
				display: none;
			}
		}
	}
}

.tooltip {
	display: none;
	position: absolute;
	width: auto;
	background: $tooltip-bg-color;
	border-radius: 8px;
	padding: 8px 12px;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%);
	bottom: calc(100% + 15px);
	&__text {
		display: block;
		text-align: left;
		color: $tooltip-color;
		font-size: 12px;
	}
	&:after {
		content: '';
		position: absolute;
		border: 6px solid transparent;
		border-bottom: 6px solid $tooltip-bg-color;
		bottom: -12px;
		left: 50%;
		transform: translateX(-50%) rotate(-180deg);
	}
}

@media screen and (max-width: 475px) {
	.tooltip {
		max-width: 280px;
		font-size: 11px;
	}
}
