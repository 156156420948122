@import 'assets/styles/scss/variables';

.nav {
	margin-bottom: 16px;
	transition: 0.5s ease;
	max-height: 500px;
	overflow-y: hidden;
	&_hidden {
		max-height: 0;
	}
	&__text {
		max-width: 100%;
		font-size: 14px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $globalWhite
	}

	&__icon {
		display: flex;
		justify-content: center;
		width: 24px;
		flex: 0 0 24px;

		svg path {
			stroke: $globalMainBlue;
		}
	}

	&__dot {
		min-width: 20px;
		height: 17px;
		border-radius: 4px;
		background: $globalOrange;
		text-align: center;
		line-height: 18px;
		font-size: 12px;
		margin-left: auto;
	}

	&__link {
		display: flex;
		align-items: center;
		height: 40px;
		gap: 16px;
		padding: 0 16px;
		border-radius: 12px;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: $globalBlack;
		width: 100%;

		&:hover {
			background: $hoverGlobalMainBlue;
		}

		&.active {
			background: $globalMainBlue;
			color: $globalWhite;

			.nav {
				&__icon svg path {
					stroke: $globalWhite;
				}

				&__text {
					color: $globalWhite;
				}
			}
		}
	}
}
