// Тут будут собраны кастомные изменения в grid-template-columns на разных страницах

.table-filter--orders-custom {
	grid-template-columns: 1fr 1fr 1fr 50px;
}

.table-filter--reports {
	grid-template-columns: 1fr 1fr 1fr 50px 186px;
}

.table {
	&--merchant-client-new {
		& .tr {
			grid-template-columns: 0.57fr 0.82fr 1.97fr 1.26fr 1.07fr 0.77fr 0.87fr 0.65fr 0.62fr;
		}
	}
}
.table--approvements-provider .tr {
	grid-template-columns: 30px 1.45fr 0.75fr 1.45fr 0.85fr 0.75fr 0.85fr 0.6fr 0.65fr 0.7fr 0.51fr;
}
