@import 'assets/styles/scss/variables';

.form:not(:first-of-type) .feeManagement__item {
	border-top: 1px solid $button-disable;
}
.feeManagement {
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 78px;
	}
	&__head-right {
		display: flex;
		.td {
			width: 96px;
			margin-left: 16px;
		}
	}

	&__title {
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 23.1px;
			color: $globalBlack;
		}
		span {
			font-size: 14px;
			line-height: 165%;
			color: $globalTextSecondary;
		}
	}

	&__line {
		margin-bottom: 0;
	}
	&__item &__label {
		margin-bottom: 0;
	}
	&__line {
		max-width: 100%;
		display: flex;
		gap: 16px;
		margin-right: -126px;
		@media screen and (max-width: 620px) {
			grid-template-columns: repeat(1, 1fr);
		}
		& .input {
			margin-bottom: 0;
		}
		& .button {
			min-width: 86px;
		}
	}
	&__hide {
		min-width: 96px;
	}
	&__button {
		opacity: 0;
		transition: 0.4s;
	}
	&__buttonShow {
		opacity: 1;
	}
	&__item &__input {
		width: 96px;
		height: 48px;
		border-radius: 8px;
		padding: 12px 16px 12px 16px;
		border: 1px solid $button-disable;
		color: $button-disable-border;
		font-weight: 500;
		font-size: 14px;
		line-height: 23.1px;
		-moz-appearance: textfield;
		&:focus {
			border: 1px solid #4285F5;
			color: $globalBlack;
		}
	}
	&__input::-webkit-outer-spin-button,
	&__input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

@media screen and (max-width: 1199px) {
	.tr {
		grid-template-columns: 1fr minmax(100px, 1fr);
		grid-gap: 12px;
		gap: 12px;
		border-bottom: 1px solid #EAEAEF;
		padding-bottom: 16px;
	}
	.td {
		padding: 0;
		border-bottom: unset;
		display: block;
		&_hiddenName {
			display: block;
			text-align: left;
		}
		&Right {
			justify-content: left;
		}
	}
	.table__buttons {
		justify-content: left;
	}
}

@media screen and (max-width: 620px) {
	.tr {
		display: flex;
		flex-wrap: wrap;
		grid-template-columns: repeat(1, 1fr);
	}
	.td {
		position: relative;
		width: 100%;
	}
}
