@import 'assets/styles/scss/variables';

.status {
	&__title {
		margin-bottom: 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
	}
	&__block {
		display: flex;
		align-items: center;
		grid-gap: 8px;
		gap: 8px;
		margin-top: 4px;
		font-weight: 500;
	}
	&__label {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		color: $globalTextSecondary;
	}
}
