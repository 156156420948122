.dropdown-item__link--button {
	width: 100%;
}

.project-callbacks {
	// .table-filter {
	// 	grid-template-columns: 1.75fr 1.75fr 2.65fr 1.75fr 50px 170px;
	// }
	.table--callbacks {
		.tr {
			grid-template-columns: 0.33fr 0.75fr 1.37fr 1.67fr 1.65fr 0.87fr 0.87fr 1.11fr 1.63fr;
		}
	}
}

.project-callback-info {
	.request {
		.request-info {
			padding: 16px 24px;
			height: 240px;
			font-size: 16px;
			line-height: 160%;
			background: #f2f6ff;
			border-radius: 12px;
			overflow-y: auto;
			overflow-x: hidden;
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 3px;
				background: var(--text-color2);
				cursor: pointer;
			}
		}

		.request-info {
			p {
				white-space: pre-wrap;
			}
		}
	}
}
