.invoice-popup {
	.select-block--project-select {
		margin-top: 38.5px;
		margin-bottom: 38.5px;
	}
}

.invoice-link {
	.status {
		margin-top: 4px;
		font-size: 14px;
		line-height: 165%;
		font-weight: 400;
		&.invoice-overdue {
			color: var(--red-color);
		}
		&.in-progress {
			color: #9996BE;
		}
		&.paid {
			color: var(--green-color);
		}
	}
}
