@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin-bottom: 16px;
}

.filters {
	display: grid;
	align-items: flex-end;
	gap: 12px;
	grid-template-columns: 1.65fr 1.65fr 1.55fr 1.55fr 1.2fr 50px 160px;
	margin-bottom: 16px;
}

.analytics__list {
	max-width: 1600px;
	display: flex;
	grid-gap: 28px;
	gap: 28px;
	&:not(:last-of-type) {
		margin-bottom: 64px;
	}
}
