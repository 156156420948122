@import 'assets/styles/scss/variables';

.datepicker-input {
	position: relative;
	width: 100%;
	margin-bottom: 24px;

	&__no-mb {
		margin-bottom: 0;
	}
	&__width-240 {
		width: 240px;
	}
}

.datepicker-input-wrapper {
		cursor: pointer;
		position: relative;
		* {
				cursor: pointer;
		}

		& > div input {
			padding: 0 16px;
			border-radius: 8px;
			height: 48px;

			&::placeholder {
				color: $field-text-placeholder;
				font-size: 14px;
			}
		}

		&__icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: flex;
				left: 15px;
				width: auto;
				max-width: 24px;
				max-height: 24px;
				min-width: 24px;
				min-height: 24px;
				svg {
						width: 100%;
						height: auto;
				}
				.stroke path {
						stroke: $globalTextSecondary;
				}
				&--right {
						left: unset;
						right: 16px;
				}
		}

		.datepicker-input-wrapper__icon {
				pointer-events: none;
		}
}
