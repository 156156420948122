@import 'assets/styles/scss/variables';

.modal {
	width: 416px;
	display: flex;
	flex-direction: column !important;
	align-items: center;
	gap: 8px;
}

.title {
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0;
	color: $globalTextColorMain;
}

.description {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
}

.button {
	width: 100%;
	margin-top: 16px;
	cursor: pointer;
}
