@import 'assets/styles/scss/variables';

@mixin input-placeholder {
	&.placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}

/*--------------Inputs---------------*/

.input {
	position: relative;
	width: 100%;
	margin-bottom: 24px;

	&__name {
		display: block;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
	&__notification {
		margin: 8px 0 0 0;
		font-size: 14px;
		&--error {
			color: $field-error;
		}
	}
	&--error {
		.input-item,
		.textarea {
			border: 2px solid $field-error;
		}

		.input-item {
			border-width: 1px;
			border-color: $field-error;
		}
	}

	&--success {
		.input-item {
			border: 2px solid $field-success;
		}
	}

	&--right {
		display: flex;
		justify-content: flex-end;
	}
}

.input-wrapper {
	position: relative;
}

.input-item {
	display: block;
	width: 100%;
	outline: none;
	transition: all 0.15s ease;
	height: 48px;
	padding: 0 16px;
	border: 1px solid $field-border-color;
	border-radius: 8px;
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: $field-text;

	@include input-placeholder {
		color: $field-text-placeholder;
		font-size: 14px;
		transition: all 0.15s ease;
	}

	&:not(:disabled):hover {
		z-index: 5;
		border-color: $field-border-color-hover;
	}

	&:not(:disabled):active,
	&:not(:disabled):focus {
		z-index: 5;
		border-color: $field-border-color-active;
	}

	&--left-icon {
		padding-left: 50px;
	}
	&--right-double-icon {
		padding-right: 60px;
	}
	&--right-icon-and-action {
		padding-right: 90px;
	}
	&--right-icon {
		padding-right: 50px;
	}

	&--width-240 {
		width: 240px;
	}
	&--width-340 {
		width: 340px;
	}
}

.input-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	left: 15px;
	max-width: 24px;
	max-height: 24px;
	min-width: 24px;
	min-height: 24px;

	svg {
		width: 100%;
		height: auto;
	}
	.stroke path {
		stroke: $globalTextSecondary;
	}
	&--right {
		left: unset;
		right: 16px;
	}
}

input[type='password'] ~ .show-pass {
	.password-type {
		display: block;
	}
	.text-type {
		display: none;
	}
}

/*--------------End_Inputs-----------*/
