.td .table-buttons .table-buttons-drop__item-custom button {
	width: 100%;
	max-width: 100%;
	height: 39px;
	color: #202020;
}

.table-filter--orders-custom {
	grid-template-columns: 1fr 1fr 1fr;
}

.status-custom {
	&::first-letter {
		text-transform: uppercase;
	}
}

.new {
	color: #202020 !important;
}

.paid {
	color: var(--green-color) !important;
}

.underpaid {
	color: var(--red-color) !important;
}

.overpaid {
	color: #9996be !important;
}

.overdue {
	color: #9996be !important;
}

.overdue-and-paid {
	color: #00ba88 !important;
}

.returned {
	color: #0060ba !important;
}

body .checkbox-custom {
	height: auto;
	display: block;
}

.input-item-custom {
	display: flex;
	align-items: center;
}
