@import 'assets/styles/scss/variables';

.select-block {
		&__last-elem {
				margin-bottom: 0;
		}

		&__notification {
				margin: 8px 0 0 0;
				font-size: 14px;
				&--error {
						color: $field-error;
				}
		}
}
