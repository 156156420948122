.table-buttons {
	.table-buttons-drop {
		button {
			flex: 1;
			max-width: 100%;
			width: 100%;
		}
	}
}
.table--provider-wallet-addresses--client .tr {
	grid-template-columns: 1fr 1fr 3.75fr 1.1fr 1.1fr 1.3fr 1.3fr;
}
.table--market-limitations.table--market-limitations--custom .tr {
	grid-template-columns: 1.5fr 1.5fr 1.5fr 1.75fr 1.2fr 1.2fr 1.25fr 0.85fr 1fr;
}
.table--project-wallets-transactions-history-fiat .tr {
	grid-template-columns: 1fr 1fr 3.75fr 1.1fr 1.1fr 1.3fr 1.3fr 1.3fr;
}
