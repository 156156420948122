.tableContainer {
	width: 629px;
	margin: 0 auto;
	align-items: center;
	&.tableHeader {
		height: 59px;
	}
	.td {
		padding: 0;
	}
}
