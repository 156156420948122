input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.button {
	gap: 12px;
	height: 50px;
	padding: 0 24px;
	border-radius: 12px;
	background: var(--main-color);
	font-weight: 600;
	font-size: 16px;
	line-height: 110%;

	&:hover {
		background: darken(#4285f5, 10%);
	}

	&--type2 {
		background: transparent;
		border: 1px solid var(--border-color-main);
		color: var(--text-color2);

		&:hover {
			background: transparent;
			border-color: var(--main-color);
		}
	}

	&--type3 {
		background: transparent;
		border: 1px solid var(--main-color);
		color: var(--text-color-main);

		&:hover {
			color: #fff;
			background: var(--main-color);
		}
	}

	&--type4 {
		background: #fd3232;
		&:hover {
			background: #d82b2b;
		}
	}

	&--type5 {
		background: #00ba88;
		&:hover {
			background: #00916a;
		}
	}

	&--type6 {
		color: var(--text-color2);
		background: #fff;
		&:hover {
			color: #fff;
			background: var(--main-color);
		}
	}

	&--type7 {
		color: #4285f5;
		background: #f2f6ff;
		&:hover {
			color: #4285f5;
			background: #e9f0ff;
		}
	}

	&--type8 {
		border: 1px solid #fd3232;
		color: #fd3232;
		background: #fff;
		&:hover {
			color: #fff;
			background: #fd3232;
		}
	}
	&--type9,
	&--link {
		height: auto;
		background: transparent;
		color: #4285f5;
		padding: 0;

		&:hover {
			background: transparent;
			color: #4285f5;

			.btn-icon svg path {
				stroke: #4285f5;
			}
		}
	}

	&:disabled {
		background: #b6b6b6;
		opacity: 1;
		color: #fff;

		&:hover {
			background: #b6b6b6;
		}
	}

	&--width-auto {
		width: auto;
		min-width: auto;
	}

	&--long {
		width: 275px;
	}

	&--medium {
		width: 200px;
	}
	&--w209 {
		width: 209px;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&--size2 {
		min-width: auto;
		padding: 0 15px;
		height: 39px;
		font-size: 14px;
	}

	&--size-td {
		min-width: auto;
		padding: 0 16px;
		flex-direction: column;
		justify-content: center !important;
		align-items: center !important;
		gap: 2px;
		height: 47px;
		font-size: 10px;
	}

	&--icon {
		min-width: auto;
		padding: 0;
		width: 50px;
		height: 50px;
	}

	&--fs-14 {
		font-size: 14px;
	}
}

.input {
	margin-bottom: 24px;

	&--no-mb {
		margin-bottom: 0;
	}

	&--error {
		.input-item {
			border-width: 1px;
			border-color: var(--red-color);
		}
	}

	&--width-260 {
		width: 260px;
	}
	&--max-width-100 {
		max-width: 100px;
	}

	&--right {
		display: flex;
		justify-content: flex-end;
	}
}

.input-item {
	height: 50px;
	padding: 0 16px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: var(--text-main-color);

	@include input-placeholder {
		color: var(--text-color2);
		font-size: inherit;
		transition: all 0.15s ease;
	}

	&--type-td {
		height: 47px;
		font-size: 14px;
	}

	&--max-width-100px {
		max-width: 100px;
	}

	&--max-width-56px {
		max-width: 56px;
	}

	&--left-icon {
		padding-left: 50px;
	}

	&--pr-125 {
		padding-right: 125px;

		@media screen and (max-width: 767px) {
			padding-right: 115px;
		}
	}

	&--pr-75 {
		padding-right: 75px;
	}

	&--right-icon {
		padding-right: 50px;
	}

	&--blue-border {
		border: 1px solid #4285f5;
		border-radius: 12px;
	}
	&--pr120 {
		padding-right: 120px;
	}
}

.input-icon {
	left: 15px;
	width: auto;
	max-width: 20px;

	&--right {
		left: unset;
		right: 16px;
	}
}

.input-action {
	max-width: 20px;
	&--right {
		left: unset;
		right: 16px;
	}
}

.textarea {
	padding: 8px 14px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	textarea {
		min-height: 100px;
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-main-color);
		resize: none;
		@include input-placeholder {
			color: var(--text-color2);
			font-size: inherit;
			transition: all 0.15s ease;
		}
	}
}

.select-block {
	margin-bottom: 24px;
	&--width-302 {
		width: 302px;
	}
	&__name {
		min-height: 21px;
	}
	&__title {
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}

	&--project-select {
		margin-bottom: 0;
		.select {
			height: auto;
		}
		.select__current-arrow svg path {
			stroke: var(--text-color-main);
		}
		.token--type3 .token__name {
			font-weight: 600;
			font-size: 16px;
		}
		@media screen and (max-width: 991px) {
			.select__current {
				padding: 0;
			}
		}
	}
}

.select {
	height: 50px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	&__current {
		justify-content: flex-start;
		gap: 12px;
		padding: 0 16px;
		font-weight: 400;
		font-size: 16px;

		&--placeholder {
			color: var(--text-color2);
		}
	}
	&__current-arrow {
		margin-left: auto;
		flex: 0 0 14px;
		max-width: 14px;

		&--tooltip {
			flex: 0 0 22px;
			max-width: 22px;
		}
	}
	&__drop {
		border: 1px solid var(--border-color-main);
		border-radius: 12px;
		padding: 0;
		overflow: hidden;
		> .input {
			padding: 24px 24px 0 24px;
		}
		&--type2 {
			width: 340px;
			left: auto;
			right: 0;
			@media screen and (max-width: 1280px) {
				width: 100%;
			}
		}
		ul {
			> li {
				> a,
				> button {
					min-height: 46px;
					padding: 14px 10px;
					border-radius: 0;
				}
				&:first-child {
					> a,
					> button {
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
					}
				}
				&:last-child {
					> a,
					> button {
						border-bottom-left-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}
			}
		}
	}
	&__drop-scroll {
		max-height: 230px;
		//border-radius: 12px;
	}
	&__drop-item {
		.input {
			padding: 24px;
		}
	}

	&--type-choice {
		.select {
			&__drop-scroll {
				max-height: 294px;
			}
			&__drop-item {
				ul {
					li {
						&:last-child {
							button {
								border: none;
								border-radius: 0 0 10px 10px;
							}
						}
						&:first-child {
							button {
								border-radius: 0;
							}
						}
					}
				}
				button {
					padding: 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #eaeaef;
					border-radius: 0;
					span {
						svg {
							opacity: 0;
						}
					}

					&:first-child {
					}

					&.active {
						background: #f2f6ff;

						span {
							svg {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&--type-checkbox {
		.select {
			&__drop-scroll {
				max-height: 240px;
			}
			&__drop-item {
				ul {
					li {
						padding: 12px;
						border-bottom: 1px solid #eaeaef;
						&:last-child {
							border: none;
						}
						.checkbox {
							&__text {
								color: #202020;
							}
						}
					}
				}
			}
		}
	}
	&--type-choice-type2 {
		border: none;
	}
	&--td {
		min-width: 100px;
		display: inline-flex;
	}
	&--width-540 {
		max-width: 100%;
		width: 540px;
	}
	&--max-width-120 {
		max-width: 120px;
	}
	&--height-55 {
		height: 55px;
	}
}

.show-pass {
	width: 20px;

	svg path {
		fill: transparent;
		stroke: var(--icon-color);
	}
}

.checkbox {
	&__label {
		gap: 11px;
	}

	&__item {
		width: 18px;
		height: 18px;
		flex: 0 0 18px;
		border: 2px solid #9996be;
	}

	&__text {
		margin: 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);

		a {
			text-decoration: none !important;
			color: var(--main-color);
			font-weight: 500;
		}
	}

	&__item-icon {
		width: 12px;
	}

	input:checked ~ .checkbox__item {
		background: var(--main-color);
		border-color: var(--main-color);

		.checkbox__item-icon {
			svg path {
				fill: #fff;
			}
		}
	}
	&--mt4 {
		margin-top: 4px;
	}
}

.tooltip {
	&--no-arrow {
		&:after {
			display: none;
		}
	}
}

.td-name,
.td-hidden-name {
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color2);
}

.td-hidden-name {
	display: none;

	@media screen and (max-width: 1199px) {
		display: flex;
		margin-bottom: 6px;
	}
}

.table-value {
	a {
		color: var(--main-color);

		&:hover,
		&:focus {
			color: darken(#4285f5, 10%);
		}
	}
}

.table {
	border: none;
	border-radius: 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	.tr {
		gap: 6px;
		@media screen and (max-width: 1199px) {
			gap: 12px;
		}
	}
}

.td {
	padding: 0;
	&--center {
		* {
			text-align: center;
			justify-content: center;
			@media screen and (max-width: 1199px) {
				justify-content: flex-start;
				text-align: left;
			}
		}
		.tooltip-item {
			display: flex;
		}
		@media screen and (max-width: 1199px) {
			text-align: left;
		}
	}
	&--right {
		text-align: right;
		@media screen and (max-width: 1199px) {
			text-align: left;
		}
		* {
			justify-content: flex-end;
			@media screen and (max-width: 1199px) {
				justify-content: flex-start;
			}
		}
	}
	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		padding-right: 0;
	}
	.select__current {
		font-size: 14px;
	}
}

.td-value {
	&--text-overflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.table-buttons {
	gap: 12px;
	button {
		flex: 0 0 23px;
		max-width: 23px;
	}
	button,
	a {
		&.button {
			flex: none;
			max-width: 100%;
			min-width: 72px;
		}
	}
}

.table-header {
	.tr {
		border-bottom: 1px solid var(--border-color-main);
	}

	.td {
		padding-bottom: 12px;
	}

	@media screen and (max-width: 1199px) {
		display: none;
	}
}

.table-body {
	margin-top: 16px;

	.tr {
		border: none;

		& + .tr {
			margin-top: 24px;
		}

		@media screen and (max-width: 1199px) {
			grid-template-columns: repeat(2, 1fr) !important;
			gap: 12px;
			border-bottom: 1px solid var(--border-color-main);
			padding-bottom: 16px;
			align-items: flex-start;
		}
	}
}

.tr {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: -12px;
		top: -18px;
		width: calc(100% + 24px);
		height: calc(100% + 36px);
		z-index: -1;
		opacity: 0;
		border-radius: 12px;
		background: #f2f6ff;
		transition: 0.3s;
	}
	&.active {
		&::before {
			opacity: 1;
		}
		.button--type7 {
			background: #ffffff;
		}
	}
}

.pagination-block {
	margin-top: 40px;
	&--m0 {
		margin: 0;
	}
}

.pagination {
	gap: 8px;

	> li {
		padding: 0;

		> a,
		> button {
			margin: 0;
			width: 39px;
			height: 39px;
			border: 1px solid var(--border-color-main);
			border-radius: 12px;
			color: var(--text-color-main);
			font-weight: 500;
			font-size: 14px;
			line-height: 165%;

			&.arrow svg path {
				stroke: var(--icon-color);
			}

			&.active,
			&:hover {
				background: var(--main-color);
				border-color: var(--main-color) !important;
				color: #fff;

				&.arrow svg path {
					stroke: #fff;
				}
			}
		}

		:hover,
		&:not(:disabled):hover {
			> a,
			> button {
				background: var(--main-color);
				color: #fff;
				border-color: var(--main-color) !important;

				&.arrow svg path {
					stroke: #fff;
				}
			}
		}
	}
}

.tooltip-icon {
	min-width: 15px;
}

.tooltip {
	word-break: normal;
	padding: 10px 12px;
	border-radius: 12px;
	z-index: 100;
	&--top {
		bottom: calc(100% + 15px);
	}
	&--width-auto {
		width: auto;
	}
	&--width-320 {
		width: 320px;
	}
	&--width-170px {
		width: 170px;
	}
	&--arrow-bottom-center {
		&:after {
			bottom: -18px;
		}
	}
	&--center {
		@media screen and (max-width: 720px) {
			left: 0;
			transform: none;
		}
	}
	&--center {
		@media screen and (max-width: 720px) {
			left: 0;
			transform: none;
		}
	}
	&:after {
		border-width: 9px;
	}
	&__text {
		font-size: 12px;
		font-weight: 400;
		&--center {
			text-align: center;
		}
	}
}

.switch {
	&__label {
		display: flex;
		gap: 24px;
	}
	&__toggler {
		width: 36px;
		height: 20px;
		border-color: #eaeaef;
		background: #eaeaef;
		&::before {
			width: 14px;
			height: 14px;
			background: #fff;
		}
	}
	&__text {
		font-size: 14px;
		color: #777e90;
	}
	input:checked ~ .switch__toggler {
		border-color: var(--main-color);
		background: var(--main-color);
		&:before {
			left: 19px;
			background: #fff;
		}
	}
}

.notification {
	width: 422px;
	border-radius: 12px;

	@media screen and (max-width: 520px) {
		width: 280px;
	}
	&--icon-error {
		border: 1px solid #fd3232;
		.notification {
			&__title {
				font-size: 18px;
			}
			&__text {
				margin-top: 0;
				font-size: 14px;
			}
			&__info {
				padding: 24px;
				padding-left: 0;
			}
			&__icon {
				width: 64px;
				min-width: 64px;
				background: none;
				svg {
					width: 24px;
				}
			}
		}
	}
	&--icon-successful {
		border: 1px solid #00ba88;
		.notification {
			&__title {
				font-size: 18px;
			}
			&__text {
				margin-top: 0;
				font-size: 14px;
			}
			&__info {
				padding: 24px;
				padding-left: 0;
			}
			&__icon {
				width: 64px;
				min-width: 64px;
				background: none;
				svg {
					width: 24px;
				}
			}
		}
	}
	&--icon-error {
		border: 1px solid #fd3232;
		.notification {
			&__title {
				font-size: 18px;
			}
			&__text {
				margin-top: 0;
				font-size: 14px;
			}
			&__info {
				padding: 24px;
				padding-left: 0;
			}
			&__icon {
				width: 64px;
				min-width: 64px;
				background: none;
				svg {
					width: 24px;
				}
			}
		}
	}
	&__icon {
		border-radius: 8px 0 0 8px;
	}
}

.upload-file-box-title {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
	@media screen and (max-width: 720px) {
		margin-top: 12px;
	}
}

.upload-file-box {
	width: 100%;
	max-width: 100%;
	height: 322px;
	border-color: var(--main-color);
	@media screen and (max-width: 1320px) {
		width: 100%;
	}
	&:hover {
		border-color: var(--main-color);
		.upload-file {
			&__label {
				color: var(--main-color);
			}
		}
	}
}

.upload-file {
	margin-top: -46px;
	&__icon {
		width: 24px;
		&--big {
			width: 61px;
		}
	}
	&__icon-upload {
		display: block;
		max-width: 120px;
		margin: 0 auto;
		img {
			max-width: 100%;
		}
	}
	&__title {
		margin-top: 12px;
		font-weight: 700;
		font-size: 16px;
		color: var(--main-color);
	}
	&__label {
		padding: 8px 14px;
		border: 1px solid var(--main-color);
		border-radius: 12px;
	}
	&__size {
		position: absolute;
		left: 50%;
		bottom: 16px;
		font-size: 12px;
		color: var(--text-color2);
		transform: translateX(-50%);
	}
	&__buttons {
		margin-top: 16px;
		display: flex;
		justify-content: center;
		gap: 16px;
	}
}

.show-pass {
	svg path {
		stroke: #4285f5;
	}
	&--grey {
		svg path {
			stroke: #9996be;
		}
	}
}

.radio {
	margin-bottom: 16px;
	&__item {
		min-width: 20px;
		width: 20px;
		height: 20px;
		flex: 0 0 20px;
		border: 2px solid #9996be;
	}
	&__text {
		font-size: 16px;
		line-height: 160%;
	}

	input:checked ~ .radio {
		&__item {
			border-color: #4285f5;
			background: none;
			&::after {
				width: 8px;
				height: 8px;
				background: #4285f5;
			}
		}
	}
}
