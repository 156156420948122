@import 'assets/styles/scss/variables';

.popup {
  background: rgba(153, 150, 190, 0.7);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 201;
  &__window {
    position: relative;
    max-width: 100%;
    border-radius: 10px;
    background: var(--ui-popup-bg);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 40px 32px;
    width: 540px;
  }
  &__inside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  }
  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
  }
  &__body {
    margin-top: 24px;
  }
  &__title {
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    color: var(--text-color-main);
  }
  &__button {
    flex-direction: row;
    margin-top: 26px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
  }
}

.select {
  &Block {
    margin-bottom: 24px;
    &__name {
      min-height: 21px;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &Info {
    margin-top: 8px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;

    &__name {
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
      color: var(--text-color2);
    }

    &__number {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      color: var(--text-color-main);
    }
  }
}

.input {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  &__name {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 165%;
  }
  &__wrapper {
    position: relative;
  }
  &__item {
    height: 50px;
    padding: 0 16px;
    border: 1px solid var(--border-color-main);
    border-radius: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--text-main-color);
    display: block;
    width: 100%;
    outline: none;
    transition: all 0.15s ease;
  }
}

.textarea {
  padding: 8px 14px;
  border: 1px solid var(--border-color-main);
  border-radius: 12px;
  & textarea {
    min-height: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--text-main-color);
    resize: none;
    display: block;
    width: 100%;
    background: none;
    border: none;
    outline: none !important;
    scrollbar-color: #b6b6b6 transparent;
    scrollbar-width: thin;
  }
}

.button {
  grid-gap: 8px;
  gap: 8px;
  height: 50px;
  padding: 0 24px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  cursor: pointer;
  transition: all 0.15s ease;
  background: $globalMainBlue;
  border: none;
  color: #fff;
  &:disabled {
    background: #eaeaef;
    opacity: 1;
    color: #9996be;
    border: none;
  }
  &_cancel {
    background: transparent;
    border: 1px solid $globalStrokeAndDivider;
    color: $globalTextSecondary;
  }
}


