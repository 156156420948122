body {
	.table--provider-administration-team-members .tr {
		align-items: center;
	}
}

// 📌 AddNewAdmin

.select-block--error .select {
	border: 1px solid var(--ui-error-color);
}

// 📌 UserProfile

.table--user-profile-role-project {
	border-bottom: 1px solid var(--border-color-main);

	.tr {
		grid-template-columns: 4fr 1fr;
	}
}
