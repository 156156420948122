@import '../../../../assets/styles/scss/variables';

.table-plug {
	padding: 64px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__icon {
		max-width: 120px;
		margin-bottom: 12px;
	}

	&__text {
		margin-bottom: 32px;
		font-weight: 400;
		font-size: 16px;
		color: $table-text-color;
	}

	&__with-header {
		margin-top: 0;
		border-top: none;
	}
}
