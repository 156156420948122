@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__background {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;

	}
	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

