@import 'assets/styles/scss/variables';

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 72px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	& svg path {
		stroke: $fullBlack;
	}
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	height: 40px;
	border-radius: 8px;
	background: $table-link-background;
	cursor: pointer;
}
