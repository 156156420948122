@import 'assets/styles/scss/variables';

.tableHeader {
	display: flex;
	flex-wrap: nowrap;
	height: 59px;
	width: 629px;
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	&_right {
		display: flex;
	}
}

.td {
	width: 96px;
	&:not(:first-child) {
		margin-left: 16px;
	}
	&__name {
		display: flex;
		align-items: center;
		grid-gap: 4px;
		gap: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: $globalTextSecondary;
	}
}

@media screen and (max-width: 1199px) {
	.tableHeader {
		display: none;
	}
}
