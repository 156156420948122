@import 'assets/styles/scss/variables';

html:has(.drawer:hover) {
	overflow: hidden;
}

.drawer {
	position: absolute;
	background: $globalWhite;
	box-shadow: 0 4px 21px 0 rgba(163, 182, 212, 0.38), 0 2px 3px 0 rgba(163, 182, 212, 0.46);
	height: 100%;
	right: 0;
	left: 0;

	&:hover {
		pointer-events: all;
	}

	&-layout {
		position: fixed;
		height: 100%;
		width: 290px;
		right: 0;
		top: 0;
		z-index: 900;
		transform: translateX(100%);
		transition-duration: 0.3s;

		&__active {
			transform: translateX(0);
		}
	}

	&-header {
		padding: 24px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $globalStrokeAndDivider;
		padding-bottom: 23px;
		align-items: center;
		&__left {
			display: flex;
			align-items: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
		}

		&__right {
			display: flex;
			gap: 4px;
		}
	}

	&-body {
		padding: 32px 24px 0;
		overflow-x: hidden;
		scroll-behavior: smooth;
		&::-webkit-scrollbar {
			background: $scrollColor;
		}
	}

	&-footer {
		border-top: 1px solid $globalStrokeAndDivider;
		padding: 15px 24px 16px;
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		gap: 8px;
	}
}


