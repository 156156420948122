@import 'assets/styles/scss/variables';

.header {
	display: flex;
	align-items: center;
	height: 72px;
	background: $globalBlack;
	border-bottom: 1px solid $layoutBorder;
  border-left: 1px solid $globalBlack;
  margin-left: calc($globalSidebarWidth - 1px);
  z-index: 2;
	padding-left: 0;
	padding-right: 32px;
	border-left: 1px solid $button-disable-border;
	transition: 0.2s;
	&__rollup {
		min-width: 28px;
		min-height: 28px;
		max-width: 28px;
		max-height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 1px solid $button-disable-border;
		background: $globalBlack;
		transform: translateX(-14px);
		cursor: pointer;
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
	}
	&__userType {
		color: $globalWhite;
	}
	&__right {
		margin-left: auto;
		height: 100%;
	}
	&__info {
		display: flex;
		margin-left: auto;
		height: 100%;
	}
	&__companies {
		display: flex;
		align-items: center;
		padding: 0 25px 0 0;
		height: 100%;
		background: $globalBlack;
	}

	&.active {
		margin-left: calc($globalShortSidebarWidth - 1px);
		.header__rollup svg {
			transition: 0.2s;
			transform: rotateY(180deg);
		}
	}
	@media screen and (max-width: 1360px) {
		padding-right: 25px;
	}

	@media screen and (max-width: 991px) {
		padding-right: 16px;
	}
}

