.select-block--custom {
	margin-bottom: 0;
}

.sidebar-active {
	@media screen and (max-width: 991px) {
		display: block;
		width: 100%;
	}
}
