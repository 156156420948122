@import 'assets/styles/scss/variables';

.buttons {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	gap: 12px;
	width: 100%;
	& > div {
		cursor: pointer;
	}
}

.right {
	justify-content: flex-end;
}
