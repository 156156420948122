.schedule-width {
	width: 100%;
}

.center-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.analytics-list-custom {
	border: none;
	padding: 0;
}
