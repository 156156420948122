@import 'assets/styles/scss/variables';

.users {
	margin-top: 24px;
	display: grid;
	grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
	grid-gap: 16px 40px;
	gap: 16px 40px;
}

.user {
	padding: 24px;
	border: 1px solid $button-border;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		margin: 0;
		& b {
			font-weight: 900;
		}
	}

	&__value {
		font-size: 20px;
		font-weight: 600;
	}
}
