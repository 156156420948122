@import 'assets/styles/scss/variables';

$select-height: 48px;

.input {
	width: 100%;
}

.select {
	position: relative;
	width: 100%;
}

.value {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $select-height;
	padding: 12px 16px;
	border-radius: 8px;
	border: 1px solid $globalStrokeAndDivider;
	cursor: pointer;
}

.option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.icon {
	width: 24px;
	height: 24px;
}

.name {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $fullBlack;
}

.code {
	color: $button-disable-border;
	text-transform: uppercase;
	margin-left: 4px;
}

.placeholder {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
}

.balance {
	margin: 12px 0 24px 16px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	&__value {
		color: $fullBlack;
		margin-right: 4px;
	}
}

.label {
	position: absolute;
	top: 0;
	background: $globalWhite;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
	padding: 0 2px;
	left: 14px;
	transform: translateY(-50%);
	color: $button-disable-border;
}

.list {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: $select-height + 1;
	width: 100%;
	background: $globalWhite;
	z-index: 5;
	max-height: 180px;
	overflow-y: hidden;
	border-radius: 8px;
	border: 1px solid $globalStrokeAndDivider;
	&__li {
		height: $select-height;
		padding: 12px 16px;
		cursor: pointer;
		display: flex;
		&:hover {
			background: $button-hover-close;
		}
	}
	& .active {
		cursor: default;
		background: $hoverGlobalMainBlue;
	}
}
