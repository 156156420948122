@import 'assets/styles/scss/variables';

.role {
	padding-top: 24px;
	&:not(:last-of-type) {
		border-bottom: 1px solid $button-border;
		padding-bottom: 24px;
	}
	&__title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
	}
	&__text {
		font-size: 14px;
		line-height: 165%;
		color: $globalTextSecondary;
	}
}

