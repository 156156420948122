@import 'assets/styles/scss/variables';

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin: 0;
	margin-bottom: 24px;
}

.tableBlock {
	padding-bottom: 24px;
}

.table {
	margin-top: 0;
}
