@import 'assets/styles/scss/variables';

.select-block {
	margin-bottom: 24px;
	&--max-width-120 {
		max-width: 120px;
	}
	&--width-302 {
		width: 302px;
	}
	&__name {
		min-height: 21px;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
	&__title,
	&__last-elem,
	&__table {
		margin-bottom: 0;
	}
	&--error .select {
		border: 1px solid $globalRed;
	}
}

.select {
	position: relative;
	display: flex;
	align-content: center;
	border: 1px solid $globalStrokeAndDivider;
	background: #fff;
	z-index: 3;
	height:  48px;
	border-radius: 8px;
	&--height-55 {
		height: 55px;
	}
	&--height-58 {
		height: 58px;
	}
	&--disabled {
		pointer-events: none;
		.select__current {
			color: $globalStrokeAndDivider;
		}
		.select__current-arrow svg path {
			stroke: var(--border-color-main);
		}
	}
	&__current {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: 10px;
		padding: 0 16px;
		font-weight: 500;
		font-size: 14px;
		z-index: 4;
		&--placeholder {
			color: $field-text-placeholder;
		}
		&--arrow-start {
			justify-content: inherit;
		}

		& > * {
			pointer-events: none;
		}
	}
	&__current-extra {
		margin-left: 8px;
		color: var(--text-color2);
	}
	&__current-input {
		position: relative;
		display: flex;
		width: 100%;
		padding-right: 16px;
		.input-item {
			border: none;
			height: 100%;
			background: transparent;
		}
	}
	&__current-arrow {
		display: flex;
		flex: 0 0 24px;
		max-width: 24px;
		justify-content: center;
		margin-left: auto;
		min-width: 24px;
		max-height: 24px;
		min-height: 24px;
		align-items: center;
		.stroke path {
			stroke: #424242;
		}
		svg {
			path {
				stroke: $globalTextSecondary;
			}
		}
		&.active {
			path {
				stroke: $globalMainBlue;
			}
		}
		&--start {
			justify-content: flex-start;
			margin-right: 6px;
			margin-left: 0;
		}
	}
	&__current-text {
		margin-right: auto;
	}
	&__img {
		display: flex;
		width: 16px;
		align-items: center;
		margin-right: 12px;
	}
	&__drop {
		display: none;
		position: absolute;
		left: 0;
		top: calc(100% + 10px);
		width: 100%;
		background: #ffffff;
		z-index: 7;
		border: 1px solid var(--border-color-main);
		border-radius: 8px;
		padding: 0;
		box-shadow: 0 4px 21px 0 rgba(#A3B6D4, 0.38);
		opacity: 0;
		transition-delay: 0s;

		&--width-240 {
			width: 240px;
		}
		&--width-360 {
			width: 360px;
		}
		&--width-340 {
			width: 330px;
			@media screen and (max-width: 720px) {
				max-width: 100%;
			}
		}
		&--top {
			transform: translateY(-100%);
			top: -10px;
			opacity: 1;
		}
		&--bottom {
			opacity: 1;
		}
		.select__drop-list {
			> .select__drop-list--item {
				> a,
				> button {
					display: block;
					width: 100%;
					padding: 0 16px;
					border-radius: 4px;
					color: #000;
					font-size: 14px;
					transition: all 0.15s ease;
					text-align: left;
					font-weight: 500;
					line-height: 23px;
					height: 40px;
					&:hover {
						background: $globalBGLight;
					}
				}
			}
		}
	}
	&__drop-scroll {
		max-height: 188px;
		overflow: auto;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;
		box-shadow: 0 2px 3px 0 rgba(#A3B6D4, 0.46);

		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: #b6b6b6;
		}
	}
	&:hover {
		border-color: $globalTextSecondary;
	}
	&.active {
		z-index: 5;
		border-color: $globalMainBlue;
		.select__drop {
			display: block;
		}
		.select__current-arrow {
			transform: rotate(180deg);
		}
	}
	&.error {
		border-color: $globalRed;
	}
	&--small {
		padding: 0;
		border: 0;
		width: 62px;
		z-index: auto;
		.select__drop {
			padding: 0;
			min-width: 100px;
		}
	}
	&--grey {
		background: #dadada;
		padding: 0 15px;
		width: 74px;
	}
	&--light {
		.select__current {
			color: #b6b6b6;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
		}
	}
	&--big {
		height: 52px;
		font-size: 16px;
	}
	&--type-choice {
		.select {
			&__drop {
				padding: 0;
			}
			&__drop-scroll {
				max-height: 294px;
			}
			&__drop-item {
				.select__drop-list {
					&--item {
						padding: 4px;
						button {
							border: none;
							border-radius: 8px;
						}
					}
				}
				button {
					padding: 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #eaeaef;
					border-radius: 0;
					span {
						svg {
							opacity: 0;
						}
					}

					&:first-child {
					}

					&.active {
						background-color: $hoverGlobalMainBlue;

						span {
							svg {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&--type-checkbox {
		.select {
			&__drop-scroll {
				max-height: 240px;
			}
			&__drop-item {
				.select__drop-list {
					&--item {
						padding: 12px;
						border-bottom: 1px solid #eaeaef;
						&:last-child {
							border: none;
						}
						.checkbox {
							&__text {
								color: #202020;
							}
						}
					}
				}
			}
		}
	}
	&--type-choice-type2 {
		border: none;
	}
	&--type-choice-type3 {
		.select {
			&__drop-item {
				button {
					span {
						svg {
							opacity: 1;
						}
					}
					&.active {
						background: #f2f6ff;
					}
				}
			}
		}
	}
	&--td {
		min-width: 100px;
		display: inline-flex;
	}
	&--width-540 {
		max-width: 100%;
		width: 540px;
	}
	&--show-chart {
		.select__drop {
			right: 0;
			left: unset;
		}
		.select__drop-scroll {
			max-height: 454px;
		}
	}
	&--drop-only {
		height: auto;
		border: none;
		border-radius: 0;
		.select__drop {
			position: relative;
			top: 0;
			left: 0;
		}
	}
}
