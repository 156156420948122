@import '../scss/variables';

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
	height: 7px;
	background-color: rgba($globalBGLight, .5);
	-webkit-box-shadow: 0 0 1px 1px $globalTextSecondary;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0,0,0,.5);
	-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
