@import 'assets/styles/scss/variables';

.dialog {
	overflow-y: visible;
	& &__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 88px;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		text-align: left;
		color: $globalTextColorMain;
	}
	& &__content {
		padding-top: 4px !important;
		padding-bottom: 0;
		width: 440px;
		overflow-y: visible;
	}
	& &__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		height: 96px;
		padding: 24px;
	}
}

.open {
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: 0;
	text-align: left;
	border-radius: 8px;
	border: 1px solid $globalMainBlue;
	background: $globalWhite;
	color: $globalBlack;
	padding: 10px 24px;
	cursor: pointer;
	&:hover {
		background: $globalMainBlue;
		color: $globalWhite;
	}
}


.cancel {
	border: 1px solid $globalStrokeAndDivider;
}
