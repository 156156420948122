@import 'assets/styles/scss/variables';

.main {
	margin-left: calc($globalSidebarWidth - 1px);
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	transition: 0.2s;
	&.active {
		margin-left: calc($globalShortSidebarWidth - 1px);
	}
}
