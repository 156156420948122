@import 'assets/styles/scss/variables';

.filter {
	&__area {
		border-radius: 12px;
		background: $globalBGLight;
		flex-shrink: 1;
		height: 48px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		padding: 0 8px;
	}
	&__button {
		margin-left: 16px;
		height: 48px;
	}
	&__delete {
		width: 76px;
		height: 48px;
	}
	&-item {
		display: flex;
		align-items: center;
		gap: 6px;
		height: 26px;
		padding-left: 12px;
		padding-right: 3px;
		border-radius: 16px;
		background: $globalBlack;
		color: $globalWhite;
		line-height: 23px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		white-space: nowrap;

		&--label {
			color: $globalTextSecondary;
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
		}

		&--delete {
			width: 20px;
			height: 20px;

			path {
				fill: $globalTextSecondary;
			}

			&:hover {
				cursor: pointer;
				path {
					fill: #fe9999;
				}
			}
		}
	}
	&__wrapper {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
	}
	&__content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;
	}
}

.filters {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-shrink: 0;
	&__buttons {
		display: flex;
		gap: 16px;
		flex-shrink: 0;
		margin-right: 16px;
	}
}

