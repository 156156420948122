@import 'assets/styles/scss/variables';
$icon-size: 40px;

.card {
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 8px;
	gap: 24px;
	background: $globalWhite;
	position: relative;
	box-shadow: 0 5px 12px 0 $boxShadowColor;
}

.icon {
	max-width: $icon-size;
	max-height: $icon-size;
	min-width: $icon-size;
	min-height: $icon-size;
	& svg {
		max-width: $icon-size;
		max-height: $icon-size;
		min-width: $icon-size;
		min-height: $icon-size;
	}
}

.label {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
}

.value {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $globalBlack;
	white-space: pre-wrap;
	&:hover .copy__value {
		color: $globalMainBlue;
	}
}

.header {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0;
	color: $globalBlack;
	gap: 16px;
}

.available {
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
	letter-spacing: 0;
	color: $globalBlack;
	white-space: pre-wrap;
}

.currency {
	color: $button-disable-border;
}
