@import 'assets/styles/scss/variables';

.tooltip {
	border-radius: 8px;
	padding: 8px 12px;
	font-size: 12px;
	background: var(--ui--tooltip-bg);
	position: absolute;
	top: -40px;
	color: $globalWhite;
	display: none;
}

.buttons {
	display: flex;
	gap: 8px;
	& svg {
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;
		cursor: pointer;
	}
}
