@import 'assets/styles/scss/variables';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}

.mb_0 {
	margin-bottom: 0 !important;
}

.dropdown {
	display: flex;
	gap: 8px;
	&__button {
		border-radius: 8px;
		background: $globalMainBlue;
		color: $globalWhite;
		padding: 12px 24px;
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0;
		text-align: left;
		cursor: pointer;
		width: 200px;
		display: flex;
		gap: 8px;
		align-items: center;
	}
	&__items {
		display: none;
		position: absolute;
		border: 1px solid $globalMainBlue;
	}
	&:hover .dropdown__items {
		display: flex;
		flex-direction: column;
	}
}
