@import 'assets/styles/scss/variables';

.button {
	gap: 8px;
	height: 48px;
	padding: 0 24px;
	border-radius: 8px;
	background: $button-primary;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: auto;
	min-width: 110px;
	border: none;
	color: $globalWhite;
	cursor: pointer;
	transition: all 0.15s ease;

	svg path {
		stroke: $globalBlack;
	}

	&:hover {
		background: $button-primary-dark;
	}

	&__type-primary {
		background: $button-primary;
		color: $globalWhite;

		svg path {
			stroke: $globalWhite;
		}

		&:hover {
			background: $button-primary-dark;
		}
	}

	&__type-primary-light {
		background: $globalWhite;
		border: 1px solid $button-primary;
		color: $globalBlack;

		svg path {
			stroke: $button-primary;
		}

		&:hover {
			background: $button-primary-light;
			border: 1px solid $button-primary-dark;
		}
	}

	&__type-primary-link {
		background: transparent;
		border: unset;
		color: $button-primary;
		padding: 0;
		min-width: auto;
		height: auto;
		gap: 4px;
		font-size: 14px;
		font-weight: 500;

		&:hover {
			color: $button-primary-dark;
			background: transparent;
		}
	}

	&__filter-icon {
		position: relative;

		.filter-icon {
			min-height: 24px;
			max-height: 24px;
			min-width: 24px;
			max-width: 24px;
			path {
				stroke: $globalBlack;
			}
		}

		.filter-badge {
			position: absolute;
			fill: $button-clear;
			top: -8px;
			right: -16px;
		}
	}

	&__type-action {
		height: 48px;
		min-width: 48px;
		gap: 10px;
		background: $button-primary-light;
		color: $button-primary;
		padding: 6px 12px 10px 12px;

		&:hover {
			background: $button-primary-medium;
		}
	}

	&__type-table-action {
		height: 46px;
		min-width: 73px;
		display: flex;
		flex-direction: column;
		background: $button-primary-light;
		color: $button-primary;
		font-size: 10px;
		padding: 6px 12px 10px;
		grid-gap: unset;
		gap: unset;
		line-height: 10px;
		svg {
			flex: none;
			height: 18px;
			width: 18px;
			path {
				stroke: $button-primary;
			}
		}

		&:hover {
			background: rgba($globalMainBlue, 20%);
		}
		&:disabled {
			cursor: default;
			background: $button-disable;
			opacity: 0.5;
			color: $button-disable-border;

			svg path {
				stroke: $button-disable-border;
			}
		}
	}

	&__type-pagination {
		height: 39px;
		width: 39px;
		min-width: unset;
		gap: 10px;
		background: $globalWhite;
		color: $globalBlack;
		border: 1px solid $button-secondary-border;
		padding: 6px 12px 10px 12px;

		&:hover {
			background: $button-primary-light;
		}

		&.active {
			background: $button-primary;
			border: unset;
			color: $globalWhite;
		}

		&:hover.active {
			background: $button-primary-dark;
		}
	}

	&__type-no-border {
		border: none;
		background: transparent;
		color: $globalBlack;

		&.button__type-action {
			color: $button-primary;
		}

		&:hover {
			background: $button-hover-no-border;
		}
	}

	&__type-clear {
		color: $button-clear;
		background: transparent;

		&:hover {
			background: $button-clear-light;
		}

		svg path {
			stroke: $button-clear;
		}
	}

	&__type-close {
		&:hover {
			background: $button-hover-close;
		}
	}



	&__type--simple-small,
	&__type--simple-regular {
		width: 16px;
		height: 16px;
		min-width: 16px;
		background: transparent;
		color: $button-primary;
		padding: 0;
		svg path {
			stroke: $button-primary;
		}

		&:hover {
			background: transparent;
			color: $button-primary-dark;
			svg path {
				stroke: $button-primary-dark;
			}
		}
	}

	&__type--simple-regular {
		width: 24px;
		height: 24px;
		min-width: 24px;
	}

	&:disabled {
		cursor: default;
		background: $button-disable;
		opacity: 0.5;
		color: $button-disable-border;

		svg path {
			stroke: $button-disable-border;
		}

		&.button__type-primary-light {
			background: $globalWhite;
			border: 1px solid $button-disable-border;
		}
	}

	&__size2 {
		min-width: auto;
		padding: 0 16px;
		height: 40px;
		font-size: 14px;
	}

	&__size4 {
		min-width: auto;
		padding: 8px;
		height: 32px;
		font-size: 14px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	&__icon {
		min-width: auto;
		padding: 0 !important;
		width: 40px;
		height: 40px;
		border-radius: 50%;

		.svg {
			stroke: $globalBlack;
		}
	}

	&__switch-status {
		min-width: auto;
		padding: 0 16px;
		height: 40px;
		font-size: 14px;

		&--selected:hover {
			background: $button-primary;
		}

		&--unselected, &:disabled {
			background: $globalWhite;
			color: $globalBlack;

			&:hover {
				background: $globalWhite;
				color: $globalBlack;
			}
		}
	}
}
