@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: #F2F6FF;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 0;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.table {
	margin-top: 24px;
	padding-bottom: 24px;
}
