@import 'assets/styles/scss/variables';

.reportDownloadMenu {
	position: absolute;
	right: 0;
	top: calc(100% + 3px);
	width: 220px;
	background: var(--block-bg);
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	z-index: 100;
	display: none;

	&.active {
		display: block;
	}

	&__item {
		cursor: pointer;
		padding: 8px;
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 400;
		font-size: 14px;
		border-bottom: 1px solid var(--border-color-main);

		@media screen and (max-width: 720px) {
			font-size: 15px;
		}

		&:hover {
			background-color: $hoverGlobalMainBlue;
			font-weight: 600;
			border-radius: 12px;
		}

		svg {
			@media screen and (max-width: 720px) {
				max-width: 20px;
			}
		}

		svg path {
			stroke: var(--main-color);
		}

		&:last-child {
			border: none;
		}
	}
}
