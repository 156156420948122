@import 'assets/styles/scss/variables';

.progressbar {
	display: flex;
	gap: 2px;
}

.capsule {
	width: 4px;
	height: 20px;
	border-radius: 2px;
	background: $globalStrokeAndDivider;
}
