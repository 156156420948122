@import 'assets/styles/scss/variables';

.filters {
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.filters__button {
		padding: 0;
	}
}

.search {
	margin: 0;
	&__input {
		margin: 0;
	}
}
