@import 'assets/styles/scss/variables';
$icon-size: 40px;
$icon-trend-size: 32px;

.account {
	width: 492px;
	background: $globalBlack;
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 8px;
	gap: 24px;
	position: relative;
	box-shadow: 0 5px 12px 0 $boxShadowColor;
	&__title {
		color: $globalWhite;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		text-align: left;
		margin-left: 16px;
	}
	&__header {
		display: flex;
		align-items: center;
	}
	&__icon {
		max-width: $icon-size;
		max-height: $icon-size;
		min-width: $icon-size;
		min-height: $icon-size;
		& svg {
			max-width: $icon-size;
			max-height: $icon-size;
			min-width: $icon-size;
			min-height: $icon-size;
		}
		& img {
			width: 100%;
			height: 100%;
		}
	}
	&__name {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: $globalWhite;
	}
	&__label {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $card-label;
	}
	&__value {
		display: flex;
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $globalWhite;
		&:hover .copy__value {
			color: $globalMainBlue;
		}
	}
	&__balance {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0;
		color: $globalWhite;
		white-space: pre-wrap;
		&Currency {
			color: $card-label;
		}
	}
	&__menu {
		width: 159px;
		padding: 4px;
		border-radius: 8px;
		gap: 4px;
		background: $globalWhite;
		position: absolute;
		box-shadow: 0 4px 21px 0 $menu-shadow;
		&Item {
			cursor: pointer;
			display: flex;
			height: 40px;
			align-items: flex-end;
			padding: 8px;
			gap: 12px;
			font-weight: 500;
			line-height: 23px;
			letter-spacing: 0;
			color: $globalBlack;
			& path {
				stroke: $button-disable-border;
			}
			&_delete {
				color: $globalRed;
				& path {
					stroke: $globalRed;
				}
			}
		}
	}
	&__edit {
		position: absolute;
		padding: 8px;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 1;
		&Button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.2s;
			path {
				stroke: $button-disable-border;
			}
		}
		&:hover {
			.account__editButton {
				background: $button-hover-close;
			}
		}
	}
	&__currency {
	}
}

.copy {
	margin-left: 8px;
	&__value {
		cursor: pointer;
		transition: 0.2s;
	}
}

.bottom {
	display: flex;
	&__item {
		display: flex;
		flex-direction: column;
		&:not(:last-of-type) {
			border-right: 1px solid $card-border;
			padding-right: 24px;
			margin-right: 24px;
		}
	}
	&__label {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		text-align: left;
		color: $button-disable-border;
	}
	&__value {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $globalWhite;
		white-space: pre-wrap;
	}
	&__currency {
		color: $card-label;
	}
}

.trend {
	display: flex;
	align-items: center;
	margin-top: 12px;
	gap: 12px;
	&__value {
		font-size: 16px;
		font-weight: 700;
		line-height: 26px;
		letter-spacing: 0;
		text-align: left;
		color: $globalWhite;
	}
	&__icon {
		max-width: $icon-trend-size;
		max-height: $icon-trend-size;
		min-width: $icon-trend-size;
		min-height: $icon-trend-size;
	}
}
