.authorization-section-custom {
	position: absolute;
	width: 100%;
}

.upload-file-box-custom {
	width: auto;
}

.fields-item-custom {
	padding-bottom: 100px;

	@media screen and (max-width: 720px) {
		padding-bottom: 20px;
	}
}
