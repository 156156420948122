@import 'assets/styles/scss/variables';

.floating {
	position: relative;
	&__label {
		position: absolute;
		pointer-events: none;
		transform-origin: 0 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 2px;
		z-index: 4;
		transform: scale(1) translateY(-.7rem) translateX(14px);
		font-size: 12px;
		background: $globalWhite;
		color: $button-disable-border;
		border: 1px solid transparent;
		font-weight: 500;
		width: auto;
		line-height: 20.4px;
	}
}
