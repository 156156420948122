@import 'assets/styles/scss/variables';

.tabs {
	margin-bottom: 16px;
	padding: 4px;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 4px;
	background: $globalWhite;
	border-radius: 8px;
}
