@import 'assets/styles/scss/variables';

.menu {
	background: $globalWhite;
	border: 1px solid $button-border;
	border-radius: 12px;
	&__item {
		cursor: pointer;
		padding: 8px 16px;
		display: block;
		height: 39px;
		color: $globalTextColorMain;
		text-align: left;
		font-weight: inherit;
		&:not(:last-child) {
			border-bottom: 1px solid $button-border;
		}
		&:hover {
			color: $globalMainBlue;
			transition: 0.3s;
		}
	}
	&__item a {
		color: $globalMainBlue;
	}
}
