@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	&__details {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.header {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	align-items: center;
	margin-bottom: 16px;
	&__button {
		height: 40px;
		padding: 8px 16px;
	}
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin-bottom: 16px;
}

.tableBlock {
	margin-top: 32px;
	padding-bottom: 24px;
}

.filters {
	padding: 0;
}
