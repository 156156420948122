@import 'assets/styles/scss/variables';

.status {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px 12px 16px;
	border-radius: 8px;
	border: 1px solid $globalMainBlue;
	background: $globalWhite;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	text-align: left;
	color: $fullBlack;
}

.center {
	justify-content: center;
}

.loader {
	display: flex;
	gap: 4px;
	&__item {
		background: $globalMainBlue;
		border-radius: 50%;
		width: 12px;
		height: 12px;
		animation-name: loading-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}
}

@keyframes loading-animation {
	0% {
		transform: scale(0.167);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.167);
	}
}
