@import 'assets/styles/scss/variables';

.reportDownloadButton {
	display: flex;
	align-items: center;
	gap: 12px;
	position: relative;
	justify-content: space-between;
	flex-wrap: nowrap;
	grid-gap: 16px;
	&__button {
		height: 40px;
		font-size: 16px;
		font-weight: 600;
		padding: 8px 16px;
		background: $globalWhite;
		border: 1px solid $button-disable-border;
		border-radius: 8px;
		svg path {
			stroke: var(--main-color);
		}
	}
	&__wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
	}
}
