@import 'assets/styles/scss/variables';

.table {
	margin-top: 32px;
	box-sizing: border-box;
	color: #202020;
	&__block {
		border: none;
		border-radius: 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
	&__body {
		margin-top: 16px;
	}
}
