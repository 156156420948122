// 📌 Project settings

.back-page--link-type {
	width: max-content;
}

.tabs__item--link-type {
	text-align: center;
	line-height: normal;
}

.plus-icon--blue-color path {
	stroke: #4285f5;
}

.button--show-key {
	min-width: 124px;

	@media screen and (max-width: 767px) {
		min-width: auto;
	}
}

.api-item-wrapper--callback-url {
	align-items: flex-start;
}

.button--callback-url {
	margin-top: 27px;

	@media screen and (max-width: 680px) {
		margin-top: 0;
	}
}

.widget-key-icon path {
	stroke: #4285f5;
}

// 📌 Index

.button--active-link {
	background: var(--main-color);
	color: #fff;
}
