@import 'assets/styles/scss/variables';

.walletsHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;

	&__title {
		margin-bottom: 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__buttons {
		display: flex;
		gap: 8px;
		margin-bottom: 4px;
	}
	&__button {
		height: 40px;
		padding: 8px 16px;
	}
}
