:root {
	--toastify-toast-width: 430px;
	--toastify-font-family: inherit;
}

.Toastify {
	&__toast {
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0px 0px 1px rgb(0 0 0 / 10%), 0px 2px 25px rgb(0 0 0 / 15%);

		&--success {
			border: 1px solid #00ba88;
		}
		&--error {
			border: 1px solid #fd3232;
		}
		&--info {
			border: 1px solid #3498db;
		}
	}

	&__toast-body {
		padding: 0;
	}

	&__toast-icon {
		width: 24px;
		margin-inline-end: 15px;
	}
}

.notification {
	&__title {
		font-size: 18px;
	}

	&__text {
		margin-top: 0;
	}
}
