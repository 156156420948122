@import 'assets/styles/scss/variables';

$transition: 0.2s;

.accounts {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 16px;
	margin: 16px 0;
}

.append {
	flex: 0 0 calc(25% - 12px);
	max-width: 100%;
	padding: 24px;
	background: $globalWhite;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 2px dashed $globalStrokeAndDivider;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	min-height: 412px;
	transition: 0.2s;
	&__icon {
		width: 40px;
		height: 40px;
		background: $globalMainBlue;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $transition;
	}
	&__text {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: $globalBlack;
		transition: $transition;
	}
	&__union {
		position: absolute;
		bottom: 27px;
		right: -10.5px;
		transition: $transition;
	}
	& path {
		transition: $transition;
	}
	&:hover {
		border: 2px dashed $globalWhite;
		background: $globalMainBlue;
		.append__icon {
			background: $globalWhite;
			svg, path {
				stroke: $globalMainBlue;
			}
		}
		.append__union {
			svg, path {
				fill: $globalWhite;
			}
		}
		.append__text {
			color: $globalWhite;
		}
	}
}

@media screen and (max-width: 1300px) {
	.append {
		flex-basis: calc(calc(100% / 3) - 11px) ;
		max-width: calc(calc(100% / 3) - 11px);
	}
}

@media screen and (max-width: 800px) {
	.append {
		flex-basis: calc(50% - 8px);
		max-width: calc(50% - 8px);
	}
}

@media screen and (max-width: 600px) {
	.append {
		flex-basis: 100%;
		max-width: 100%;
	}
}
