@import 'assets/styles/scss/variables';

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin: 0;
}

.head {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 16px;
	width: 100%;
}

.tableBlock {
	padding-bottom: 24px;
}
