@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin-bottom: 16px;
}

.commission {
	&:not(:last-of-type) {
		border-bottom: 1px solid $button-border;
		padding-bottom: 24px;
		margin-bottom: 24px;
	}
	&__item {
		display: flex;
		flex-direction: column;
		width: 400px;
	}
	&__value {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 16px;
		border: 1px solid $button-border;
		border-radius: 12px;
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: $globalBlack;
	}
	&__list {
		display: flex;
		flex-direction: column;
	}
	&__title {
		display: flex;
		gap: 0 18px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		margin-bottom: 24px;
	}
	&__content {
		max-width: 100%;
		display: flex;
		align-items: flex-end;
		grid-gap: 16px;
		gap: 16px;
	}
	&__label {
		display: block;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
}

