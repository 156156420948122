@import '../../../../../assets/styles/scss/variables';

.copy-address {
	padding: 24px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	word-break: break-word;
	background: $globalBGLight;
	border-radius: 12px;
	&__type2 {
		background: none;
		padding: 0;
		font-weight: 400;
		justify-content: flex-start;
		p {
			position: relative;
			overflow: hidden;
			max-width: calc(100% - 26px);
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	&__sb {
		justify-content: space-between;
	}
	&__show-tooltip {
		overflow: visible!important;
	}
}
