@import 'assets/styles/scss/variables';

.fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 64px;
	gap: 64px;
	& .mb_0 {
		margin-bottom: 0;
	}
	&__uploadFile {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 14px;
	}
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	color: $globalTextColorMain;
	margin: 0 0 16px;
}

