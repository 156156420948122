.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: 104.1px 0 97px;
	height: 100%;
	width: 100%;
	background: white;
}

