@import '../../../assets/styles/scss/variables';

.userInfo {
	display: flex;
	align-items: center;
	gap: 12px;
	height: 100%;
	width: max-content;
    max-width: 300px;
	padding-left: 32px;
	border-left: 1px solid #5D6A85;
	position: relative;

    &__wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
    }

	&__avatar {
		padding: 8px;
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f2f6ff;
		border-radius: 12px;
	}

	&__name {
		text-align: left;
	}

    &__role {
        font-weight: 600;
        font-size: 14px;
        color: $globalTextSecondary;
    }

    &__fullName {
        display: block;
        font-weight: 600;
        font-size: 16px;
        color: $globalWhite;
    }

    &__arrow {
        margin-top: 4px;
    }

	&.active {
		.userInfo {
			&__arrow {
				transform: rotate(180deg);
			}
		}
	}
}
