@import 'assets/styles/scss/variables';

.account {
	flex: 0 0 calc(25% - 12px);
	max-width: 25%;
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 8px;
	gap: 16px;
	background: $globalWhite;
	position: relative;
	box-shadow: 0 5px 12px 0 $boxShadowColor;
	&__icon {
		max-width: 64px;
		max-height: 64px;
		min-width: 64px;
		min-height: 64px;
		& img {
			width: 100%;
			height: 100%;
		}
	}
	&__name {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: $globalBlack;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&__label {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__value {
		display: flex;
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $globalBlack;
		&:hover .copy__value {
			color: $globalMainBlue;
		}
	}
	&__balance {
		border-top: 1px solid $globalStrokeAndDivider;
		padding-top: 16px;
		gap: 16px;
		display: flex;
		flex-direction: column;
	}
	&__menu {
		width: 159px;
		padding: 4px;
		border-radius: 8px;
		gap: 4px;
		background: $globalWhite;
		position: absolute;
		box-shadow: 0 4px 21px 0 $menu-shadow;
		&Item {
			cursor: pointer;
			display: flex;
			height: 40px;
			align-items: flex-end;
			padding: 8px;
			gap: 12px;
			font-weight: 500;
			line-height: 23px;
			letter-spacing: 0;
			color: $globalBlack;
			& path {
				stroke: $button-disable-border;
			}
			&_delete {
				color: $globalRed;
				& path {
					stroke: $globalRed;
				}
			}
		}
	}
	&__edit {
		position: absolute;
		padding: 8px;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 1;
		&Button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.2s;
			path {
				stroke: $button-disable-border;
			}
		}
		&:hover {
			.account__editButton {
				background: $button-hover-close;
			}
		}
	}
	&__currency {
		display: flex;
		gap: 32px;
	}
}

.copy {
	margin-left: 8px;
	&__value {
		cursor: pointer;
		transition: 0.2s;
	}
}

@media screen and (max-width: 1300px) {
	.account {
		flex-basis: calc(calc(100% / 3) - 11px) ;
		max-width: calc(calc(100% / 3) - 11px);
	}
}

@media screen and (max-width: 800px) {
	.account {
		flex-basis: calc(50% - 8px);
		max-width: calc(50% - 8px);
	}
}

@media screen and (max-width: 600px) {
	.account {
		flex-basis: 100%;
		max-width: 100%;
	}
}
