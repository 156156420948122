@import 'assets/styles/scss/variables';

.switch {
	display: flex;
	grid-gap: 24px;
	gap: 24px;
	position: relative;

	&__tooltip {
		bottom: calc(100% + 8px);
		width: auto;
		word-break: normal;
		white-space: normal;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		display: none;
		background: $globalBlack;
		border-radius: 8px;
		padding: 8px 12px;
		z-index: 10;
		text-align: left;
		color: #fff;
		font-size: 12px;
		font-weight: 400;

		&::after {
			left: 50%;
			-webkit-transform: translateX(-50%) rotate(-180deg);
			transform: translateX(-50%) rotate(-180deg);
			content: "";
			position: absolute;
			border: 6px solid transparent;
			border-bottom: 6px solid $globalBlack;
			bottom: -11px;
		}
	}

	&__toggler {
		width: 36px;
		height: 20px;
		border-color: $button-border;
		background: $button-border;
		position: relative;
		display: block;
		border: 1px solid $uiSwitchColor;
		border-radius: 13px;
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			left: 2px;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			background: $uiSwitchColor;
			background: $globalWhite;
			border-radius: 100%;
			transition: .3s;
			width: 14px;
			height: 14px;
		}
	}

	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		border: 0;
		padding: 0;
		clip: rect(0 0 0 0);
		overflow: hidden;
		-webkit-appearance: none;
		appearance: none;
	}

	&:hover &__tooltip {
		display: flex;
	}

	& input:checked ~ &__toggler {
		border-color: $globalMainBlue;
		background: $globalMainBlue;

		&::before {
			left: 19px;
		}
	}
}
