@import 'assets/styles/scss/variables';

.noResult {
	padding: 72px 0 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: none;
	&__icon {
		max-width: 120px;
		margin-bottom: 12px;
	}
	&__text {
		font-weight: 400;
		font-size: 16px;
		color: $globalTextSecondary;
		margin: 0;
	}
}

