@import 'assets/styles/scss/variables';

.left {
	width: 100%;
	display: flex;
}

.table {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.table__content {
		display: grid;
		grid-auto-rows: min-content;
		width: 100%;
	}
}

.td {
	padding-bottom: 16px;
	border-bottom: 1px solid $globalStrokeAndDivider;
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: $globalTextSecondary;
	padding-right: 12px;
	&:first-child {
		padding-left: 0;
	}
	&Right {
		display: flex;
		text-align: right;
		justify-content: flex-end;
	}
}

.tooltip {
	display: inline-flex;
	margin-left: 4px;
	&__item {
		display: flex;
		align-items: center;
	}
	.tooltip__content {
		width: 320px;
		word-break: normal;
		white-space: normal;
		transform: translate(-50%, calc(-100% - 8px));
		position: absolute;
		display: none;
		background: $globalBlack;
		border-radius: 8px;
		padding: 8px 12px;
		z-index: 10;
	}

	&:hover .tooltip__content {
		display: block;
	}
}
