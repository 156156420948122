@import 'assets/styles/scss/variables';

.table {
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.table__content {
		display: grid;
		grid-template-columns: 50px 0.55fr 0.55fr 0.9fr 0.9fr minmax(150px, 1fr) 1fr 0.6fr 0.65fr 0.85fr minmax(96px, 0.6fr) 50px;
		grid-auto-rows: min-content;
		position: relative;
		width: 100%;
	}
}

.td {
	padding-bottom: 16px;
	border-bottom: 1px solid #D3DAE8;
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: $globalTextSecondary;
	&:first-child {
		padding-left: 0;
	}
	&:last-child  {
		display: flex;
		text-align: right;
		justify-content: right;
	}
}

.filters {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 16px;
	padding: 0;
	&__button {
		padding: 0;
		flex-grow: 1;
	}
}

.search {
	margin: 0;
	&__input {
		margin: 0;
	}
}
