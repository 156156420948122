@import '../../../../../../assets/styles/scss/variables';

.logo {
	display: flex;
	width: 40px;
	margin-right: 0;
	height: 40px;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	width: 72px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	& svg path {
		stroke: $fullBlack;
	}
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 36px;
	height: 40px;
	border-radius: 8px;
	background: $table-link-background;
	cursor: pointer;
}

.head {
	display: flex;
	gap: 16px;
	margin-bottom: 32px;
	align-items: center;
}

.title {
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0;
	color: $globalTextColorMain;
}

.subtitle {
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
	text-align: left;
	color: $button-disable-border;
}

.content {
	padding: 32px;
}

.balance {
	margin: 12px 0 24px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
}

.reverse {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px auto;
	width: 48px;
	height: 48px;
	background: $globalMainBlue;
	border-radius: 50%;
	cursor: pointer;
	& path {
		stroke: $globalWhite;
	}
}

.info {
	padding: 24px;
	border-radius: 12px;
	gap: 8px;
	background: $button-hover-close;
	display: flex;
	flex-direction: column;
	&__item {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $button-disable-border;
	}
	&__value {
		color: $fullBlack;
	}
}

.status {
	display: flex;
	align-items: center;
	padding: 12px 16px 12px 16px;
	border-radius: 8px;
	border: 1px solid $globalMainBlue;
	background: $globalWhite;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	text-align: left;
	color: $fullBlack;
}

.buttons {
	display: flex;
	gap: 8px;
	margin: 24px 0;
}

.button {
	width: 50%;
	border: 1px solid $button-secondary-border;
}

.description {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.autoExchange {
	font-size: 14px;
	font-weight: 600;
	line-height: 23px;
	letter-spacing: 0;
	text-align: center;
	color: $globalMainBlue;
	margin-top: 8px;
	padding: 16px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	cursor: pointer;
}

.body {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
}

.bodyReverse {
	flex-direction: column-reverse;
}
