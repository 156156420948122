@import 'assets/styles/scss/variables';

$transition: 0.2s;

.empty {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	&__or {
		margin: 0 16px;
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__content {
		width: 490px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 239px;
	}
	&__title {
		font-size: 32px;
		font-weight: 700;
		line-height: 45px;
		letter-spacing: 0;
		color: $globalBlack;
		margin: 40px 0 24px;
		text-align: center;
	}
	&__text {
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0;
		margin-bottom: 40px;
		text-align: center;
		color: $button-disable-border;
	}
	&__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__button {
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0;
		width: 224px;
	}
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}

.crypto {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 16px;
	margin-top: 16px;
}

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 0;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}
