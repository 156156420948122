@import 'assets/styles/scss/variables';

.card {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	border-radius: 8px;
	background: $globalWhite;
	box-shadow: 0 2px 2px 0 $boxShadowColor;
	position: relative;
	&:hover {
		box-shadow: 0 5px 12px 0 $boxShadowColor;
	}
}

.head {
	.title {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: $fullBlack;
		gap: 16px;
	}
	.icon {
		width: 40px;
		height: 40px;
	}
}

.isActive {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 63px;
	height: 23px;
	border-radius: 20px;
	gap: 8px;
	background: $active-project;
	margin-left: 64px;
	font-size: 10px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0;
	color: $active-project-text;
}

.isNotActive {
	background: rgba($globalRed, 0.1);
	color: $globalRed;
}

.balance {
	&__title {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__value {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0;
		color: $fullBlack;
	}
	&__currency {
		color: $button-disable-border;
	}
}

.currencies {
	&__title {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__items {
		display: flex;
		flex-direction: column;
		gap: 12px;

		height: 144px;
	}
}

.currency {
	display: flex;
	&__icon {
		min-width: 40px;
		min-height: 40px;
		max-width: 40px;
		max-height: 40px;
		margin-right: 12px;
	}
	&__progress {
		width: 100%;
		height: 8px;
		border-radius: 4px;
		background: $currency-progress;
		&Value {
			background: $globalMainBlue;
			height: 8px;
			border-radius: 4px;
		}
	}

	&__info {
		width: 100%;
	}
	&__value {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
		margin-bottom: 4px;
		text-transform: uppercase;
	}
}

.info {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
	gap: 32px;
	&__item {
		display: flex;
		align-items: center;
		gap: 12px;
	}
	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($button-disable-border, 10%);
		border-radius: 50%;
		svg path {
			width: 24px;
			height: 24px;
			stroke: $button-disable-border;
		}
	}
	&__title {
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__value {
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		letter-spacing: 0;
		color: $fullBlack;
	}
}
