@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		margin-top: 16px;
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin-top: 24px;
}
