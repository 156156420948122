@import 'assets/styles/scss/variables';

$transition: 0.2s;

.empty {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	&__or {
		margin: 0 16px;
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0;
		color: $button-disable-border;
	}
	&__content {
		width: 490px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 239px;
	}
	&__title {
		font-size: 32px;
		font-weight: 700;
		line-height: 45px;
		letter-spacing: 0;
		color: $globalBlack;
		margin: 40px 0 24px;
		text-align: center;
	}
	&__text {
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0;
		margin-bottom: 40px;
		text-align: center;
		color: $button-disable-border;
	}
	&__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__button {
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: 0;
		width: 224px;
	}
}
