@import 'assets/styles/scss/variables';

.selectAll {
	font-weight: 500;
	font-size: 14px;
	color: $button-disable-border;
	display: flex;
	align-items: center;
	justify-content: center;
}
