@import 'assets/styles/scss/variables';

$select-height: 48px;

.input {
	position: relative;
	display: flex;
	justify-content: space-between;
	height: $select-height;
	border-radius: 8px;
	border: 1px solid $button-secondary-border;
	align-items: center;
	width: 100%;
	gap: 8px;
	padding-right: 16px;
}

.value {
	flex-grow: 1;
	border: none;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $fullBlack;
	text-decoration: none;
	outline:none;
	padding: 0 16px;
	height: 46px;
	border-radius: 12px;
	cursor: pointer;
}

.label {
	position: absolute;
	top: 0;
	background: $globalWhite;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0;
	padding: 0 2px;
	left: 14px;
	transform: translateY(-50%);
	color: $button-disable-border;
}

.actions {
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	color: $button-disable-border;
	display: flex;
	align-items: center;
	height: 100%;
	gap: 0;

}

.max {
	color: $globalMainBlue;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 8px;
}

.value::-webkit-outer-spin-button,
.value::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.value[type='number'] {
	-moz-appearance: textfield;
}
