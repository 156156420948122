@import 'assets/styles/scss/variables';

.token {
	display: flex;
	align-items: center;
	text-align: left;
	line-height: 23.1px;
	max-width: 83%;
	&__icon {
		display: flex;
		width: 34px;
		margin-right: 12px;
		&--type2 {
			width: 28px !important;
		}
	}
	&__id {
		display: block;
		font-weight: 600;
		font-size: 14px;
		color: $globalTextSecondary;
	}
	&__name {
		margin-right: 8px;
		color: $globalBlack;
		//styleName: Trim/Medium/P2;
		font-size: 14px;
		font-weight: 500;
		line-height: 23px;
		letter-spacing: 0;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 1;
	}

	&__transcription {
		font-weight: 500;
		font-size: 14px;
		color: $button-disable-border;
	}
	&__network {
		display: block;
		font-weight: 500;
		font-size: 14px;
		color: $globalTextSecondary;
	}

	&__type2 {
		.token {
			&__icon {
				display: flex;
				min-width: 40px;
				max-width: 40px;
				margin-right: 12px;
			}
			&__name {
				font-weight: 500;
				font-size: 14px;
			}
			&__transcription {
				font-size: 14px;
			}
		}
	}
	&__no-wrap {
		flex-wrap: nowrap;
	}
}

.token__center {
	justify-content: center !important;
}
.token__color_gray {
	color: $globalTextSecondary;
}
