@import 'reset.scss';
@import 'ui-kit.scss';
@import 'ui-change.scss';
@import 'swiper.scss';
@import 'datepicker.scss';
@import 'variables.scss';

:root {
	--block-bg: #fff;

	--main-color: #4285f5;

	--text-color-main: #202020;
	--text-color2: #9996be;
	--text-color: #777e90;

	--border-color-main: #eaeaef;
	--border-color2: #dadada;

	--icon-color: #9996be;

	--border-width: 1px;
	--border-radius: 4px;

	--red-color: #fd3232;
	--green-color: #00ba88;
}

* {
	-webkit-font-smoothing: antialiased;
}

body {
	background: var(--block-bg);
	font-family: 'Plus Jakarta Sans', sans-serif;
	line-height: 1.5;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-color-main);
	-webkit-font-smoothing: antialiased;
	overscroll-behavior-y: none; // Stop scroll to top (experimental)
}

button,
input,
label,
textarea,
select {
	font-family: 'Plus Jakarta Sans', sans-serif;
}

img {
	width: auto;
	max-width: 100%;
}

svg {
	width: auto;
	display: block;
	max-width: 100%;
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

main {
	/*max-width: 100%;
    width: 1435px;*/
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.flex {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}

.flex-m {
	align-items: center;
}

.flex-t {
	align-items: flex-start;
}

.flex-b {
	align-items: flex-end;
}

.flex-sb {
	justify-content: space-between;
}

.flex-c {
	justify-content: center;
}

.flex-sa {
	justify-content: space-around;
}

.flex-e {
	justify-content: flex-end;
}

.flex-ww {
	flex-wrap: wrap;
}

.header {
	display: flex;
	align-items: center;
	height: 64px;
	padding: 0 32px;
	background: var(--block-bg);
	border-bottom: 1px solid var(--border-color-main);

	@media screen and (max-width: 991px) {
		height: auto;
		padding: 12px 16px !important;
		.header-balance,
		.select {
			display: none;
		}
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 1440px;
		height: 100%;
		margin: 0 auto;
		@media screen and (max-width: 991px) {
			flex-wrap: wrap;
			gap: 12px;
		}
	}

	&--type2 {
		padding: 0 32px 0 29px;
		.logo {
			justify-content: center;
			@media screen and (max-width: 991px) {
				justify-content: flex-start;
			}
		}
		.header__container {
			width: 100%;
		}
		@media screen and (max-width: 991px) {
			.header-right {
				width: 100%;
			}
		}
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}
}

.header-left {
	display: flex;
	align-items: center;
	padding: 0 25px 0 0;

	.select-block {
		margin-bottom: 0;
	}

	@media screen and (max-width: 991px) {
		padding: 0 16px 0 0;
	}

	&--type2 {
		height: 100%;
		.logo {
			width: 208px;
			height: 100%;
			padding-right: 25px;
			border-right: 1px solid var(--border-color-main);
			@media screen and (max-width: 991px) {
				border: none;
			}
		}

		@media screen and (max-width: 991px) {
			border: none;
			width: 100%;
			justify-content: flex-start;
		}
	}
}

.header-right {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
}

.header-admin-wrap {
	display: flex;
	align-items: center;
	gap: 12px;
	height: 100%;
	padding-left: 32px;
	border-left: 1px solid var(--border-color-main);
	position: relative;
	@media screen and (max-width: 991px) {
		padding-left: 0;
		border: none;
	}
	&.active {
		.header-admin-drop {
			display: block;
		}
		.header-admin__arrow {
			transform: rotate(180deg);
		}
	}
}

.header-admin-drop {
	position: absolute;
	right: 0;
	top: calc(100% + 15px);
	width: 220px;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	z-index: 100;
	display: none;
	@media screen and (max-width: 720px) {
		right: unset;
		left: 0;
	}
}

.header-admin-drop-item {
	cursor: pointer;
	padding: 13px 16px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 400;
	font-size: 16px;
	border-bottom: 1px solid var(--border-color-main);
	@media screen and (max-width: 720px) {
		font-size: 15px;
	}
	svg {
		@media screen and (max-width: 720px) {
			max-width: 20px;
		}
	}

	&:last-child {
		border: none;
	}
}

.nav-btn {
	display: none;
	margin-left: auto;
	width: 24px;

	&__open {
		svg path {
			stroke: var(--text-color-main);
		}
	}

	&__close {
		svg path {
			fill: var(--text-color-main);
		}
	}

	@media screen and (max-width: 991px) {
		display: flex;

		&__close {
			display: none;
		}

		&.active {
			.nav-btn__close {
				display: flex;
			}

			.nav-btn__open {
				display: none;
			}
		}
	}
}

.mobile-project-info {
	display: none;
	justify-content: space-between;
	align-items: center;
	gap: 8px 16px;
	margin-bottom: 16px;
	& + .content-header {
		margin-top: 16px;
	}
	.select-block {
		margin-bottom: 0;
	}
	.select {
		height: auto;
		&__current {
			padding: 0;
		}
	}
	@media screen and (max-width: 991px) {
		display: flex;
		flex-wrap: wrap;
		.header-project {
			display: block;
			&__name {
				display: block;
				padding-left: 0;
				font-size: 16px;
			}
		}
	}
}

.flex-s {
	justify-content: flex-start !important;
}

.nav-open {
	position: relative;
	overflow: hidden;
}

.logo {
	display: flex;
	align-items: center;
	width: 183px;
}

.authorization-section {
	position: relative;
	display: flex;
	align-items: center;
	min-height: unset;
	flex: 1;
	padding: 75px 0;
	overflow: hidden;

	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}

	&__figure {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 630px;
		height: 567px;
		border-radius: 50%;
		background: #4285f5;
		opacity: 0.1;
		filter: blur(180px);
	}
}

.authorization-box {
	position: relative;
	max-width: 464px;
	width: 100%;
	margin: 0 auto;
	z-index: 2;

	@media screen and (max-width: 767px) {
		max-width: 100%;
	}
}

.form {
	border-radius: 20px;
	background: var(--block-bg);
	border-radius: 12px;
	padding: 40px 32px;

	&__body {
		margin-top: 32px;
	}

	&--authorization {
		box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.1);
	}

	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}
}

.form-title {
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;

	&--center {
		text-align: center;
	}

	@media screen and (max-width: 991px) {
		font-size: 21px;
	}

	&--smaller {
		font-size: 20px;

		@media screen and (max-width: 991px) {
			font-size: 20px;
		}
	}

	&--mb0 {
		margin-bottom: 0;
	}
}

.form-subtitle {
	font-weight: 700;
	font-size: 18px;
	line-height: 150%;

	@media screen and (max-width: 767px) {
		font-size: 17px;
	}
}

.form-text {
	margin-top: 8px;
	line-height: 1.6;
	color: var(--text-color2);

	&--center {
		text-align: center;
	}

	&--mt-0 {
		margin-top: 0;
	}
}

.form-group {
	margin-top: 32px;

	&--type2 {
		margin-top: 24px;
	}
}

.form-options {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.form-img {
	display: flex;
	width: 120px;
	margin: 0 auto 18px auto;
}

.form-footer {
	display: flex;
	justify-content: center;
	margin-top: 32px;
	gap: 16px;

	&--less-mt {
		margin-top: 24px;
	}

	&--column {
		flex-direction: column;
		align-items: center;
		gap: 32px;
	}

	&--mob-column {
		@media screen and (max-width: 575px) {
			flex-direction: column;
		}
	}
}

.input-name {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
}

.input-notification {
	margin-top: 4px;
	font-size: 14px;
	line-height: 165%;
	font-weight: 400;
	&--error {
		color: var(--red-color);
	}
}

.error-message {
	margin-top: 4px;
	font-size: 14px;
	line-height: 165%;
	font-weight: 400;
	color: var(--red-color);
}

.link,
.td-value a {
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--main-color);
	transition: 0.2s linear;

	&:hover,
	&:focus {
		color: darken(#4285f5, 10%);
	}

	&--center {
		text-align: center;
		justify-content: center;
	}

	&--type2 {
		justify-content: center;
		text-transform: uppercase;
	}
	&--size2 {
		font-size: 16px;
	}
	&--uppercase {
		text-transform: uppercase;
	}

	svg {
		min-width: 23px;
	}
	&--type3 {
		font-size: 16px;
		font-weight: 400;
	}
}

.password-options {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.password-strength {
	position: relative;
	display: flex;
	margin-top: 16px;
	gap: 4px;
	height: 8px;

	&__line {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 8px;
		background: var(--border-color-main);
		border-radius: 12px;
	}

	&__item {
		position: relative;
		display: none;
		width: 25%;
		height: 8px;
		border-radius: 12px;
		z-index: 2;

		&--weak {
			background: #fd3232;
		}

		&--strong {
			background: #00ba88;
		}

		&.active {
			display: block;
		}
	}
}

.support-btn {
	position: fixed;
	right: -47px;
	bottom: 79px;
	transform: rotate(-90deg);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 138px;
	height: 47px;
	background: var(--main-color);
	border-radius: 12px 12px 0px 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: #fff;
	z-index: 99;

	&__icon {
		display: flex;
		transform: rotate(90deg);
	}
}

.tfa {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 24px 0;

	&__qr {
		width: 160px;
		margin: 0 auto;
	}

	&__code {
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
}

.main-section {
	display: flex;
	flex: 1;
}

.sidebar {
	width: 237px;
	padding: 16px 12px;
	border-right: 1px solid var(--border-color-main);

	@media screen and (max-width: 991px) {
		display: none;

		&.active {
			display: block;
		}
	}
}

.sidebar-nav {
	&__title {
		padding: 0 0 8px 16px;
		font-weight: 700;
		font-size: 18px;
	}
	&__list {
		& + .sidebar-nav__title {
			margin-top: 8px;
		}
		> li {
			a,
			button {
				display: flex;
				align-items: center;
				height: 50px;
				gap: 16px;
				padding: 0 16px;
				border-radius: 12px;
				font-weight: 600;
				font-size: 16px;
				line-height: 160%;
				color: var(--text-color-main);

				&:hover {
					color: var(--main-color);
				}

				&.active {
					background: var(--main-color);
					color: #fff;

					.sidebar-nav-icon {
						svg path {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
}

.sidebar-nav-text {
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sidebar-nav-icon {
	display: flex;
	justify-content: center;
	width: 24px;
	flex: 0 0 24px;

	svg path {
		stroke: var(--main-color);
	}
}

.sidebar-nav-dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: var(--red-color);
}

.main-content {
	//max-width: 1203px;
	flex: 1 0 0;
	padding: 24px 32px;
	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
	@media screen and (max-width: 991px) {
		width: 100%;
		padding: 24px 16px;
	}
	.main-content {
		margin-top: 24px;
		padding: 0;
	}
}

.content-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;
	.form-title {
		margin-bottom: 0;
		&--mb-32 {
			margin-bottom: 32px;
		}
	}
	&--start {
		align-items: flex-start;
	}
	&__actions {
		display: flex;
		align-items: center;
		gap: 16px 24px;
		&--buttons {
			gap: 16px 8px;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			.button {
				width: 100%;
			}
			@media screen and (max-width: 500px) {
				grid-template-columns: 1fr;
			}
		}
	}

	@media screen and (max-width: 991px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.content-search {
	width: 260px;

	&--width-390 {
		width: 390px;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.btn-icon {
	display: flex;
	justify-content: center;
	max-width: 16px;
}

.table-block {
	&--type2 {
		margin-top: 24px;
	}
}

.table-container {
	width: 629px;
	margin: 0 auto;
	align-items: center;
	&.table-header {
		height: 59px;
	}
	.td {
		padding: 0;
	}
}

.table {
	@media screen and (max-width: 1380px) {
		word-break: break-word;
	}

	@media screen and (max-width: 1200px) {
		.tooltip-item {
			&.input-icon {
				.tooltip {
					left: auto !important;
					right: 0 !important;
					transform: none !important;
					bottom: calc(100% + 2px) !important;
				}
			}
		}
	}

	@media screen and (max-width: 620px) {
		.tr {
			display: flex;
			flex-wrap: wrap;
			grid-template-columns: repeat(1, 1fr) !important;
		}
		.td {
			position: relative;
			width: 100%;
		}
		.tooltip-item {
			position: static !important;
			&.input-icon {
				position: absolute !important;
			}
		}
		.tooltip {
			left: 0 !important;
			right: auto !important;
			transform: none !important;
			bottom: 100% !important;
		}
		.tooltip:after {
			display: none;
		}
	}

	&--merchant-management {
		& .tr {
			grid-template-columns: 0.35fr 1.6fr 2.4fr 1.45fr 1.65fr 1.65fr 1.05fr 0.6fr;
		}
	}

	&--account-data-security {
		& .tr {
			grid-template-columns: 0.2fr 1fr 1fr 0.6fr 0.44fr;
		}
	}

	//Project wallets
	&--project-wallets-balances {
		& .tr {
			grid-template-columns: 1.7fr 2.3fr 1.25fr 1.25fr 1.34fr 1.95fr minmax(220px, 2.5fr);
		}
		.copy-address--type2 p {
			overflow: visible;
		}
	}
	&--project-wallets-balances-fiat {
		& .tr {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	&--project-wallets-transactions-history-crypto {
		& .tr {
			grid-template-columns: minmax(30px, 0.2fr) 0.6fr minmax(150px, 1.4fr) 0.75fr 0.85fr 0.8fr 0.7fr 1.3fr 1.3fr 1.3fr minmax(
					80px,
					0.45fr
				) 50px;
			gap: 4px;
		}
		.copy-address--type2 p {
			overflow: visible;
		}
	}
	&--project-wallets-transactions-history-fiat {
		& .tr {
			grid-template-columns: 0.4fr 0.5fr 1.2fr 1fr 1fr 0.8fr 0.6fr 0.5fr;
			gap: 4px;
		}
		.copy-address--type2 p {
			overflow: visible;
		}
	}

	&--project-wallets-profit {
		.table-body {
			margin-top: 0;
			.tr {
				margin-top: 0 !important;
				align-items: stretch;
			}
			.td {
				padding: 18px 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				&:nth-child(2n) {
					background: #f2f6ff;
				}
			}
		}
		& .tr {
			grid-template-columns: minmax(160px, 1.45fr) 0.85fr 0.9fr 1fr 0.95fr 1.15fr 0.97fr 1.3fr 1.2fr 1fr;
		}
		/*.table-body {
            .tr {
                .td {
                    &:nth-child(2n) {
                        background: #F2F6FF;
                    }
                }
            }
        }*/
	}

	//Project wallets end

	&--user-profile-role-project {
		& .tr {
			padding-bottom: 20px;
			align-items: flex-start;
			grid-template-columns: 6fr 4fr 3fr;
			border-bottom: 1px solid var(--border-color-main);
		}
	}

	&--user-profile-white-list {
		& .tr {
			grid-template-columns: 2.68fr 4.04fr 2.5fr 1.8fr;
		}
		.table-header {
			.td {
				&:last-child {
					padding-right: 16px;
				}
			}
		}
		.table-body {
			padding-right: 16px;
			height: 192px;
			overflow-y: auto;
			overflow-x: hidden;

			scrollbar-color: var(--border-color-main);
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 3px;
				background: var(--text-color2);
				cursor: pointer;
			}
			@media screen and (max-width: 1199px) {
				height: 360px;
			}
		}
	}

	//Merchant Settings
	&--merchant-settings-currencies {
		& .tr {
			grid-template-columns: 2.59fr 3.25fr 3.02fr 2.2fr;
		}
	}

	&--merchant-settings-fee-deposit-crypto {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}
	&--merchant-settings-fee-deposit-fiat {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}

	&--merchant-settings-fee-withdrawal-crypto {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}
	&--merchant-settings-fee-withdrawal-fiat {
		& .tr {
			grid-template-columns: 1.7fr 1.95fr 2.2fr 2.1fr 1.95fr 1fr;
		}
	}

	&--merchant-settings-fee-exchange-crypto-crypto {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}
	&--merchant-settings-fee-exchange-crypto-fiat {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}
	&--merchant-settings-fee-exchange-fiat-crypto {
		& .tr {
			grid-template-columns: 1.35fr 1.2fr 1fr 0.5fr;
		}
	}
	&--merchant-settings-fee-transfer {
		.table-header {
			.tr {
				align-items: flex-end;
			}
		}
		& .tr {
			grid-template-columns: 1.04fr 2.25fr 2.31fr 2.45fr 1.99fr 0.9fr;
		}
	}
	&--merchant-settings-fee-auto-exchange {
		& .tr {
			grid-template-columns: 1.04fr 2.25fr 2.31fr 2.45fr 2.3fr 0.9fr;
		}
	}

	&--merchant-settings-limitations {
		& .tr {
			grid-template-columns: 2.17fr 2.11fr 2.13fr 1.86fr 1.67fr 1fr;
		}
	}
	&--merchant-settings-autolimit {
		& .tr {
			grid-template-columns: 2.83fr 2fr 1fr;
		}
	}
	&--merchant-settings-autoconvert {
		& .tr {
			grid-template-columns: 2.25fr 2fr 1fr;
		}
	}
	//End Merchant Settings

	//End components Settings

	&--merchant-client {
		& .tr {
			grid-template-columns: 0.57fr 0.82fr 1.97fr 1.26fr 1.07fr 0.77fr 0.87fr 1fr 0.62fr;
		}
	}
	&--merchant-client-new {
		& .tr {
			grid-template-columns: 0.57fr 0.82fr 0.82fr 1.97fr 1.26fr 1.07fr 0.77fr 0.87fr 0.65fr 0.62fr;
		}
	}
	&--merchant-client-edit {
		& .tr {
			grid-template-columns: 0.58fr 0.84fr 0.7fr 2fr 0.9fr 1.1fr 0.77fr 0.9fr 0.94fr 1fr 0.85fr 0.62fr;
		}
	}

	&--transaction-history-client {
		& .tr {
			grid-template-columns: 0.72fr 1.42fr 3.52fr minmax(151px, 1.78fr) 0.75fr 0.75fr 0.75fr;
		}
		.td {
			text-align: center;
			.td-name {
				justify-content: center;
			}
			@media screen and (max-width: 1199px) {
				text-align: left;
				.td-name {
					justify-content: flex-start;
				}
			}
		}
	}
	&--callbacks {
	}

	&--administration-team-members {
		& .tr {
			grid-template-columns: 0.32fr 2.37fr 1.5fr 1.55fr 1.1fr 1.77fr 1fr;
		}
	}

	//Transactions

	&--transactions-crypto {
		& .tr {
			//grid-template-columns: 50px 0.55fr 0.55fr 0.9fr 1.1fr 0.8fr 1fr 0.7fr 0.6fr 0.9fr 0.6fr 50px;
			grid-template-columns: 50px 0.55fr 0.55fr 0.9fr 0.9fr minmax(150px, 1fr) 1fr 0.6fr 0.65fr 0.85fr minmax(
					96px,
					0.6fr
				) 50px;
		}
		.copy-address > p {
			font-weight: 500;
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
	}
	&--transactions-fiat {
		& .tr {
			//grid-template-columns: 50px 0.55fr 0.55fr 0.9fr 1.1fr 0.8fr 1fr 0.7fr 0.6fr 0.9fr 0.6fr 50px;
			grid-template-columns: 50px 0.55fr 0.55fr 1fr 0.8fr minmax(150px, 1fr) 1fr 0.6fr 0.65fr 0.85fr 50px;
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
	}

	&--project-transactions-crypto {
		& .tr {
			grid-template-columns: 60px 0.65fr 0.65fr 1fr 1fr 0.75fr 0.7fr 1fr 0.7fr 50px;
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
		.copy-address--type2 p {
			overflow: visible;
		}
	}
	&--project-transactions-fiat {
		& .tr {
			grid-template-columns: 55px 0.6fr 0.6fr minmax(150px, 1.1fr) 1fr 0.75fr 0.7fr 1fr 0.7fr 50px;
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
		.copy-address--type2 p {
			overflow: visible;
		}
	}
	&--project-transactions-approvements {
		& .tr {
			grid-template-columns: 0.3fr 0.95fr 1.1fr 0.9fr 0.8fr 1.1fr 0.93fr 1.04fr 0.8fr 0.8fr 0.6fr minmax(
					70px,
					0.6fr
				) 0.56fr;
		}
	}
	&--transactions-approvements {
		& .tr {
			grid-template-columns: 30px 0.8fr 0.95fr 0.65fr 0.62fr 0.94fr minmax(92px, 0.9fr) 0.85fr 0.65fr 0.63fr 0.59fr 0.4fr 50px;
		}
		.table-header {
			.td {
				word-break: normal;
			}
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
	}
	//End Transactions

	//market
	&--market-currencies {
		& .tr {
			grid-template-columns: 1.3fr 1fr 1fr 1fr 0.8fr 70px;
		}
	}

	&--market-limitations {
		& .tr {
			//grid-template-columns: 1.5fr 1.5fr 1.5fr 1.75fr 1.2fr 1.25fr 1.19fr .85fr;
			grid-template-columns: 1.5fr 1.5fr 1.5fr 1.75fr 1.2fr 1.2fr 1.25fr 0.85fr;
		}
	}

	&--market-free-withdraw {
		& .tr {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
	//End market

	//liquidity
	&--liquidity-providers-binance {
		& .tr {
			grid-template-columns: minmax(200px, 0.9fr) minmax(180px, 2.6fr) 0.9fr 0.9fr minmax(
					150px,
					0.6fr
				);
			gap: 16px;
			@media screen and (max-width: 1199px) {
				gap: 12px;
			}
		}
		.copy-address {
			position: relative;
			max-width: 550px;
			> p {
				display: block;
				font-weight: 500;
			}
			.tooltip {
				word-break: break-word;
				white-space: normal;
				&--top {
					bottom: 100%;
				}
			}
			&:hover {
				.tooltip {
					display: block;
				}
			}
		}
	}
	&--liquidity-providers-kraken {
		& .tr {
			grid-template-columns: 3.17fr 2.93fr 2.4fr 2.54fr;
		}
	}
	&--liquidity-providers-huobi {
		& .tr {
			grid-template-columns: 3.17fr 2.93fr 2.4fr 2.54fr;
		}
	}
	//End liquidity

	&--report-table-сryptocurrency-transactions {
		& .tr {
			grid-template-columns: 2.13fr 2.45fr 2.45fr;
			&--type2 {
				font-size: 20px;
				color: var(--main-color);
				font-weight: 600;
			}
		}
	}

	&--project-approvements {
		& .tr {
			grid-template-columns: 1.85fr 1.65fr 2.55fr 2.2fr 1.6fr 1.08fr;
		}
	}

	&--reports-analytics-report {
		& .tr {
			grid-template-columns: 2.4fr 2.6fr 2.6fr 1.8fr 1.5fr;
		}
	}

	&--reports {
		& .tr {
			grid-template-columns: 2.25fr 2.5fr 2.52fr 1.75fr 1.46fr;
		}
	}

	// account
	&--account-crypto {
		& .tr {
			grid-template-columns: 1.45fr 1.35fr 0.85fr 1.1fr 1.3fr 1.15fr minmax(300px, 3.6fr);
			@media screen and (max-width: 1380px) {
				grid-template-columns: 1.52fr 1.5fr 0.85fr 1fr 1.3fr 1.15fr minmax(330px, 3.6fr);
			}
		}
		.tr {
			&:nth-last-child(4),
			&:nth-last-child(3),
			&:nth-last-child(2),
			&:last-child {
				.select__drop {
					top: unset;
					bottom: calc(100% + 10px);
				}
			}
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				.select__drop {
					top: calc(100% + 10px);
					bottom: unset;
				}
			}
		}
	}
	&--account-fiat {
		& .tr {
			grid-template-columns: 1.9fr 1.2fr 1.35fr 1.45fr 1.3fr 3.6fr;
		}
	}
	&--account-currency-settings-currencies {
		& .tr {
			grid-template-columns: 1fr 1fr;
		}
	}
	&--account-currency-settings-new {
		& .tr {
			grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr minmax(60px, 0.4fr) minmax(50px, 0.4fr);
		}
	}
	&--account-currency-settings-limitation {
		& .tr {
			grid-template-columns: 2.55fr 1.75fr 1.9fr 1.85fr 1.8fr 1fr;
		}
	}

	&--account-currency-settings-limitation2 {
		& .tr {
			grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}
	&--account-currency-settings-limitation,
	&--account-currency-settings-limitation2 {
		.tooltip:after {
			// display: none;
		}

		.table-header {
			.td {
				&:nth-last-child(1),
				&:nth-last-child(2) {
					.tooltip {
						left: unset;
						transform: none;
						right: -16px;
						&:after {
							left: unset;
							right: 20px;
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
		.table-body {
			@media screen and (max-width: 1199px) {
				.td {
					.tooltip-item {
						position: static;
					}
					.tooltip {
						left: 0;
						transform: none;
					}
					&:nth-child(2n) {
						.tooltip {
							left: -20px;
						}
					}
				}
			}
		}
	}
	// account end

	// finance

	&--finance-settings-currencies {
		& .tr {
			grid-template-columns: 3.8fr 3.2fr 2fr 2fr;
		}
	}

	&--finance-settings-limitations {
		& .tr {
			grid-template-columns: 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 0.6fr;
		}
	}

	&--finance-settings-autolimit {
		& .tr {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}

	&--finance-settings-autoconvert {
		& .tr {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}

	// finance end

	&--pending-withdrawal {
		& .tr {
			align-items: flex-start;
			//grid-template-columns: 53px 55px 85px 155px 60px 65px 70px 70px 55px 86px 120px 179px;
			grid-template-columns: 53px 0.55fr 0.85fr 1.3fr 0.66fr 0.71fr 0.76fr 0.76fr 0.61fr 0.87fr 1.2fr 175px;
		}
	}
	&--pending-withdrawal-fiat {
		& .tr {
			align-items: flex-start;
			//grid-template-columns: 53px 55px 85px 155px 60px 65px 70px 70px 55px 86px 120px 179px;
			grid-template-columns: 53px 0.6fr 0.85fr 1.3fr 0.66fr 0.71fr 0.76fr 0.61fr 0.87fr 1fr 175px;
		}
	}

	//BANK ACCOUNT

	&--profile-bank-account {
		& .tr {
			grid-template-columns: 1.22fr 1fr 1fr 3fr 0.6fr;
		}
		.token {
			&__name {
				font-weight: 500;
				font-size: 14px;
				line-height: 165%;
			}
		}
	}

	//provider-administration start

	&--provider-administration-team-members {
		& .tr {
			align-items: flex-start;
			grid-template-columns: 0.35fr 1.4fr 2.35fr 1.6fr 1.7fr 1.5fr 1.05fr 0.8fr;
		}
	}

	&--provider-administration-user-profile-role-project {
		& .tr {
			align-items: flex-start;
			grid-template-columns: 1fr 1fr;
		}
	}

	//provider-administration end

	&--invoice {
		& .tr {
			align-items: flex-start;
			grid-template-columns: minmax(40px, 0.22fr) minmax(162px, 0.9fr) 0.65fr minmax(55px, 0.65fr) minmax(
					80px,
					0.75fr
				) minmax(65px, 0.67fr) 1fr 1fr minmax(80px, 0.8fr) 0.9fr 1.58fr 0.8fr 60px;
		}
		.tooltip--top {
			bottom: 100%;
		}
		.td {
			text-align: center;
			.td-name {
				justify-content: center;
			}
			@media screen and (max-width: 1199px) {
				text-align: left;
				.td-name {
					justify-content: flex-start;
				}
			}
			&:nth-child(12) {
				word-break: break-word;
			}
		}
	}

	&--orders {
		& .tr {
			//grid-template-columns: .2fr 1.48fr .57fr .59fr .65fr .62fr .86fr .95fr .66fr .70fr .7fr minmax(60px, .6fr);
			grid-template-columns: 0.2fr 1.38fr 0.57fr 0.75fr 0.62fr 0.86fr 0.95fr 0.66fr 0.7fr 0.7fr minmax(
					60px,
					0.6fr
				);
		}
		.td {
			&:nth-child(11) {
				word-break: break-word;
			}
		}
	}

	&--provider-payment-transactions {
		& .tr {
			grid-template-columns: 1.1fr 1.3fr 1.95fr 1.1fr 1.1fr 1fr 2.3fr 0.85fr;
		}
	}
	&--payment-transactions {
		& .tr {
			//grid-template-columns: 0.85fr 0.85fr 1.3fr 1.95fr 1.1fr 1.1fr 1fr 2.3fr .85fr;
			grid-template-columns: 0.75fr 0.85fr 0.85fr 1.95fr 1.1fr 1.1fr 1fr 2.3fr 0.85fr;
		}
	}
	&--provider-wallet-addresses {
		& .tr {
			grid-template-columns: 1fr 1fr 1fr minmax(100px, 3.45fr) 1.1fr 1.1fr 1.3fr 1.3fr;
		}
		.copy-address {
			max-width: 380px;
			justify-content: space-between;
		}
	}

	&--project-wallet-addresses {
		& .tr {
			grid-template-columns: 1fr 1fr minmax(100px, 3.45fr) 1.1fr 1.1fr 1.3fr 1.3fr;
		}
		.copy-address {
			max-width: 380px;
		}
	}

	&--project-wallet-addresses-user-id {
		& .tr {
			grid-template-columns: 1.25fr 1fr 3.75fr 1.1fr 1.1fr 1.3fr 1.3fr;
		}
	}

	&--approvements {
		.table-header {
			.tr {
				align-items: flex-end;
			}
		}
		& .tr {
			grid-template-columns: 0.63fr 0.7fr 1.35fr 1.1fr 1.45fr 0.85fr 0.75fr 0.6fr 0.65fr 0.8fr 0.7fr minmax(
					58px,
					0.51fr
				);
		}
	}

	&--approvements-provider {
		& .tr {
			grid-template-columns: 50px 0.95fr 1.1fr 1.15fr 0.85fr 0.75fr 0.85fr 0.6fr 0.65fr 0.8fr 0.7fr 0.51fr;
		}
	}
}

// 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px

.tr {
	.table-buttons-drop {
		min-width: 120px;
	}
	&:nth-last-child(3),
	&:nth-last-child(2),
	&:last-child {
		.table-buttons-drop {
			top: unset;
			bottom: calc(100% + 8px);
		}
	}
	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		.table-buttons-drop {
			top: calc(100% + 8px);
			bottom: unset;
		}
	}

	&.active {
		.td-check {
			svg path {
				fill: var(--green-color);
			}
		}
	}
}

.td-status {
	&--green {
		color: #00ba88 !important;
	}
	&--red {
		color: #fd3232 !important;
	}
	&--grey {
		color: #9996be !important;
	}
}

.d-block {
	display: block;
}

.header-admin {
	display: flex;
	align-items: center;
	gap: 16px;

	&__avatar {
		padding: 8px;
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f2f6ff;
		border-radius: 12px;

		@media screen and (max-width: 560px) {
			display: none;
		}

		svg,
		img {
			max-width: 100%;
		}
	}

	&__name {
		color: var(--text-color-main);
		text-align: left;

		span {
			display: block;
			font-weight: 600;
			font-size: 16px;

			&:nth-child(2) {
				font-weight: 400;
				font-size: 12px;
			}
		}
	}
}

.header-project {
	&__name {
		padding-left: 16px;
		font-weight: 600;
		font-size: 16px;
		@media screen and (max-width: 991px) {
			display: none;
		}
	}
}

.table-plug {
	margin-top: 64px;
	padding-bottom: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__icon {
		max-width: 120px;
		margin-bottom: 12px;
	}

	&__text {
		margin-bottom: 32px;
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
}

.popup-window {
	background: rgba(153, 150, 190, 0.7);
	backdrop-filter: blur(4px);
}

.popup-content {
	max-width: 100%;
	padding: 16px;
}

.popup {
	padding: 40px 32px;
	width: 465px;

	&__close {
		width: 24px;
		height: 24px;
		svg path {
			transition: all 0.2s linear;
		}
		&:hover {
			svg path {
				stroke: var(--text-color-main);
			}
		}
	}
	&--width-480 {
		width: 480px;
	}
	&--width-540 {
		width: 540px;
	}
	&--width-620 {
		width: 620px;
	}
	&--width-660 {
		width: 660px;
	}
	&--width-800 {
		width: 800px;
	}
	@media screen and (max-width: 575px) {
		padding: 32px 24px 24px 24px;
	}
}

.popup-header {
	&__title {
		font-size: 20px;
	}
	&__subtitle {
		color: var(--text-color2);
	}
}

.popup-body {
	margin-top: 8px;
	&--type2 {
		margin-top: 32px;
	}
	&--type3 {
		margin-top: 24px;
	}
	&--mt-16 {
		margin-top: 16px;
	}
	.radio {
		&__text {
			font-size: 16px;
		}
	}
}

.popup-text {
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: var(--text-color2);
	line-height: 1.6;
	&--fz-14 {
		font-size: 14px;
	}
	&--type2 {
		font-size: 14px;
		text-align: left;
	}
	&--type3 {
		margin-top: 8px;
		text-align: left;
	}
}

.popup-button {
	margin-top: 26px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&--type2 {
		flex-direction: row;
	}
}

.back-page {
	margin-bottom: 16px;
	display: inline-flex !important;
	align-items: center;
	gap: 16px;
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
	&--color2 {
		color: var(--text-color-main);
		svg path {
			stroke: var(--main-color);
		}
	}
}

.fields-wrap {
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 64px;

	@media screen and (max-width: 1199px) {
		gap: 32px;
	}

	@media screen and (max-width: 720px) {
		grid-template-columns: repeat(1, 1fr);
	}
	&--one-col {
		grid-template-columns: minmax(320px, 559px);
	}
}

.fields-list {
	&__title {
		margin-bottom: 24px;
		font-weight: 600;
		font-size: 18px;
	}
}

.fields-button {
	margin-top: 40px;
	display: flex;
	justify-content: flex-end;
	gap: 16px;

	&--type2 {
		justify-content: space-between;
	}
	&--mt360 {
		margin-top: 360px;
		@media screen and (max-width: 999px) {
			margin-top: 40px;
		}
	}
}

.tabs {
	padding-bottom: 15px;
	border-bottom: 1px solid var(--border-color-main);
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

	&__item {
		padding: 0 15px;
		position: relative;
		min-width: 160px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color2);

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -16px;
			display: none;
			width: 100%;
			height: 4px;
			background: var(--main-color);
			border-radius: 2px 2px 0px 0px;
		}

		&.active {
			color: var(--text-color-main);

			&::before {
				display: block;
			}
		}
	}
	& + .content-header {
		margin-top: 16px;
	}
}

.tfa-block-wrap {
	padding-bottom: 24px;
	margin-bottom: 40px;
	border-bottom: 1px solid var(--border-color-main);
}

.tfa-block {
	max-width: 520px;
	margin-top: 32px;

	&__title {
		margin-bottom: 4px;
		font-weight: 600;
		font-size: 18px;
		color: var(--text-color-main);
	}

	&__box {
		display: flex;
		align-items: flex-start;
		gap: 20px;
	}

	&__text {
		margin-bottom: 24px;
		max-width: 464px;
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
}

.content-title {
	margin-bottom: 32px;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	color: var(--text-color-main);
}

.projects {
	margin-top: 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;

	@media screen and (max-width: 1150px) {
		grid-template-columns: 1fr !important;
	}
}

.project {
	padding: 32px 24px;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}
	&.active {
		box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
	}

	&__top {
		margin-bottom: 24px;
	}

	&__head {
		display: flex;
		align-items: flex-start;
	}

	&__icon {
		display: flex;
		width: 61px;
		margin-right: 16px;
	}

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
	}

	&__btn-wrapper {
		position: relative;
		margin-left: auto;
	}

	&__btn {
		display: flex;
		width: 24px;
		height: 24px;
		align-items: center;
		gap: 4px;

		span {
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #9996be;
		}
	}

	&__info {
		padding-left: 77px;
		display: flex;
		justify-content: space-between;
	}

	&__date {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}

	&__status {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		padding-top: 16px;
		border-top: 1px solid var(--border-color-main);
	}

	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
	}

	&__number {
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
	}

	.dropdown {
		right: 0;
		width: 220px;
	}
}

.project--inactive {
	.project__title,
	.project__date,
	.project__text,
	.project__number {
		color: var(--text-color2);
	}

	.project__icon {
		opacity: 0.5;
	}
}

.token {
	display: flex;
	align-items: center;
	text-align: left;
	//flex-wrap: wrap;

	&__icon {
		display: flex;
		width: 34px;
		margin-right: 12px;
		&--type2 {
			width: 28px !important;
		}
	}
	&__id {
		display: block;
		font-weight: 600;
		font-size: 14px;
		color: var(--text-color2);
	}
	&__name {
		margin-right: 8px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color-main);
	}

	&__transcription {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
	&__network {
		display: block;
		font-weight: 500;
		font-size: 14px;
		color: var(--text-color2);
	}

	&--type2 {
		.token {
			&__icon {
				display: flex;
				width: 40px;
				margin-right: 12px;
			}
			&__name {
				font-weight: 500;
				font-size: 14px;
			}
			&__transcription {
				font-size: 14px;
			}
		}
	}
	&--no-wrap {
		flex-wrap: nowrap;
	}
}

.select-info,
.input-info {
	margin-top: 8px;
	display: flex;
	gap: 8px;

	&__name {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}

	&__number {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color-main);

		button {
			color: var(--main-color);
		}
	}
	&__number-price {
		font-size: 14px;
		font-weight: 400;
		color: var(--main-color);
	}
}

.dropdown {
	position: absolute;
	top: 25px;
	display: none;
	width: 100%;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	background-color: #fff;

	&.active {
		display: block;
	}
}

.dropdown-item {
	border-bottom: 1px solid var(--border-color-main);
	transition: all 0.3s ease;

	&:hover {
		background-color: #f4f4f4;
	}

	&:first-child {
		border-radius: 12px 12px 0 0;
	}

	&:last-child {
		border-radius: 0 0 12px 12px;
		border-bottom: none;
	}

	&__link {
		display: block;
		padding: 13px 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__icon {
		display: flex;
		width: 24px;
	}

	&__name {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color-main);
	}
}

.tabs-buttons {
	margin-top: 24px;
	padding: 16px 20px;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 8px;
	background: #f2f6ff;
	border-radius: 12px;
}

.tabs-buttons-wrap {
	margin-top: 24px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	.input {
		margin-bottom: 0;
	}
	.tabs-buttons {
		margin-top: 0;
	}
}

.table-filter {
	margin-top: 24px;
	display: grid;
	align-items: flex-end;
	grid-template-columns: 1fr 1fr 50px 170px;
	gap: 12px;

	.select-block,
	.input {
		margin-bottom: 0;
	}
	.select__current,
	.input-item {
		padding: 0 12px;
	}

	&--approval-management-team-members {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1300px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--reports-analytics-report {
		grid-template-columns: 1fr 1fr 1fr 186px;
		@media screen and (max-width: 1240px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--reports {
		grid-template-columns: 1fr 1fr 1fr 50px 186px;

		@media screen and (max-width: 1280px) {
			display: flex;
			flex-wrap: wrap;
			.select-block,
			.input {
				width: calc((100% - 24px) / 3);
			}
		}
		@media screen and (max-width: 767px) {
			.select-block,
			.input {
				width: 100%;
			}
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--transactions-page {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1199px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media screen and (max-width: 820px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--transactions-page-second {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1199px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media screen and (max-width: 820px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--transactions-submit {
		grid-template-columns: 1fr 1fr auto;
		@media screen and (max-width: 1100px) {
			display: flex;
			flex-wrap: wrap;
			.select-block {
				width: calc(50% - 6px);
			}
		}
		@media screen and (max-width: 575px) {
			.select-block {
				width: 100%;
			}
		}
	}
	&--transactions-submit-second {
		grid-template-columns: 1fr 1fr 1fr auto;
		@media screen and (max-width: 1100px) {
			display: flex;
			flex-wrap: wrap;
			.select-block {
				width: calc(33.3% - 12px);
			}
		}
		@media screen and (max-width: 767px) {
			.select-block {
				width: calc(50% - 6px);
			}
		}
		@media screen and (max-width: 575px) {
			.select-block {
				width: 100%;
			}
		}
	}
	&--invoice {
		grid-template-columns: 1fr 0.9fr 0.9fr 1fr minmax(150px, 1fr) minmax(150px, 1fr) 50px 160px;
		.input-item--right-icon {
			padding-right: 35px;
		}
		.button {
			padding: 0 16px;
		}

		@media screen and (max-width: 1340px) {
			display: flex;
			flex-wrap: wrap;
			.select-block,
			.input {
				width: calc((100% - 36px) / 4);
			}
		}
		@media screen and (max-width: 767px) {
			.select-block,
			.input {
				width: calc(50% - 6px);
			}
		}
		@media screen and (max-width: 575px) {
			.select-block,
			.input {
				width: 100%;
			}
		}
	}
	&--wallet-transaction-history {
		grid-template-columns: 1fr 1fr 1fr 50px 170px;
		@media screen and (max-width: 1280px) {
			display: flex;
			flex-wrap: wrap;
			.select-block,
			.input {
				width: calc((100% - 24px) / 3);
			}
		}
		@media screen and (max-width: 767px) {
			.select-block,
			.input {
				width: calc(50% - 6px);
			}
		}
		@media screen and (max-width: 575px) {
			.select-block,
			.input {
				width: 100%;
			}
		}
	}
	&--wallet-profit {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1280px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--pending-withdraw {
		grid-template-columns: 260px 300px auto;
		@media screen and (max-width: 1280px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--wallet-addresses {
		//grid-template-columns: 1.2fr 1fr 300px;
		grid-template-columns: 40% 1fr 300px;
		@media screen and (max-width: 1280px) {
			display: flex;
			flex-wrap: wrap;
			> div {
				width: calc((100% - 12px) / 2);
				&:first-child {
					width: 100%;
				}
			}
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
			.select-block,
			.input {
				width: 100%;
			}
		}
	}

	&--wallet-addresses-second {
		//grid-template-columns: 1.2fr 1fr 238px 50px;
		grid-template-columns: 40% 1fr 238px 50px;
		@media screen and (max-width: 1280px) {
			display: flex;
			flex-wrap: wrap;
			> div {
				width: calc((100% - 12px) / 2);
				&:first-child {
					width: 100%;
				}
			}
		}
		@media screen and (max-width: 767px) {
			.select-block,
			.input {
				width: 100%;
			}
		}
	}
	&--wallet-addresses-provider {
		//grid-template-columns: 2fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 40%;
		@media screen and (max-width: 1280px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}

	&--payment-transactions {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1280px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}

	&--orders {
		grid-template-columns: 1fr 1fr 1fr 50px;
		@media screen and (max-width: 767px) {
			display: flex;
			flex-wrap: wrap;
			.select-block,
			.input {
				width: 100%;
			}
		}
	}
	&--callbacks {
		//grid-template-columns: 1.75fr 1.75fr 2.65fr 1.75fr 50px 170px;
		grid-template-columns: 1.65fr 1.65fr 1.55fr 1.55fr 1.2fr 50px 160px !important;
		.button {
			padding: 0 8px;
		}
		@media screen and (max-width: 1300px) {
			grid-template-columns: 1fr 1fr !important;
			.button--icon {
				width: 100%;
			}
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr !important;
		}
	}
	.react-datepicker {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		min-width: 350px;
		gap: 8px;
		&____triangle {
			display: none !important;
		}
	}
	.react-datepicker__navigation {
		display: none;
	}
}

/*-------DATE PICKER------*/

.react-datepicker-popper {
	z-index: 10;
}
.react-datepicker {
	border: none;
}

.react-datepicker__month-container {
	background: #f2f6ff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
}

.react-datepicker__header {
	background: transparent;
}

.react-datepicker__current-month {
	margin-top: 8px;
}

.react-datepicker-time__header {
	margin-top: 8px;
}

.react-datepicker__header__dropdown {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 16px;
	gap: 0 8px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
	border: none !important;
	outline: none !important;
	height: auto;
	font-size: 14px;
	font-weight: 700;
	background: transparent;
	font-family: inherit;
}

.react-datepicker__time-list {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background-color: #ddd8d8;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #7093d4;
		border-radius: 2px;
	}
}

.react-datepicker__time-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	background: #f2f6ff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
}

.react-datepicker__time-container .react-datepicker__time {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.react-datepicker__time-box {
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	height: 274px !important;
	box-sizing: border-box;
}

.react-datepicker__time-container .react-datepicker__time {
	background: transparent;
}

.react-datepicker__day {
	border-radius: 5px !important;
}

.date-select {
	position: relative;
	&__current {
		display: flex;
		align-items: center;
		gap: 12px;
		font-weight: 700;
		font-size: 14px;
	}
	&__current-arrow {
		display: flex;
		flex: 0 0 12px;
		max-width: 12px;
	}
	&.active {
		.date-select__drop {
			display: block;
		}
	}
	&__drop {
		position: absolute;
		display: none;
		left: 0;
		top: calc(100% + 10px);
		width: max-content;
		min-width: 70px;
		max-height: 224px;
		overflow: auto;
		border: 1px solid var(--border-color-main);
		background: #fff;
		border-radius: 12px;
		padding: 0;
		z-index: 10;
		&::-webkit-scrollbar {
			width: 4px;
		}
		&::-webkit-scrollbar-track {
			background-color: #ddd8d8;
			border-radius: 2px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #7093d4;
			border-radius: 2px;
		}
		ul {
			> li {
				padding: 8px 12px;
				font-size: 14px;
				font-weight: 500;
				text-align: left;
				cursor: pointer;
				transition: all 0.3s ease;
				&:hover {
					background: var(--main-color);
					color: #fff;
				}
			}
		}
	}
}

/*------END DATE PICKER-----*/

.filter-btns {
	display: flex;
	align-items: center;
	gap: 8px;
	&--type2 {
		justify-content: flex-end;
		@media screen and (max-width: 1199px) {
			justify-content: flex-start;
		}
	}
	@media screen and (max-width: 767px) {
		justify-content: flex-start;
		flex-wrap: wrap;
	}
}

.roles-list-item {
	padding: 24px 0;
	border-bottom: 1px solid var(--border-color-main);
	&__title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
	}
	&__text {
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
}

.block-title-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.block-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 150%;
	&--type2 {
		margin-top: 40px;
	}
	&--fs-18 {
		font-size: 18px;
	}
}

.buttons-wrap {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	gap: 16px;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	@media screen and (max-width: 560px) {
		width: 100%;
	}
	&--type2 {
		justify-content: space-between;
	}
	&--type3 {
		justify-content: flex-end;
	}
	.button {
		@media screen and (max-width: 560px) {
			width: 100%;
		}
	}
}

.edit-role-info-pop {
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color2);
	text-align: left;
}

.qr-code {
	margin: 32px auto;
	text-align: center;
	img {
		max-width: 100%;
	}
}

.copy-address {
	padding: 24px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	word-break: break-word;
	background: #f2f6ff;
	border-radius: 12px;
	&--type2 {
		background: none;
		padding: 0;
		font-weight: 400;
		justify-content: flex-start;
		p {
			position: relative;
			overflow: hidden;
			max-width: calc(100% - 26px);
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	&--sb {
		justify-content: space-between;
	}

	button {
		position: relative;
		width: 18px;
		min-width: 18px;
	}
}

.table-hash {
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		flex: 0 0 16px;
	}
}

.deposit-info-pop {
	padding: 24px;
	background: #f2f6ff;
	border-radius: 12px;

	ul {
		display: flex;
		flex-direction: column;
		gap: 8px;
		li {
			display: flex;
			justify-content: space-between;
			gap: 8px;
			font-weight: 500;
			font-size: 14px;
			word-break: break-word;
			span {
				color: var(--text-color2);
			}
		}
	}
}

.input-text {
	position: absolute;
	top: 50%;
	right: 16px;
	font-size: 16px;
	color: var(--text-color2);
	transform: translateY(-50%);
}

.button-add {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: var(--main-color);
}

.item-wrap {
	margin-top: 32px;
	margin-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.item {
	max-width: 100%;
	width: 200px;
	.input,
	.select-block {
		margin-bottom: 0;
	}
	@media screen and (max-width: 720px) {
		width: 100%;
	}
}

.analytics-list {
	max-width: 1600px;
	margin-bottom: 40px;
	display: flex;
	gap: 28px;
	&--type2 {
		margin-bottom: 40px;
	}
	@media screen and (max-width: 1199px) {
		margin-bottom: 40px;
	}
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
}

.chartjs-render-monitor {
	width: 100% !important;
}

.analytics-item {
	width: 100%;
	@media screen and (max-width: 991px) {
		width: 100%;
	}
	&__title {
		margin-bottom: 18px;
		font-weight: 600;
		font-size: 18px;
	}
	&__schedule {
		position: relative;
		max-height: 334px;
		img,
		iframe {
			width: 100%;
		}
	}
}

.analytics-item-info {
	margin-bottom: 24px;
	display: flex;
	gap: 66px;
	&__item {
		p {
			font-size: 14px;
		}
		span {
			font-weight: 600;
			font-size: 20px;
		}
	}
}

.analytics-data-wrap {
	display: flex;
	gap: 24px;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
		gap: 0;
	}
	&--border-top {
		border-top: 1px solid var(--border-color-main);
		padding-top: 40px;
	}
	&--type2 {
		@media screen and (max-width: 1240px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0 24px;
			.analytics-data {
				margin-bottom: 24px;
			}
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}
	&--grid-type {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0 24px;
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}
	}
}

.analytics-data {
	margin-bottom: 40px;
	padding: 24px;
	width: 100%;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	@media screen and (max-width: 720px) {
		width: 100%;
		margin-bottom: 24px;
	}
	&--type2 {
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media screen and (max-width: 720px) {
			flex-wrap: wrap;
		}
	}
	&--type3 {
		text-align: center;
		* {
			justify-content: center;
		}
	}
	&__title {
		margin-bottom: 12px;
		font-weight: 600;
		font-size: 18px;
	}
	&__number {
		font-weight: 600;
		font-size: 20px;
		color: var(--main-color);
		p {
			display: flex;
			align-items: center;
		}
		span {
			font-weight: 400;
			font-size: 14px;
			color: var(--text-color-main);
			&.slash {
				padding: 0 14px;
				font-weight: 600;
				font-size: 20px;
				color: var(--text-color2);
			}
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 8px 32px;
	}
	&__schedule {
		margin-top: 32px;
		text-align: center;
		img {
			max-width: 100%;
		}
	}
}

.analytics-data-list {
	max-width: 100%;
	@media screen and (max-width: 720px) {
		width: 100%;
	}
	&:last-child {
		margin-right: 100px;
		@media screen and (max-width: 720px) {
			margin-right: 0;
		}
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;
		li {
			display: flex;
			align-items: center;
			gap: 16px;
			p {
				font-weight: 500;
				font-size: 14px;
				span {
					color: var(--text-color2);
				}
			}
		}
	}
}

.report-table-schedule {
	text-align: center;
	&--type2 {
		text-align: left;
	}
}

.report-frame {
	display: grid;
	grid-template-columns: 240px 1fr;
	gap: 40px 102px;
	padding: 40px 24px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	@media screen and (max-width: 1340px) {
		gap: 40px;
	}
	@media screen and (max-width: 1199px) {
		grid-template-columns: 1fr;
		padding: 24px;
	}
	&__item {
		display: flex;
		justify-content: center;
	}
	&__legend {
		display: flex;
		gap: 12px 102px;
		@media screen and (max-width: 1340px) {
			gap: 12px 40px;
		}
		@media screen and (max-width: 1340px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
			max-width: 400px;
			margin: 0 auto;
		}
	}
	&__legend-item {
		p {
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.report-table-title {
	margin-bottom: 22px;
	font-weight: 600;
	font-size: 20px;
}

.report-table {
	padding-bottom: 40px;
	margin-bottom: 40px;
	display: grid;
	grid-template-columns: 1fr 307px;
	align-items: flex-end;
	gap: 40px;
	border-bottom: 1px solid var(--border-color-main);
	@media screen and (max-width: 1200px) {
		grid-template-columns: repeat(1, 1fr);
	}

	&--type2 {
		padding-bottom: 0;
		border: none;
	}
	&--full-width {
		grid-template-columns: 1fr;
	}
}

.analytics-title {
	margin-bottom: 24px;
	font-weight: 600;
	font-size: 20px;
}

.status-block {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 4px;
	font-weight: 500;
}

.status-value {
	font-weight: 500;
	font-size: 14px;
	&--green {
		color: #00ba88 !important;
	}
}

.approvements-title {
	margin-top: 40px;
	margin-bottom: -8px;
	font-weight: 700;
	font-size: 20px;
}

.approvements-list {
	margin-bottom: 40px;
	margin-top: 36px;
	display: grid;
	grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
	gap: 40px;
	@media screen and (max-width: 860px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.approvements-user-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px 40px;
	margin-bottom: 40px;
	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
	.approvements-user {
		margin-top: 0;
	}
}

.approvements-item {
	display: flex;
	justify-content: space-between;
	gap: 8px;
	font-size: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color-main);
	@media screen and (max-width: 860px) {
		flex-wrap: wrap;
	}
	> p {
		color: var(--text-color2);
	}
	span {
		display: flex;
		gap: 16px;
		@media screen and (max-width: 860px) {
			flex-wrap: wrap;
		}
	}
	&__title {
		color: var(--text-color2) !important;
		white-space: normal;
	}
	&__value {
		display: flex;
		gap: 16px;
		word-break: normal;
		@media screen and (max-width: 860px) {
			flex-wrap: wrap;
		}
		&_right {
			flex-grow: 1;
			justify-content: flex-end;
			text-align: right;
		}
	}
	&__status {
		text-align: right;
		margin-left: auto;
	}
	&--status {
		justify-content: flex-start;
		gap: 16px;
	}
}

@media screen and (max-width: 860px) {
	.approvements-item__value {
		text-align: left;
	}
}

.approvements-column {
	display: flex;
	flex-direction: column;
	gap: 16px;
	&--type2 {
		justify-content: flex-end;
	}
}

.approvements-user {
	margin-top: 30px;
	padding: 24px;
	display: flex;
	gap: 16px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	&__icon {
		min-width: 46px;
	}
	&__name {
		font-weight: 600;
		font-size: 16px;
	}
	&__date {
		font-size: 12px;
		margin-bottom: 12px;
	}
	&__text {
		font-size: 16px;
		line-height: 160%;
		&--type2 {
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
		}
	}
	&__value {
		font-size: 20px;
		font-weight: 600;
	}
	@media screen and (max-width: 800px) {
		padding: 16px;
	}
}

.pop-text {
	font-weight: 500;
	font-size: 14px;
	color: var(--text-color2);
}

.fields-switch {
	margin-bottom: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--border-color-main);
	&__title {
		font-weight: 500;
		font-size: 16px;
	}
}

.fields-item--type3 {
	max-width: 480px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.api-container {
	padding-top: 40px;
}

.api-item {
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 0;
	}
}

.api-item-wrapper {
	display: flex;
	align-items: flex-end;
	gap: 16px;
	flex-wrap: wrap;

	.input {
		max-width: 523px;
	}
}

.block-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: var(--text-color2);

	&--mb32 {
		margin-bottom: 32px;
	}

	&--mb24 {
		margin-bottom: 24px;
	}

	&--mb16 {
		margin-bottom: 16px;
	}

	&--fs-14 {
		font-size: 14px;
	}

	&--line-165 {
		line-height: 1.65;
	}
}

.line-info-block {
	margin-bottom: 24px;
}

.info {
	max-width: 628px;
	margin-top: 16px;
	&--width-411 {
		max-width: 411px;
	}
	&--width-520 {
		max-width: 520px;
	}
	&--width-560 {
		max-width: 560px;
	}
}

.info-title {
	margin-bottom: 32px;
	font-weight: 700;
	font-size: 20px;
}

.line-info {
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color-main);
	word-break: break-all;
	line-height: 1.65;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
	}

	&--type2 {
		justify-content: space-between;
	}

	&__name {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
		@media screen and (max-width: 720px) {
			width: 100%;
		}
		&--type2 {
			font-size: 14px;
			color: var(--text-color-main);
			font-weight: 500;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color-main);
		&--type2 {
			font-size: 14px;
			font-weight: 500;
		}
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}
}

.user-profile-white-list-button {
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
}

.notifications {
	margin-top: 40px;
}

.notifications-title {
	margin-bottom: 32px;
	font-weight: 600;
	font-size: 18px;
}

.notifications-item {
	margin-bottom: 24px;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	border-bottom: 1px solid var(--border-color-main);
	&__title {
		margin-bottom: 6px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
	}
	&__text {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
}

.table-buttons {
	position: relative;
	align-items: center;
	&--gap4 {
		gap: 4px;
	}
	button + button {
		margin-left: 0;
	}

	@media screen and (max-width: 1380px) {
		button {
			&.button {
				flex: none;
				min-width: 62px;
				padding: 0 8px;
			}
		}
	}
	&--gap16 {
		gap: 16px;
	}
}

.table-buttons__group {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-left: 4px;
}

.table-buttons-drop {
	position: absolute;
	right: 0;
	top: calc(100% + 8px);
	width: max-content;
	border-radius: 12px;
	border: 1px solid var(--border-color-main);
	background: #fff;
	display: none;
	z-index: 10;
	&.active {
		display: block;
	}
	@media screen and (max-width: 1199px) {
		left: 0;
		right: unset;
	}
	&__item {
		padding: 8px 16px;
		display: block !important;
		height: 39px;
		color: #202020;
		text-align: left;
		border-bottom: 1px solid var(--border-color-main);
		font-weight: inherit;
		&:hover {
			color: var(--main-color);
			transition: 0.3s;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.withdrawal-info {
	margin-bottom: 8px;
	&__title {
		margin-bottom: 4px;
		font-size: 14px;
		line-height: 165%;
	}
	&__info {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
}

.progress-line {
	position: relative;
	margin: 8px 0;
	width: 100%;
	height: 4px;
	background: var(--border-color-main);
	border-radius: 20px;
	&__fill {
		position: absolute;
		width: auto;
		height: 100%;
		left: 0;
		top: 0;
		border-radius: 20px;
		background: #4285f5;
	}
}

.withdrawal-fee-choice {
	margin: 24px 0;
}

.withdrawal-fee-choice-title {
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 165%;
}

.withdrawal-fee-choice-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
}

.withdrawal-fee-choice-item {
	padding: 15px;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	text-align: left;
	&.active {
		border-color: #4285f5;
		.withdrawal-fee-choice-item {
			&__time {
				color: #4285f5;
			}
		}
	}
	&__time {
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
	&__price {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
}

.deposit-info-pop {
	margin-bottom: 24px;
	padding: 24px;
	background: #f2f6ff;
	border-radius: 12px;

	ul {
		display: flex;
		flex-direction: column;
		gap: 8px;
		@media screen and (max-width: 520px) {
			gap: 16px;
		}
		li {
			display: flex;
			justify-content: space-between;
			gap: 8px;
			font-weight: 500;
			font-size: 14px;
			word-break: break-word;
			@media screen and (max-width: 520px) {
				flex-wrap: wrap;
				> * {
					width: 100%;
					text-align: left;
					justify-content: flex-start;
				}
			}
			p {
				&.fw-600 {
					font-weight: 600;
				}
				&.fz-16 {
					font-size: 16px;
				}
			}
			span {
				color: var(--text-color2);
			}
			strong {
				color: #202020;
			}
		}
	}
}

.input-text {
	position: absolute;
	top: 50%;
	right: 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: var(--text-color2);

	button {
		padding: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 1;
		color: #4285f5;
	}
}

.button-add {
	margin-top: 4px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: var(--main-color);
}

.copy-wrap {
	display: flex;
	align-items: center;
	gap: 8px;
	&--right {
		margin-left: auto;
		justify-content: flex-end;
		text-align: right;
	}
	button {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		font-weight: 500;
		color: var(--main-color);
	}
	svg {
		min-width: 24px;
	}
}

.exchange-switch {
	margin: 0 auto;
	display: flex;
}

.checkout-url {
	margin-top: 38px;
	&__title {
		margin-bottom: 8px;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
	}
	&__text {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-color2);
	}
	&__item {
		display: flex;
		gap: 8px;
		@media screen and (max-width: 760px) {
			flex-wrap: wrap;
		}
	}
	&__link {
		max-width: 520px;
		padding: 12px 16px;
		display: flex;
		gap: 8px;

		border: 1px solid var(--border-color-main);
		border-radius: 12px;

		@media screen and (max-width: 760px) {
			max-width: 100%;
			width: 100%;
		}

		span {
			display: block;
			width: calc(100% - 30px);

			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.item-line {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	&--type2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: flex-start;
		@media screen and (max-width: 520px) {
			grid-template-columns: repeat(1, 1fr);
			gap: 0;
			.select-block__name {
				min-height: auto;
			}
		}
	}
	@media screen and (max-width: 520px) {
		width: 100%;
		> * {
			width: 100%;
		}
	}
}

.translations {
	margin: 32px 0;
}

.translations-item {
	padding: 16px 0;
	border-bottom: 1px solid var(--border-color-main);

	&:first-child {
		border-top: 1px solid var(--border-color-main);
	}

	&.active {
		.translations-item-title {
			svg {
				transform: rotate(180deg);
			}
		}
		.translations-item-body {
			display: block;
		}
	}
}

.translations-item-title {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
}

.translations-item-body {
	margin-top: 16px;
	display: none;
}

.translations-item-input {
	& + & {
		margin-top: 16px;
	}
}

.account-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 32px;
	margin-bottom: 40px;
}

.account-box {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	padding: 24px;
	background: #f2f6ff;
	border-radius: 12px;
	@media screen and (max-width: 1175px) {
		flex-direction: column;
	}
}

.account-item {
	&__line {
		display: flex;
		align-items: center;
		gap: 24px;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
	&__name {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
	&__value {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color-main);
		word-break: break-all;
	}
	&__buttons {
		display: flex;
		align-items: center;
		gap: 16px;
	}
	&__btn {
		display: flex;
		width: 23px;
	}

	&__header {
		margin-bottom: 24px;
	}

	&__header-name {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
	}

	&__header-value {
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
	}
}

.upload-file {
	&__buttons {
		&--mt8 {
			margin-top: 8px;
		}
	}
}

.api-container {
	padding-top: 40px;

	.block-text {
		max-width: 523px;
	}
}

.api-item {
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
}

.block-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: var(--text-color2);
	&--mb32 {
		margin-bottom: 32px;
	}
	&--mb24 {
		margin-bottom: 24px;
	}
	&--mb16 {
		margin-bottom: 16px;
	}
	&--fs-14 {
		font-size: 14px;
	}
	&--line-165 {
		line-height: 1.65;
	}
}

.api-item-wrapper {
	display: flex;
	align-items: flex-end;
	gap: 16px;
	flex-wrap: wrap;
	.input {
		max-width: 523px;
	}
}

.input-description {
	margin-top: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color2);
	&--error {
		color: #fd3232;
	}
}

.action-buttons {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	&__item {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		&--blue {
			color: #4285f5;
		}
		&--red {
			color: #fd3232;
		}
	}
	&__icon {
		display: flex;
		width: 24px;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.api-item-descr {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color-main);
}

.api-item-descr-block {
	margin-bottom: 32px;
}

.priority-label {
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	&--type1 {
		background: #00ba88;
	}
}

.fee-management-title {
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 23.1px;
		color: $globalBlack;
	}
	span {
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
}

.fee-manegenent-edit {
	opacity: 0;
	transition: 0.4s;
}

.fee-manegenent-input {
	width: 96px;
	height: 48px;
	border-radius: 8px;
	padding: 12px 16px 12px 16px;
	border: 1px solid $button-disable;
	color: $button-disable-border;
	font-weight: 500;
	font-size: 14px;
	line-height: 23.1px;
}

.fee-management-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 78px;
	border-top: 1px solid $button-disable;
	&:hover {
		& .fee-manegenent-edit {
			opacity: 1;
		}
	}
	.fee-manegenent-line {
		margin-bottom: 0;
	}
}

.fee-manegenent-head-right {
	display: flex;
	.td {
		width: 96px;
		margin-left: 16px;
	}
}

.fee-manegenent-line {
	max-width: 100%;
	display: flex;
	gap: 16px;
	margin-right: -103px;
	@media screen and (max-width: 620px) {
		grid-template-columns: repeat(1, 1fr);
	}
	.input {
		margin-bottom: 0;
	}
	.button {
		min-width: 86px;
	}
}

.invoices {
	margin: 0 auto;
	padding: 32px 0;
	width: 540px;
	max-width: 100%;
}

.invoices-header {
	padding: 0 12px;
	margin-bottom: 24px;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--border-color-main);
	&__invoices {
		font-weight: 400;
		font-size: 18px;
		line-height: 150%;
		color: var(--text-color2);
	}
}

.invoices-info {
	padding: 0 24px;
	&__item {
		margin-bottom: 8px;
		padding-bottom: 8px;
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		border-bottom: 1px solid var(--border-color-main);
		text-align: left;
		@media screen and (max-width: 440px) {
			flex-wrap: wrap;
			p {
				width: 100%;
			}
		}
	}
}

.invoices-timer-titel {
	padding: 0 24px;
	margin-top: 24px;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	text-align: left;
}

.invoices-timer {
	padding: 0 24px;
	display: grid;
	grid-template-columns: 1fr 1fr 3px 1fr 1fr 3px 1fr 1fr;
	align-items: center;
	gap: 6px;
	&__item {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 24px;
		border: 1px solid var(--border-color-main);
		border-radius: 12px;
		@media screen and (max-width: 480px) {
			height: 40px;
			font-size: 20px;
		}
	}
}

.invoices-total-info {
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	p {
		display: flex;
		justify-content: space-between;
		gap: 4px;
	}
	.fize2 {
		font-weight: 600;
		font-size: 16px;
	}
}

.order-line {
	margin-bottom: 16px;
	padding-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--border-color-main);
	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-color2);
	}
}

.main-info {
	display: flex;
	flex-direction: column;
	gap: 0;
	margin-top: 32px;
	padding-bottom: 100px;
}

.main-info-category {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 40px;

	.input {
		margin-bottom: 0;
	}
	& + .main-info-category {
	}
}

.content-header + .main-info-category {
	margin-top: 32px;
}

.category-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	color: #202020;
}

.input-line {
	display: flex;
	gap: 24px;
	&--other-info {
		display: grid;
		grid-template-columns: 3fr 1fr 1fr;
	}
	@media screen and (max-width: 1100px) {
		display: flex;
		flex-wrap: wrap;
		.input {
			width: 48%;
		}
	}
	&--type2 {
		@media screen and (max-width: 1100px) {
			flex-wrap: wrap;
			.input {
				width: 48%;
			}
		}
	}
	@media screen and (max-width: 650px) {
		display: flex;
		flex-direction: column;
		gap: 16px;
		.input {
			width: 100%;
		}
	}
}

.category-title-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.kyc-document-interactionist-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

.kyc-document-interactionist {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 523px;
	width: 523px;
	min-height: 58px;
	padding: 16px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	background: #f2f6ff;
}

.kyc-document-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
}

.kyc-document-buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.kyc-document-btn {
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	color: #4285f5;
	&--icon {
		display: flex;
		width: 24px;
	}
}

.balance-block {
	width: 100%;
	padding: 24px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	display: flex;
	align-items: center;
	gap: 24px;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: 74px;
		min-width: 74px;
		height: 74px;
		background: #f2f6ff;
		border-radius: 12px;
	}
	&__name {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		color: #202020;
	}
	&__count {
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #202020;
	}

	@media screen and (max-width: 620px) {
		padding: 16px;
	}
}

.order-line {
	margin-bottom: 16px;
	padding-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--border-color-main);
	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-color2);
	}
}

.input-info-left {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	gap: 8px;
	button {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: #4285f5;
	}
	span {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-color2);
	}
}

.payment-method-choose-section {
	padding: 25px 15px;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.payment-method-choose {
	padding: 40px 32px;
	width: 620px;
	max-width: 100%;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	@media screen and (max-width: 460px) {
		padding: 20px;
	}
	&__title {
		margin-bottom: 32px;
		font-weight: 500;
		font-size: 24px;
		line-height: 150%;
		color: #202020;
		@media screen and (max-width: 460px) {
			font-size: 22px;
		}
	}
	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;
		@media screen and (max-width: 460px) {
			grid-template-columns: repeat(1, 1fr);
			gap: 8px;
		}
	}
	&__list-item {
		padding: 24px 32px;
		height: 123px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--border-color-main);
		border-radius: 12px;
		transition: all 0.3s linear;
		&:hover {
			border-color: var(--main-color);
		}
		@media screen and (max-width: 460px) {
			height: 100px;
		}
	}
}

.payment-status {
	text-align: center;
	padding: 77px 0;
	&__icon {
		display: flex;
		justify-content: center;
		max-height: 64px;
		margin-bottom: 16px;
	}
	&__text {
		font-weight: 500;
	}

	&--success {
		color: #00ba88;
	}
	&--error {
		color: #fd3232;
	}
}

.block-warning {
	display: flex;
	align-items: center;
	margin-top: 32px;
	gap: 16px;
	padding: 12px 16px;
	background: rgba(66, 133, 245, 0.1);
	border-radius: 12px;
	&__icon {
		display: flex;
		width: 24px;
	}
	&__text {
		flex: 1;
		font-weight: 500;
		font-size: 14px;
	}
	&--type2 {
		background: rgba(253, 50, 50, 0.1);
		color: #fd3232;
		.block-warning__icon svg path {
			stroke: #fd3232;
		}
	}
	@media screen and (max-width: 767px) {
		margin-top: 24px;
	}
}

.payment-box {
	display: grid;
	grid-template-columns: 268px 1fr;
	gap: 16px 24px;
	margin-top: 32px;
	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		margin-top: 24px;
	}
}

.payment-qr {
	padding: 24px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	text-align: center;
	&__item {
		display: flex;
		justify-content: center;
		width: 120px;
		margin: 0 auto;
		width: 100%;
	}
	&__code {
		margin-top: 16px;
		word-break: break-word;
		font-size: 12px;
	}
}

.payment-details-box {
	&__btn {
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}
}

.payment-details {
	padding: 24px 0;
	border-top: 1px solid var(--border-color-main);
	&:not(:last-child) {
		border-bottom: 1px solid var(--border-color-main);
	}
	&__name {
		font-weight: 500;
		font-size: 14px;
	}
	&__value-block {
		display: flex;
		gap: 16px;
		justify-content: space-between;
		margin-top: 16px;
	}
	&__value {
		font-weight: 500;
	}
}

.payment-coin {
	display: flex;
	align-items: center;
	gap: 16px;
	&__icon {
		display: flex;
		width: 34px;
		height: 34px;
		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}
	p {
		display: flex;
		gap: 8px;
		font-weight: 500;
	}
	span {
		color: var(--text-color2);
		font-weight: 400;
	}
}

.operation-info-group {
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;
	margin-top: 32px;
}

.operation-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	&__value,
	&__name {
		font-size: 14px;
		font-weight: 500;
		color: var(--text-color2);
	}
	&__timer {
		background: var(--main-color);
		padding: 4px 16px;
		border-radius: 8px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
	}
	&--error {
		.operation-info__value {
			color: #fd3232;
		}
		.operation-info__timer {
			background: #fd3232;
		}
	}
}

.comissions-list {
	margin-top: 32px;
}

.comission-item {
	border-bottom: 1px solid var(--border-color-main);
	padding-bottom: 24px;
	&__title {
		display: flex;
		gap: 0 18px;
		font-weight: 600;
		@media screen and (max-width: 460px) {
			flex-wrap: wrap;
			> * {
				width: 100%;
			}
		}
	}
	&__text {
		margin-top: 8px;
	}
	&__input {
		margin-top: 24px;
		width: 800px;
		max-width: 100%;
		display: grid;
		align-items: flex-end;
		grid-template-columns: 1fr 1fr 85px;
		gap: 16px;
		.input {
			margin-bottom: 0;
		}
		@media screen and (max-width: 460px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	& + .comission-item {
		margin-top: 24px;
	}
}

.input-clear {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	width: 20px;
	&--right35 {
		right: 35px;
	}
}

.phone-input {
	display: flex;
	align-items: baseline;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	.select,
	.input-item {
		border: none;
	}
	.input-item {
		flex: 1;
		padding-left: 12px;
	}
	.select-block {
		flex: 0 0 auto;
		margin-bottom: 0px;
		border-right: 1px solid var(--border-color-main);
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.select {
		&__current {
			padding-right: 12px;
		}
		&__drop {
			width: 200px;
			top: unset;
			bottom: calc(100% + 10px);
		}
		.select__drop-scroll {
			max-height: 254px;
		}
		.select-block__title {
			margin-bottom: 0px;
		}
	}
	&.input--error {
		.input-item {
			border: 1px solid var(--red-color);
		}
	}
}

.green {
	color: #00ba88;
}

.red {
	color: #fd3232;
}

.grey {
	color: var(--text-color2);
}

.load {
	animation: loading 1.3s linear infinite;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


@import 'ui-modifiers.scss';
