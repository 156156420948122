.lds-dual-ring {
	display: inline-block;
	width: 90px;
	height: 90px;

	&:after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid #4285f5;
		border-color: #4285f5 transparent #4285f5 transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.table-position {
	position: relative;
}
.table-loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: 50px 0;
	height: 100%;
	top: 0px;
	left: 0;
	position: absolute;
	width: 100%;
	background: white;
	z-index: 10;
}

.lds-dual-ring-small {
	display: inline-block;
	width: 32px;
	height: 32px;
	vertical-align: middle;

	&:after {
		content: ' ';
		display: block;
		width: 20px;
		height: 20px;
		margin: 4px;
		border-radius: 50%;
		border: 3px solid #009a5b;
		border-color: #009a5b transparent #009a5b transparent;
		animation: lds-dual-ring-small 1.2s linear infinite;
	}
}

@keyframes lds-dual-ring-small {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
