@import 'assets/styles/scss/variables';

.col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0;
	&__major {
		color: $globalBlack;
	}
	&__minor {
		color: $globalTextSecondary;
	}
}
