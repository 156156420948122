@import 'assets/styles/scss/variables';

.link {
	border-radius: 8px;
	padding: 8px;
	margin-right: -12px;
	&:hover {
		background: $table-link-background;
	}
}
