@import 'assets/styles/scss/variables';

.fields {
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 64px;
	gap: 64px;
	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__buttons {
		display: flex;
		justify-content: flex-end;
		grid-gap: 16px;
		gap: 16px;
		align-self: flex-end;
	}
	& .mb_0 {
		margin-bottom: 0;
	}
}
