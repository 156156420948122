@import 'assets/styles/scss/variables';

.tooltip {
	display: inline-flex;
	margin-left: 4px;
	&__item {
		display: flex;
		align-items: center;
	}
	.tooltip__content {
		width: 320px;
		word-break: normal;
		white-space: normal;
		transform: translate(-50%, calc(-100% - 8px));
		position: absolute;
		display: none;
		background: $globalBlack;
		border-radius: 8px;
		padding: 8px 12px;
		z-index: 10;
	}

	&:hover .tooltip__content {
		display: block;
	}
}
