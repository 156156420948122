body {
	.selectInput {
		display: flex;
		align-items: center;
		&-img {
			margin-right: 5px;
			width: 25px;
			height: 25px;
		}
	}
}
