@import 'assets/styles/scss/variables';

.filters {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 16px;
	margin: 24px 0;
	&__button {
		padding: 0;
		flex-grow: 1;
	}
}

.search {
	margin: 0;
	&__input {
		margin: 0;
	}
}
