@import 'assets/styles/scss/variables';

.header {
	padding-right: 16px;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: right;
	color: $globalWhite;
	& p {
		font-weight: 400;
		font-size: 12px;
		line-height: 170%;
	}
	& span {
		font-weight: 700;
		font-size: 16px;
	}
}
