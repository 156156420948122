@import 'assets/styles/scss/variables';

.tooltip-wrap {
	&__right-align {
		text-align: right;
	}
	&__center-align {
		text-align: center;
	}
}

.tooltip-item {
	position: relative;
	display: inline-flex;
	&__absolute {
		position: absolute;
	}
	&__title {
		font-weight: 600;
	}
	&:hover {
		.tooltip {
			display: block;
		}
	}
}

.tooltip {
	position: absolute;
	display: none;
	width: 310px;
	background: $tooltip-bg-color;
	border-radius: 8px;
	padding: 8px 12px;
	z-index: 10;
	&__text {
		display: block;
		text-align: left;
		color: $tooltip-color;
		font-size: 12px;
	}
	&:after {
		content: '';
		position: absolute;
		border: 6px solid transparent;
		border-bottom: 6px solid $tooltip-bg-color;
	}
	&__bottom {
		top: calc(100% + 15px);
	}
	&__top {
		bottom: calc(100% + 15px);
	}
	&__center {
		left: 50%;
		transform: translateX(-50%);
	}
	&__left {
		left: 0;
	}
	&__right {
		right: 0;
	}
	&__arrow-top-left {
		&:after {
			top: -12px;
			left: 20px;
		}
	}
	&__arrow-top-right {
		&:after {
			top: -12px;
			right: 20px;
		}
	}
	&__arrow-top-center {
		&:after {
			top: -12px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&__arrow-bottom-left {
		&:after {
			bottom: -12px;
			left: 20px;
			transform: rotate(-180deg);
		}
	}
	&__arrow-bottom-right {
		&:after {
			bottom: -12px;
			right: 20px;
			transform: rotate(-180deg);
		}
	}
	&__arrow-bottom-center {
		&:after {
			bottom: -12px;
			left: 50%;
			transform: translateX(-50%) rotate(-180deg);
		}
	}
	&__width-170 {
		width: 170px;
	}
	&__width-320 {
		width: 320px;
	}
	&__width-400 {
		width: 400px;
	}
	&__width-auto {
		width: auto;
	}
}

.tooltip-item-custom .tooltip {
	display: block;
}

@media screen and (max-width: 475px) {
	.tooltip {
		max-width: 280px;
		font-size: 11px;
	}
}
