@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

.fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 64px;
	gap: 64px;
	& .mb_0 {
		margin-bottom: 0;
	}
}

.wrap {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid var(--border-color-main);
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	grid-gap: 16px;
	gap: 16px;
}

