@import '../../../../assets/styles/scss/variables';

.menu {
    position: absolute;
    right: 0;
    top: calc(100% + 15px);
    width: 220px;
    background: #ffffff;
    border: 1px solid var(--border-color-main);
    border-radius: 12px;
    z-index: 100;
    display: none;

    &.active {
        display: block;
    }

    &__item {
        cursor: pointer;
        padding: 13px 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        border-bottom: 1px solid #eaeaef;

        &.red {
            color: $globalRed;
        }

        @media screen and (max-width: 720px) {
            font-size: 15px;
        }

        svg {
            @media screen and (max-width: 720px) {
                max-width: 20px;
            }
        }

        &:last-child {
            border: none;
        }
    }
}
