@import 'assets/styles/scss/variables';

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	min-height: 100vh;
	max-height: 100vh;
	overflow-y: auto;
	background-color: $globalBlack;
	z-index: 1;
	// overflow-y: scroll; // TODO create custom scroll
	width: $globalSidebarWidth;
	padding: 16px 12px;
	border-right: 1px solid $layoutBorder;
	transition: 0.2s;
	&::-webkit-scrollbar {
		background: $scrollColor;
	}
	&__logo {
		display: flex;
		align-items: center;
		min-width: 183px;
		padding: 10px 20px 40px 20px;
		margin: 0;
		width: 100%;
	}

	&__title {
		padding: 0 0 8px 16px;
		font-weight: 700;
		font-size: 11px;
		letter-spacing: 2.5px;
		color: $globalTextSecondary;
		text-transform: uppercase;
	}
	&.active {
		width: $globalShortSidebarWidth;
		padding: 24px 4px;
		& .back {
			display: flex;
			flex-direction: column;
			margin-bottom: 4px;
			&__text {
				font-size: 10px;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0;
				text-align: center;
			}
		}
		& .sidebar__logo {
			width: 30px;
			min-width: 30px;
			padding: 0;
			margin: 0 auto 20px;
		}
		& .sidebar__title {
			display: none;
		}
		& .nav {
			max-height: 800px;
			&__link {
				display: flex;
				gap: 0;
				border-radius: 12px;
				font-weight: 600;
				font-size: 16px;
				line-height: 160%;
				color: $globalBlack;
				width: 100%;
				flex-direction: column;
				align-items: center;
				padding: 0;
				height: 53px;
				justify-content: space-around;
				margin-bottom: 8px;
				position: relative;
			}
			&__text {
				font-size: 10px;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0;
				text-align: center;
			}
			&__icon {
				width: 20px;
				flex: 0 0 20px;
			}
			&__dot {
				background: $globalOrange;
				text-align: center;
				line-height: 18px;
				font-size: 0;
				margin-left: auto;
				border-radius: 50%;
				max-width: 8px;
				height: 8px;
				min-width: 8px;
				position: absolute;
				top: 4px;
				right: 6px;
			}
		}
	}
}

.nav {
	margin-bottom: 16px;
	transition: 0.5s ease;
	max-height: 800px;
	overflow-y: hidden;
	&_hidden {
		max-height: 0;
	}
	&__text {
		max-width: 100%;
		font-size: 14px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $globalWhite
	}

	&__icon {
		display: flex;
		justify-content: center;
		width: 24px;
		flex: 0 0 24px;

		svg path {
			stroke: $globalMainBlue;
		}
	}

	&__dot {
		min-width: 20px;
		height: 17px;
		border-radius: 4px;
		background: $globalOrange;
		text-align: center;
		line-height: 18px;
		font-size: 12px;
		margin-left: auto;
	}

	&__link {
		display: flex;
		align-items: center;
		height: 40px;
		gap: 16px;
		padding: 0 16px;
		border-radius: 12px;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		color: $globalBlack;
		width: 100%;

		&:hover {
			background: $hoverGlobalMainBlue;
		}

		&.active {
			background: $globalMainBlue;
			color: $globalWhite;

			.nav {
				&__icon svg path {
					stroke: $globalWhite;
				}

				&__text {
					color: $globalWhite;
				}
			}
		}
	}
}

.back {
	display: flex;
	align-items: center;
	padding: 8px 16px 12px;
	gap: 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 23px;
	letter-spacing: 0;
	color: $globalWhite;
	border-bottom: 1px solid $card-border;
	margin-bottom: 4px;
	transition: 0.3s;
	& svg {
		width: 24px;
		height: 24px;
	}
}
