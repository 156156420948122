@import 'assets/styles/scss/variables';

.input__mb_0 {
	margin-bottom: 0;
}

.token {
	justify-content: center;
	display: flex;
	align-items: center;
	text-align: left;
	&__transcription {
		font-weight: 400;
		font-size: 16px;
		color: #9996BE;
	}
}
