@import 'assets/styles/scss/variables';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}
