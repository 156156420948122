body {
	.table-filter .table-filter--mb-16 .table-filter--transactions-page {
	}
}

.provider-transactions {
  .table-hash {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.transactions {
  .created-at, .td-value-date {
    .td-value {
      white-space: pre-line;
    }
  }
}

