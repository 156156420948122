// Color variables
$uiMainColor: #d81159;
$uiSwitchColor: #e1e0e0;
$globalBlack: #1c1c1e;
$globalWhite: #ffffff;
$whiteGrey: #a2adbe;
$blackGrey: #444445;
$globalGrey: #a5b0c4;
$fullBlack: #000;
$black: #0e0e0f;
$globalBGLight: #edf3fe;
$button-hover-no-border: rgba(#4285f5, 0.1);
$globalMainBlue: #4285f5;
$globalMainBlueDark: #2e71d7;
$globalRed: #fd3232;
$globalOrange: #f6b840;
$widgetBlue: #00ede1;
$widgetWhiteBlue: #007aff;
$widgetPink: #e200ac;
$widgetGrey: #bfc9d9;
$globalBlue: #006ce2;
$globalTextSecondary: #9996be;
$scrollColor: #85888e;
$globalStrokeAndDivider: #d3dae8;
$globalGreen: #00ba88;
$globalTextColorMain: #202020;
$uiBorderColor: #c9c9c9;
$layoutBorder: #5d6a85;
$card-label: #a6a3c7;
$card-border: #8a87b080;
$currency-progress: #8a87b04d;
$payment-text: #a5b0c3;
$payment-error: #ff5a35;
$table-link-background: #4285f51a;
// Color hover

$hoverGlobalMainBlue: rgba($globalMainBlue, 0.2);

// Global elements sizes

$globalSidebarWidth: 240px;
$globalShortSidebarWidth: 88px;

$boxShadowColor: #afb7ca47;
$active-project: #079e764d;
$active-project-text: #079e76;

// Color button
$menu-shadow: #a3b6d475;
$button-primary: $globalMainBlue;
$button-primary-dark: $globalMainBlueDark;
$button-primary-light: rgba($globalMainBlueDark, 10%);
$button-primary-medium: rgba($globalMainBlueDark, 20%);
$button-border: #eaeaef;
$button-secondary-border: #d3dae8;
$button-disable: $button-secondary-border;
$button-disable-border: #8a87b0;
$button-clear: $globalRed;
$button-clear-light: rgba($globalRed, 10%);
$button-hover-close: #f2f6ff;
$ul-li-hover: #f4f4f4;

// Color fields
$field-primary: $globalMainBlue;
$field-border-color: $globalStrokeAndDivider;
$field-border-color-active: $globalMainBlue;
$field-border-color-hover: $globalTextSecondary;
$field-error: $globalRed;
$field-success: $globalGreen;
$field-text: $globalBlack;
$field-text-placeholder: $button-disable-border;

// Color pagination
$pagination-border: $globalStrokeAndDivider;
$pagination-bg: $globalWhite;
$pagination-bg-hover: rgba($globalMainBlue, 10%);
$pagination-bg-selected: $globalMainBlue;
$pagination-bg-selected-hover: $globalMainBlueDark;
$pagination-color: $globalBlack;
$pagination-color-light: $globalWhite;

// Color table
$table-border-color: $globalStrokeAndDivider;
$table-text-color: $globalTextSecondary;
$table-color-main: $globalMainBlue;
$table-color-main-hover: $globalMainBlueDark;

// Color tooltip
$tooltip-color: $globalWhite;
$tooltip-bg-color: $globalBlack;
