@import 'ui__var.scss';

@mixin input-placeholder {
	&.placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}

* {
	box-sizing: border-box;
}

button {
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
}

img {
	width: 100%;
}

svg {
	width: 100%;
	height: auto;
}

a {
	text-decoration: none;
}

.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

/*--------------Inputs---------------*/

.input {
	position: relative;
	width: 100%;
	margin-bottom: 20px;

	&__name {
		display: block;
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
	&__notification {
		margin: 8px 0 0 0;
		font-size: 14px;
		color: var(--ui-error-color);
	}
	&--error {
		.input-item,
		.textarea {
			border: 2px solid var(--ui-error-color);
		}
	}
	&--success {
		.input-item {
			border: 2px solid var(--ui-success-color);
		}
	}
	&--numb {
		width: 40px;
		margin: 0 8px;
		.input-item {
			padding: 0;
			border: none;
			border-bottom: 2px solid var(--ui-main-color);
			border-radius: 0;
			font-size: 24px;
			text-align: center;
			&.active {
				border-color: var(--ui-input-border-color);
			}
		}
	}
}

.input-wrapper {
	position: relative;
}

.input-item {
	display: block;
	width: 100%;
	height: 44px;
	padding: 0 15px;
	border-radius: 5px;
	border: 1px solid var(--ui-input-border-color);
	outline: none;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	transition: all 0.15s ease;
	@include input-placeholder {
		color: #b6b6b6;
		transition: all 0.15s ease;
		font-size: 14px;
	}
	&--left-icon {
		padding-left: 45px;
	}
	&--right-double-icon {
		padding-right: 60px;
	}
	&--right-icon-and-action {
		padding-right: 90px;
	}
	&--right-icon {
		padding-right: 45px;
	}
	&--bigger {
		height: 52px;
		font-size: 16px;
	}
}

.input-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	left: 15px;
	width: 16px;
	svg {
		width: 100%;
		height: auto;
	}
	.stroke path {
		stroke: #b6b6b6;
	}
	&--right {
		left: unset;
		right: 15px;
	}
	&--more-right {
		left: unset;
		right: 45px;
	}
}

.input-action {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	left: 15px;
	font-weight: 700;
	color: var(--ui-main-color);
	&--more-right {
		left: unset;
		right: 45px;
	}
	&--right {
		left: unset;
		right: 15px;
	}
}

.password-type {
	display: none;
}

input[type='password'] ~ .show-pass {
	.password-type {
		display: block;
	}
	.text-type {
		display: none;
	}
}

.show-pass {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
	display: flex;
	width: 16px;
	svg {
		width: 100%;
		height: auto;
	}
	.fill path {
		fill: #b6b6b6;
	}
	.stroke path {
		stroke: #b6b6b6;
	}
}

.textarea {
	padding: 10px 15px;
	border-radius: 5px;
	font: inherit;
	font-size: 16px;
	font-weight: 400;
	border: 1px solid var(--ui-input-border-color);
	textarea {
		display: block;
		width: 100%;
		min-height: 120px;
		background: none;
		border: none;
		outline: none !important;
		font-size: 14px;
		@include input-placeholder {
			color: #b6b6b6;
			transition: all 0.15s ease;
		}
		resize: vertical;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 3px;
			background: #b6b6b6;
			cursor: pointer;
		}
	}
}

.select-block {
	&__name {
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
}

.select {
	position: relative;
	display: flex;
	align-content: center;
	height: 44px;
	border-radius: 5px;
	border: 1px solid var(--ui-input-border-color);
	background: #fff;
	z-index: 3;
	&__current {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 0 15px;
		font-size: 14px;
	}
	&__current-arrow {
		display: flex;
		flex: 0 0 8px;
		max-width: 8px;
		margin-left: 10px;
		.stroke path {
			stroke: #424242;
		}
	}
	&__current-text {
		margin-right: auto;
	}
	&__img {
		display: flex;
		width: 16px;
		align-items: center;
		margin-right: 12px;
	}
	&__drop {
		display: none;
		position: absolute;
		left: 0;
		top: calc(100% + 10px);
		width: 100%;
		padding: 5px;
		background: #ffffff;
		border: 1px solid var(--ui-select-border);
		border-radius: 4px;
		z-index: 1;

		ul {
			> li {
				> a,
				> button {
					display: block;
					width: 100%;
					padding: 10px;
					border-radius: 4px;
					color: #000;
					font-size: 14px;
					transition: all 0.15s ease;
					text-align: left;
					&:hover {
						background: #f4f4f4;
					}
				}
			}
		}
	}
	&__drop-scroll {
		max-height: 180px;
		overflow: auto;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: #b6b6b6;
		}
	}
	&.active {
		z-index: 5;
		.select__drop {
			display: block;
		}
	}
	&--small {
		padding: 0;
		border: 0;
		width: 62px;
		z-index: auto;
		.select__drop {
			padding: 0;
			min-width: 100px;
		}
	}
	&--grey {
		background: #dadada;
		padding: 0 15px;
		width: 74px;
	}
	&--light {
		.select__current {
			color: #b6b6b6;
			font-weight: 300;
			font-size: 16px;
			line-height: 18px;
		}
	}
	&--big {
		height: 52px;
		font-size: 16px;
	}
}

/*--------------End_Inputs-----------*/

/*--------------Buttons-------------*/

button {
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: auto;
	min-width: 110px;
	height: 44px;
	gap: 8px;
	padding: 0 15px;
	background: var(--ui-button-bg);
	border: none;
	border-radius: 5px;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
	transition: all 0.15s ease;
	&:hover {
		background: var(--ui-button-bg-hover);
	}
	&--second-type {
		background: transparent;
		border: 1px solid var(--ui-button-bg);
		color: #000;
		&:hover {
			background: var(--ui-button-bg);
			color: #fff;
		}
	}
	&--rounded {
		border-radius: 22px;
	}
	&--less-height {
		height: 36px;
	}
	&--bigger-height {
		height: 60px;
		font-size: 18px;
	}
	&--full-width {
		width: 100%;
	}
	&--small {
		width: 120px;
	}
	&--regular {
		width: 160px;
	}
	&--wide {
		width: 180px;
	}
	&--wider {
		width: 200px;
	}
	&--big {
		width: 240px;
	}

	&:disabled {
		opacity: 0.5;
		background: #b6b6b6;
		cursor: default;
	}

	&__arrow {
		display: flex;
		align-items: center;
		width: 6px;
	}
	&__icon {
		display: flex;
		width: 12px;
		.fill path {
			fill: #fff;
		}
	}
}

/*--------------End_Buttons---------*/

/*-----Checkbox and Radiobutton-----*/

.checkbox {
	margin: 0 0 10px 0;
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 3px;
		cursor: pointer;
	}
	&__item-icon {
		display: flex;
		width: 10px;
		opacity: 0;
		.fill path {
			fill: #fff;
		}
	}
	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
			text-decoration: underline !important;
		}
	}
	input:checked ~ .checkbox__item {
		background: var(--ui-checkbox-bg);
		border-color: var(--ui-checkbox-bg);
		.checkbox__item-icon {
			opacity: 1;
		}
	}
	input:disabled ~ .checkbox__item {
		cursor: default;
		background: #b6b6b6;
		& + .checkbox__text {
			cursor: default;
		}
	}
	&--no-mb {
		margin-bottom: 0;
	}
}

.radio {
	margin: 0 0 10px 0;
	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 16px;
		width: 16px;
		height: 16px;
		border: 1px solid var(--ui-checkbox-border);
		border-radius: 50%;
		cursor: pointer;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: #fff;
			z-index: 2;
			opacity: 0;
		}
	}

	&__label {
		display: flex;
		align-items: center;
	}
	&__text {
		margin-left: 10px;
		font-size: 14px;
		cursor: pointer;
		a {
			color: blue;
		}
	}
	input:checked ~ .radio__item {
		background: var(--ui-checkbox-bg);
		border-color: var(--ui-checkbox-bg);
		&:after {
			opacity: 1;
		}
	}
	input:disabled ~ .radio__item {
		cursor: default;
		background: #b6b6b6;
		& + .radio__text {
			cursor: default;
		}
	}
}

.switch {
	display: flex;
	align-items: center;
	&__toggler {
		position: relative;
		display: block;
		width: 50px;
		height: 26px;
		border: 1px solid var(--ui-swith-color);
		border-radius: 13px;
		cursor: pointer;
		&:before {
			content: '';
			position: absolute;
			left: 2px;
			top: 50%;
			transform: translateY(-50%);
			width: 18px;
			height: 18px;
			background: var(--ui-swith-color);
			border-radius: 100%;
			transition: 0.3s;
		}
	}
	input:checked ~ .switch__toggler {
		//background: #e8e8e8;
		&:before {
			left: 26px;
			background: var(--ui-main-color);
		}
	}
	&__label {
		& + .switch__text {
			margin-left: 10px;
		}
	}
	&__text {
		font-size: 14px;
		font-weight: 500;
		& + .switch__label {
			margin-left: 10px;
		}
	}
	&--type2 {
		.switch__toggler {
			background: var(--ui-swith-color);
			&:before {
				background: #fff;
			}
		}
		input:checked ~ .switch__toggler {
			background: var(--ui-main-color);
			&:before {
				background: #fff;
			}
		}
	}
}

/*---End_Checkbox and Radiobutton---*/

/*-------------Popup----------------*/

.popup-open {
	position: relative;
	overflow: hidden;
}

.popup-window {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 201;
	background: rgba(0, 0, 0, 0.5);
	&__inside {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100%;
		padding: 20px;
	}
}

.popup {
	position: relative;
	width: 500px;
	max-width: 100%;
	border-radius: 10px;
	background: var(--ui-popup-bg);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	padding: 30px;
	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 16px;
		svg {
			width: 100%;
			height: auto;
		}
		.fill path {
			fill: #000;
		}
	}
}

.popup-header {
	&__title {
		text-align: center;
		font-size: 24px;
		font-weight: 700;
	}
}

.popup-body {
	margin-top: 20px;
}

.popup-text {
	margin: 0 0 15px 0;
	line-height: 2;
	font-size: 15px;
	&--center {
		text-align: center;
	}
}

.popup-submit {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	&--less-mt {
		margin-top: 15px;
	}
	&--more-mt {
		margin-top: 45px;
	}
	&__col {
		width: calc(50% - 10px);
	}
	&--sb {
		justify-content: space-between;
	}
}

@media screen and (max-width: 767px) {
	.popup {
		padding: 30px 15px 20px 15px;
	}

	.popup-header__title {
		font-size: 21px;
	}
}
/*-------------End_Popup------------*/

/*-------------Form, Authorization----------------*/

.authorization-section {
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding: 30px 0;
	&__container {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
	}
}

.authorization {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	&__title {
		text-align: center;
		font-weight: 700;
		font-size: 24px;
	}
	&__field {
		& + .authorization__field {
			margin-top: 20px;
		}
		&--numb {
			display: flex;
			justify-content: center;
			margin-bottom: 40px;
		}
	}
	&__details {
		margin-top: 16px;
		font-size: 14px;
		text-align: center;
		a,
		button {
			color: var(--ui-main-color);
			font-weight: 700;
		}
	}
	&__form {
		margin-top: 34px;
	}
	&__options {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 10px;
	}
	&__options-full {
		width: 100%;
	}
	&__forgot-link {
		color: var(--ui-color-grey);
		font-size: 14px;
	}
	&__photo {
		margin: 25px 0;
		img {
			max-width: 100%;
		}
	}
}

.form-submit {
	margin-top: 34px;
}
/*-------------END_Form, Authorization----------------*/

/*-------------Notification----------------*/

.notification-wrapper {
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: -100;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;
	&.active {
		visibility: visible;
		opacity: 1;
		z-index: 10000;
	}
}

.notification {
	position: relative;
	width: 410px;
	max-width: 100%;
	padding: 15px 20px;
	text-align: left;
	box-sizing: border-box;
	border-radius: 18px;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 25px rgba(0, 0, 0, 0.15);
	background: #ffffff;
	transform: translateX(150%);
	transition: 0.5s;
	&.active {
		transform: translateX(0);
	}
	& + & {
		margin-top: 15px;
	}
	&--error,
	&--successful {
		.notification__title {
			color: #fff;
		}
		.notification__text {
			color: #fff;
		}
		.notification__close {
			svg {
				path {
					fill: #fff;
				}
			}
		}
	}
	&--error {
		background: #eb5757;
	}
	&--successful {
		background: #4bc0b1;
	}
	&--icon-error {
		display: flex;
		align-items: stretch;
		padding: 0;
		.notification__icon {
			background: #eb5757;
		}
		.notification__info {
			padding: 15px;
		}
	}
	&--icon-successful {
		display: flex;
		align-items: stretch;
		padding: 0;
		.notification__icon {
			background: #4bc0b1;
		}
		.notification__info {
			padding: 15px;
		}
	}
	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	&__title {
		font-weight: 600;
		font-size: 16px;
		color: #4c4c4c;
	}
	&__text {
		margin-top: 5px;
		font-size: 14px;
		line-height: 150%;
		color: #4c4c4c;
	}
	&__icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		width: 62px;
		min-width: 62px;
		border-radius: 18px 0 0 18px;
		svg {
			width: 30px;
			margin: 0 auto;
		}
	}
}

/*-------------END_Notification----------------*/

/*------------Pagination---------------------*/

.pagination-block {
	display: flex;
	justify-content: center;
	margin-top: 60px;
	&--end {
		justify-content: flex-end;
	}
	&--start {
		justify-content: flex-start;
	}
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
	> li {
		padding: 0 5px;
		> a,
		> button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			min-width: 40px;
			margin-top: 10px;
			padding: 0 2px;
			border: 1px solid var(--ui-pagination-border);
			border-radius: 3px;
			background: var(--ui-pagination-bg);
			color: var(--ui-pagination-color);
			transition: all 0.15s ease-in;
		}
		&.arrow {
			svg {
				width: 10px;
				height: auto;
			}
			.stroke path {
				stroke: var(--ui-pagination-color);
				transition: all 0.15s ease-in;
			}
			&.disabled {
				> a,
				> button {
					pointer-events: none;
				}
				svg {
					opacity: 0.3;
				}
			}
		}
		&:not(.disabled) {
			&:hover,
			&.active {
				> a,
				> button {
					background: var(--ui-main-color);
					color: #fff;
					border-color: var(--ui-main-color);
				}
				.stroke path {
					stroke: #fff;
				}
			}
		}
	}
}
/*------------END_Pagination----------------*/

/*------------------Captcha----------------*/
.captcha-modal {
	position: relative;
	width: 360px;
	background-color: var(--ui-popup-bg);
	box-shadow: 0px 0px 19.32px 1.68px rgba(0, 0, 0, 0.06);
	border-radius: 4px;
	color: var(--ui-text-color);
	padding: 15px;
}

.captcha-cover {
	position: relative;
	height: 220px;
	padding: 0 15px;
	&__item {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.captcha-text {
	position: absolute;
	top: 15px;
	left: 0;
	padding: 0 15px;
	z-index: 2;
	color: #fff;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.4;
	span {
		color: var(--ui-main-color);
	}
}

.captcha-move {
	position: absolute;
	bottom: 20px;
	left: 0%;
	margin-left: 10px;
	width: 60px;
	z-index: 2;
}

.capthca-default {
	position: absolute;
	bottom: 20px;
	left: 60%;
	width: 60px;
	margin-left: 10px;
}

.capthca-slide-block {
	padding: 20px;
}

.capthca-slide {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding-left: 40px;
	border-radius: 25px;
	background: #eeefee;
	border: 1px solid var(--ui-border-color);
	text-align: center;
	font-size: 15px;
	color: rgba(150, 150, 150, 0.5);
}

.slide-btn {
	position: absolute;
	margin-left: -10px;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: var(--ui-main-color);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 2;
	svg {
		width: 24px;
		height: auto;
		pointer-events: none;
	}
	.stroke path {
		stroke: #fff;
	}

	&.success {
		.stroke path {
			stroke: #fff;
		}
	}
	&.error {
		.stroke path {
			stroke: #ed1c24;
		}
	}
}

.capthca-actions {
	display: flex;
	padding: 15px 15px 0 15px;
	width: calc(100% + 30px);
	margin-left: -15px;
	border-top: 1px solid var(--ui-border-color);
	&__item {
		display: flex;
		align-items: center;
		& + .capthca-actions__item {
			margin-left: 15px;
		}
		svg {
			width: 20px;
			height: auto;
		}
		svg path {
			stroke: #121214;
		}
	}
}
/*------------------End_Captcha------------*/

/*------------------Avatars------------*/

.user-avatar {
	display: flex;
	align-items: center;
	&--xl-size {
		.user-avatar__info-name {
			font-size: 16px;
		}
		.user-avatar__info-text {
			font-size: 12px;
		}
	}
	&--m-size {
		.user-avatar__info-name {
			font-size: 14px;
		}
		.user-avatar__info-text {
			font-size: 11px;
		}
	}
	&--s-size {
		.user-avatar__info-name {
			font-size: 12px;
		}
		.user-avatar__info-text {
			font-size: 9px;
		}
	}
	&--xs-size {
	}
	&__info {
		padding-left: 10px;
	}
	&__info-name {
		color: var(--ui-text-color);
		font-weight: 700;
		line-height: 1.4;
	}
	&__info-text {
		color: var(--ui-text-color-light);
	}
}

.avatar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
	&--xl-size {
		width: 48px;
		height: 48px;
	}
	&--m-size {
		width: 40px;
		height: 40px;
		.avatar__text {
			font-size: 16px;
		}
	}
	&--s-size {
		width: 32px;
		height: 32px;
		.avatar__text {
			font-size: 14px;
		}
	}
	&--xs-size {
		width: 24px;
		height: 24px;
		.avatar__text {
			font-size: 12px;
		}
	}
	&--xxs-size {
		width: 16px;
		height: 16px;
		.avatar__text {
			font-size: 8px;
		}
	}
	&__text {
		color: #fff;
		font-weight: 700;
		text-align: center;
	}
	&--no-photo {
		background: var(--ui-main-color);
	}
	&__text {
		font-size: 20px;
	}
}

.avatars-group {
	display: flex;
	.avatar {
		border: 2px solid #fff;
		z-index: 10;
		&:nth-child(2) {
			z-index: 9;
		}
		&:nth-child(3) {
			z-index: 8;
		}
		&:nth-child(3) {
			z-index: 7;
		}
		&:nth-child(4) {
			z-index: 6;
		}
		&:nth-child(5) {
			z-index: 5;
		}
	}
	&--xl-size {
		.avatar + .avatar {
			margin-left: -20px;
		}
	}
	&--m-size {
		.avatar + .avatar {
			margin-left: -15px;
		}
	}
	&--s-size {
		.avatar + .avatar {
			margin-left: -10px;
		}
	}
}

/*------------------End_Avatars------------*/

/*----------------Blog_and_article------------*/

.blog-section {
	padding: 45px 0;
}

.blog-container {
	margin: 0 auto;
}

.blog {
	padding-top: 20px;
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -30px;
	}
	&__col {
		width: 33.3%;
		padding: 0 30px;
	}
}

.blog-card {
	margin-top: 30px;
	&__img {
		height: 201px;
		img {
			width: 100%;
			height: 100%;
			border-radius: 18px;
			object-fit: cover;
		}
	}
	&__title {
		display: block;
		margin-top: 20px;
		font-size: 19px;
		color: var(--ui-text-color);
		font-weight: 700;
		transition: all 0.15s ease-in;
		&:hover {
			color: var(--ui-main-color);
		}
	}
}

@media screen and (max-width: 991px) {
	.blog {
		&__row {
			margin: 0 -15px;
		}
		&__col {
			padding: 0 15px;
		}
	}

	.blog-card {
		margin-top: 20px;
		&__title {
			font-size: 17px;
		}
	}
}

@media screen and (max-width: 767px) {
	.blog-section {
		padding: 30px 0;
	}

	.blog {
		padding-top: 10px;
		&__col {
			width: 50%;
		}
	}
}

@media screen and (max-width: 475px) {
	.blog {
		&__col {
			width: 100%;
		}
	}
}

/*----------------End_Blog_and_article------------*/

/*----------------Text and title------------*/

.section-title {
	font-size: 42px;
	font-weight: 700;
	line-height: 1.4;
	&--centered {
		text-align: center;
	}
	&--right {
		text-align: right;
	}
}

.block-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.4;
	&--centered {
		text-align: center;
	}
	&--right {
		text-align: right;
	}
}

.h1,
.h2,
.h3,
.h4,
.h5 {
	font-weight: 700;
	line-height: 1.4;
}

.h1 {
	font-size: 42px;
}
.h2 {
	font-size: 36px;
}
.h3 {
	font-size: 30px;
}
.h4 {
	font-size: 24px;
}
.h5 {
	font-size: 18px;
}

.text-block {
	margin-top: 30px;
	line-height: 2;
	font-size: 15px;
	&--mt15 {
		margin-top: 15px;
	}
	&--no-mt {
		margin-top: 0;
	}
	p {
		& + p {
			margin-top: 15px;
		}
		a {
			color: var(--ui-main-color);
			text-decoration: underline !important;
			font-weight: 700;
		}
	}

	&--centered {
		text-align: center;
	}
	&--right {
		text-align: right;
	}
}

.bold {
	font-weight: 700;
}

/*----------------End_Text and title------------*/

/*----------------Loader-----------------------*/

.loader-modal {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.65);
	z-index: 210;
}

.loader-circle {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 100px;
		height: 100px;
		overflow: visible;
		transform: rotate(-90deg);
	}
	&__item {
		width: 100px;
		height: 100px;
		stroke-dasharray: 314;
		&--type1 {
			fill: none;
			stroke-width: 6px;
			stroke: rgba(255, 255, 255, 0.2);
		}
		&--type2 {
			fill: none;
			stroke-width: 8px;
			stroke: var(--ui-main-color);
			stroke-linecap: round;
			transform-origin: center center;
			animation: loader-stroke 3s infinite linear,
				loader-rotation 2s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
		}
	}
	&--md {
		svg {
			width: 70px;
			height: 70px;
		}
		.loader-circle__item {
			width: 70px;
			height: 70px;
			stroke-dasharray: 220px;
			&--type1 {
				stroke-width: 5px;
			}
			&--type2 {
				stroke-width: 6px;
				animation: loader-stroke-md 2.6s infinite linear,
					loader-rotation 1.8s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
			}
		}
	}
	&--sm {
		svg {
			width: 40px;
			height: 40px;
		}
		.loader-circle__item {
			width: 40px;
			height: 40px;
			stroke-dasharray: 126px;
			&--type1 {
				stroke-width: 3px;
			}
			&--type2 {
				stroke-width: 4px;
				animation: loader-stroke-sm 2.4s infinite linear,
					loader-rotation 1.6s infinite cubic-bezier(0.45, 0.85, 0.72, 0.71);
			}
		}
	}
}

@keyframes loader-rotation {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loader-stroke {
	0% {
		stroke-dashoffset: 314;
	}
	100% {
		stroke-dashoffset: -314;
	}
}

@keyframes loader-stroke-md {
	0% {
		stroke-dashoffset: 220;
	}
	100% {
		stroke-dashoffset: -220;
	}
}

@keyframes loader-stroke-sm {
	0% {
		stroke-dashoffset: 126;
	}
	100% {
		stroke-dashoffset: -126;
	}
}
/*----------------End_Loader-------------------*/

/*----------------File_upload-------------------*/

.upload-file-box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 220px;
	width: 370px;
	overflow: hidden;
	border: 1px dashed var(--ui-input-border-color);
	border-radius: 10px;
	transition: all 0.15s ease-in;
	cursor: pointer;
	&:hover {
		border-color: var(--ui-upload-box-color);
		.upload-file__label {
			color: var(--ui-upload-box-color);
		}
	}
	&:focus {
		border-style: solid;
	}
	&--error {
		border-color: var(--ui-error-color) !important;
		.upload-file__label {
			color: var(--ui-text-color) !important;
		}
	}
}

.error-text {
	margin-top: 4px;
	font-size: 14px;
	color: var(--ui-error-color);
}

.file-input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-indent: -999px;
	z-index: 5;
}

.upload-file {
	width: 100%;
	padding: 20px 40px;
	text-align: center;
	&__icon {
		display: flex;
		align-items: center;
		width: 30px;
		margin: 0 auto;
		svg {
			width: 100%;
			height: auto;
		}
	}
	&__title {
		display: block;
		margin-top: 15px;
		font-size: 18px;
		font-weight: 500;
		color: var(--ui-text-color);
	}
	&__text {
		display: block;
		margin-top: 15px;
		font-size: 14px;
		color: var(--ui-text-color-light);
	}
	&__label {
		display: inline-block;
		margin-top: 16px;
		padding: 6px 20px;
		border: 1px solid var(--ui-input-border-color);
		border-radius: 4px;
		box-shadow: 0px 1px 2px rgba(6, 25, 56, 0.05);
		font-size: 14px;
		font-weight: 700;
		transition: all 0.15s ease-in;
	}
	.progress-bar {
		margin-top: 16px;
	}
}

.upload-photo-wrap {
	position: relative;
}

.upload-photo-box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 170px;
	width: 260px;
	overflow: hidden;
	border: 1px dashed var(--ui-input-border-color);
	border-radius: 4px;
	transition: all 0.15 ease-in;
	cursor: pointer;
	.loader-circle__item--type1 {
		stroke: #e0e0e0;
	}
	&--preview {
		border-color: transparent;
	}
	&--error {
		background: #fff4f4;
		border-color: transparent;
		.upload-photo__info {
			color: var(--ui-error-color);
		}
	}
}

.upload-photo {
	text-align: center;
	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 55px;
		svg {
			width: 100%;
			height: auto;
		}
		&--error {
			height: 55px;
			border: 2px solid #eed5d5;
			border-radius: 50%;
			svg {
				width: 30px;
			}
		}
	}
	&__info {
		display: block;
		margin-top: 16px;
		font-size: 13px;
		color: #9ea2a9;
	}
	&__info-text {
		display: block;
		margin-top: 4px;
		font-weight: 500;
	}
}

.upload-photo-preview {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.upload-photo-hint {
	margin-top: 4px;
	font-size: 12px;
	color: var(--ui-text-color-grey);
}

.upload-photo-action {
	position: absolute;
	display: flex;
	align-items: center;
	right: 10px;
	bottom: 10px;
	z-index: 6;
	&__btn {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 3px;
		cursor: pointer;
		& + .upload-photo-action__btn {
			margin-left: 10px;
		}
		&--edit {
			background: var(--ui-main-color);
			svg {
				width: 15px;
			}
			.fill path {
				fill: #fff;
			}
		}
		&--remove {
			background: #fff;
			svg {
				width: 11px;
			}
			.stroke path {
				stroke: var(--ui-text-color);
			}
		}
		svg {
			height: auto;
		}
	}
}

.file-attachment {
	display: inline-flex;
	margin: 15px 0;
	label {
		cursor: pointer;
	}
	&__title {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: var(--ui-main-color);
		svg {
			width: 15px;
			margin-left: 5px;
		}
		.stroke path {
			stroke: var(--ui-main-color);
		}
	}
	&__text {
		margin-top: 6px;
		font-size: 14px;
		color: var(--ui-text-color-light);
	}
}

/*----------------End_File_upload---------------*/

/*----------------Progress-bar-----------------*/
.progress-bar {
	position: relative;
	display: block;
	&__line {
		display: block;
		position: relative;
		height: 5px;
		background: var(--ui-progress-bg);
		border-radius: 2px;
	}
	&__line-active {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		height: 100%;
		background: var(--ui-progress-line);
		border-radius: 2px;
		transition: all 0.15s linear;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}
}

.progress-bar-label {
	position: absolute;
	bottom: calc(100% + 10px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
	margin-left: -30px;
	height: 30px;
	width: 60px;
	border-radius: 8px;
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	background: var(--ui-progress-label-bg);
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 100%;
		border: 7px solid transparent;
		border-top: 6px solid var(--ui-progress-label-bg);
	}
}

/*----------------End_Progress-bar-------------*/

/*----------------FAQ--------------------------*/

.faq-box {
	margin-top: 50px;
	padding: 50px 15px;
	background: #f2f2f2;
	border-radius: 18px;
}

.faq-list {
	max-width: 650px;
	margin: 0 auto;
}

.faq {
	padding: 15px;
	background: #fff;
	border: 1px solid var(--ui-border-light-color);
	border-radius: 10px;
	&__header {
		display: flex;
		align-items: center;
	}
	&__header-title {
		font-weight: 600;
		flex: 1 1 0;
	}
	&__header-btn {
		display: flex;
		flex: 0 0 16px;
		max-width: 16px;
	}
	&__text {
		display: none;
		border-top: 1px solid transparent;
		font-size: 14px;
	}
	&.active {
		.faq__header-btn {
			transform: rotate(-180deg);
		}
		.faq__text {
			display: block;
			margin-top: 10px;
			padding-top: 10px;
			border-color: var(--ui-border-light-color);
		}
	}
	& + .faq {
		margin-top: 15px;
	}
}

@media screen and (max-width: 767px) {
	.faq-box {
		margin-top: 30px;
		padding: 30px 15px;
	}
}
/*----------------END_FAQ----------------------*/

/*----------------Tooltip----------------------*/

.tooltip-wrap {
	&--right-align {
		text-align: right;
	}
	&--center-align {
		text-align: center;
	}
}

.tooltip-item {
	position: relative;
	display: inline-flex;
	&__title {
		font-weight: 600;
	}
	&:hover {
		.tooltip {
			display: block;
		}
	}
}

.tooltip {
	position: absolute;
	display: none;
	width: 310px;
	background: var(--ui--tooltip-bg);
	border-radius: 8px;
	padding: 8px 12px;
	z-index: 10;
	&__text {
		display: block;
		text-align: left;
		color: #fff;
		font-size: 12px;
	}
	&:after {
		content: '';
		position: absolute;
		border: 6px solid transparent;
		border-bottom: 6px solid var(--ui--tooltip-bg);
	}
	&--bottom {
		top: calc(100% + 10px);
	}
	&--top {
		bottom: calc(100% + 10px);
	}
	&--center {
		left: 50%;
		transform: translateX(-50%);
	}
	&--left {
		left: 0;
	}
	&--right {
		right: 0;
	}
	&--arrow-top-left {
		&:after {
			top: -12px;
			left: 20px;
		}
	}
	&--arrow-top-right {
		&:after {
			top: -12px;
			right: 20px;
		}
	}
	&--arrow-top-center {
		&:after {
			top: -12px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&--arrow-bottom-left {
		&:after {
			bottom: -12px;
			left: 20px;
			transform: rotate(-180deg);
		}
	}
	&--arrow-bottom-right {
		&:after {
			bottom: -12px;
			right: 20px;
			transform: rotate(-180deg);
		}
	}
	&--arrow-bottom-center {
		&:after {
			bottom: -12px;
			left: 50%;
			transform: translateX(-50%) rotate(-180deg);
		}
	}
}

@media screen and (max-width: 475px) {
	.tooltip {
		max-width: 280px;
		font-size: 11px;
	}
}

/*--------------Index---------------*/

.table {
	font-size: 14px;
	border: 1px solid var(--ui-border-color);
	border-radius: 10px;
	&--user-management {
		.tr {
			grid-template-columns: 100px 1fr 1.4fr 1fr 1fr 120px 80px;
		}
	}
}

.tr {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr;
}

.td {
	padding: 12px 10px;
	&:first-child {
		padding-left: 25px;
	}
	&:last-child {
		padding-right: 25px;
	}
	&--right {
		text-align: right;
	}
	&--padding-left {
		padding-left: 25px;
	}
}

.table-header {
	.tr {
		border-bottom: 1px solid var(--ui-border-color);
	}
}

.table-body {
	.tr {
		border-bottom: 1px solid var(--ui-border-color);
		&:last-child,
		&:only-child {
			border: none;
		}
	}
}

.td-name {
	font-weight: 500;
	font-size: 12px;
}

.td-title {
	display: block;
	font-size: 12px;
	line-height: 18px;
	color: rgba(37, 38, 45, 0.5);
}

.td-hidden-name {
	display: none;
	font-weight: 500;
}

.table-buttons {
	display: flex;
	button {
		flex: 0 0 15px;
		max-width: 15px;
		.stroke path {
			stroke: var(--ui-border-color);
		}
		&.active {
			.stroke path {
				stroke: var(--ui-main-color);
			}
		}
	}
	button + button {
		margin-left: 10px;
	}
	&--right {
		justify-content: flex-end;
	}
}

@import 'ui-modifiers.scss';
/*----------------END_Tooltip------------------*/
