@import 'assets/styles/scss/variables';

.dropdown {
	position: absolute;
	top: 25px;
	display: none;
	width: 218px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	background-color: #fff;
	right: 0;
	&_active {
		display: block;
	}
}

.projectMenu {
	position: absolute;
	margin-left: auto;
	right: 0;
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
}
