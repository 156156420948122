@import 'assets/styles/scss/variables';

.popup {
	width: 540px;
	position: relative;
	max-width: 100%;
	border-radius: 10px;
	background: $globalWhite;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	padding: 40px 32px;
	color: $globalTextColorMain;
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 24px;
		min-height: 24px;
		padding: 16px;
	}
	&__body {
		margin-top: 24px;
	}
	&__text {
		font-weight: 400;
		text-align: center;
		color: $globalTextSecondary;
		line-height: 1.6;
		font-size: 14px;
		margin: 0 0 15px 0;
	}
	&__title {
		text-align: left;
		font-size: 20px;
		font-weight: 700;
	}
}

.copyAddress {
	padding: 24px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	word-break: break-word;
	background: #f2f6ff;
	border-radius: 12px;
	& button {
		position: relative;
		width: 18px;
		min-width: 18px;
	}
}

.qrCode {
	margin: 32px auto;
	text-align: center;
	& img {
		width: auto;
		max-width: 100%;
	}
}
