@import 'assets/styles/scss/variables';

.td {
	height: 70px;
	// отключил, так как на некоторых таблицах создает лишний скролл
	// не удаляю на случай, если поедет вертка других таблиц ( на момент исправления все ок )
	//padding: 16px 12px 16px 0;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $globalStrokeAndDivider;
	font-size: 14px;
	a {
		color: $globalMainBlue;
		&:hover,
		&:focus {
			color: $globalMainBlueDark;
		}
	}
	&Last {
		border: none;
		padding-bottom: 0;
		height: 61px;
	}
	&Right {
		text-align: right;
		justify-content: flex-end;
		padding-right: 0;
	}
	&Header {
		height: 58px;
		display: flex;
		align-items: center;
		grid-gap: 4px;
		gap: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: $globalTextSecondary;
		padding-top: 0;
		padding-bottom: 12px;
	}
}
