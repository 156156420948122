@import 'assets/styles/scss/variables';

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	margin-bottom: 16px;
	cursor: pointer;
}

.table {
	margin-top: 0;
}
