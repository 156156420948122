@import 'assets/styles/scss/variables';

.pagination-block {
	display: flex;
	justify-content: center;
	padding-top: 23px;
	border-top: 1px solid $button-secondary-border;
	width: 100%;
	margin-top: 16px;
	&--end {
		justify-content: flex-end;
	}
	&--start {
		justify-content: flex-start;
	}
}

.pagination {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
	> li {
		padding: 0;
		&:not(:last-of-type) {
			margin-right: 8px;
		}
		> a,
		> button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 39px;
			min-width: 39px;
			padding: 8px;
			border: 1px solid $pagination-border;
			border-radius: 8px;
			background: $pagination-bg;
			color: $pagination-color;
			transition: all 0.15s ease-in;
			cursor: pointer;
			font-size: 14px;
			font-weight: 500;
			line-height: 23.1px;
		}
		&.arrow {
			svg {
				width: 10px;
				height: auto;
			}
			.stroke path {
				stroke: $pagination-color;
				transition: all 0.15s ease-in;
			}
			&.disabled {
				> a,
				> button {
					pointer-events: none;
				}
				svg {
					opacity: 0.3;
				}
			}
		}
		&:not(:disabled) {
			&:hover:not(.active) {
				> a,
				> button {
					background: $pagination-bg-hover;
				}
			}
			&.active {
				> a,
				> button {
					background: $pagination-bg-selected;
					color: $pagination-color-light;
					border-color: $pagination-bg-selected;
				}
				svg path {
					stroke: $pagination-color-light;
				}

				&:hover {
					> a,
					> button {
						background: $pagination-bg-selected-hover;
						color: $pagination-color-light;
						border-color: $pagination-bg-selected-hover;
					}
				}
			}
		}
	}
}
