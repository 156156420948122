@import 'assets/styles/scss/variables';

.approvements {
	&__items {
		margin-top: 24px;
		display: grid;
		grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
		grid-gap: 16px 40px;
		gap: 16px 40px;
	}
	&__list {
		margin-top: 36px;
		display: grid;
		grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
		grid-gap: 40px;
		gap: 40px;
	}
	&__item {
		display: flex;
		justify-content: flex-start;
		font-size: 16px;
		grid-gap: 16px;
		gap: 16px;
		&:not(:last-of-type) {
			border-bottom: 1px solid $button-border;
			padding-bottom: 16px;
		}
	}
	&__user {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 24px;
		grid-gap: 16px 40px;
		gap: 40px;
	}
}
