@import 'assets/styles/scss/variables';

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;
	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 8px;
		border-radius: 6px;
		box-shadow: 0 0 10px rgba(175, 183, 202, 0.28);
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}
