.link-create {
	color: var(--text-color2);
}

// 📌 MerchantManagement

.table-plug--with-header {
	margin-top: 0;
	border-top: none;
}
