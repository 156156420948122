.project-callback-info {
	.approvements-list {
		grid-template-columns: minmax(100px, 2fr) minmax(100px, 1fr);
		.request-info {
			flex: 1;
			p {
				white-space: pre-wrap;
			}
		}
	}
	.approvements-item {
		align-items: center;
	}
}
