@import 'assets/styles/scss/variables';

.popup {
	width: 480px;
	position: relative;
	max-width: 100%;
	border-radius: 10px;
	background: $globalWhite;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	padding: 40px 32px;
	color: $globalTextColorMain;
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 24px;
		min-height: 24px;
		padding: 16px;
	}
	&__body {
		margin-top: 32px;
	}
	&__text {
		font-weight: 700;
		text-align: left;
		color: $globalTextSecondary;
		font-size: 20px;
	}
	&__title {
		text-align: left;
		font-size: 20px;
		font-weight: 700;
	}
	&__button {
		margin-top: 26px;
		display: flex;
		grid-gap: 8px;
		gap: 8px;
		flex-direction: row;
	}
}

.select {
	position: relative;
	display: flex;
	align-content: center;
	background: #fff;
	z-index: 3;
	height: 50px;
	border: 1px solid $globalStrokeAndDivider;
	border-radius: 12px;
	&Block {
		margin-bottom: 24px;
		&__name {
			margin-bottom: 8px;
			font-size: 14px;
			font-weight: 500;
			min-height: 21px;
		}
	}
	&__current {
		color: $globalTextSecondary;
		grid-gap: 10px;
		gap: 10px;
		padding: 0 16px;
		font-weight: 400;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		&Arrow {
			flex: 0 0 12px;
			max-width: 12px;
			justify-content: flex-end;
			margin-left: auto;
		}
	}
	&Info {
		margin-top: 8px;
		display: flex;
		gap: 8px;
		&__name {
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
			color: $globalTextSecondary;
		}
		&__number {
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 600;
			font-size: 16px;
			color: $globalTextColorMain;
		}
	}
}

.link, .td__value a {
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: $globalMainBlue;
	transition: 0.2s linear;
}

.input {
	position: relative;
	width: 100%;
	margin-bottom: 24px;
	&__text {
		position: absolute;
		top: 50%;
		right: 16px;
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 16px;
		transform: translateY(-50%);
		color: $globalTextSecondary;
	}
	&__item {
		display: block;
		width: 100%;
		height: 50px;
		padding: 0 16px;
		border: 1px solid $globalStrokeAndDivider;
		border-radius: 12px;
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: $globalTextColorMain;
		outline: none;
		transition: all 0.15s ease;
	}
	&__name {
		margin-bottom: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
	&__wrapper {
		position: relative;
	}
	&Info {
		margin-top: 8px;
		display: flex;
		gap: 8px;
		&__name {
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
			color: $globalTextSecondary;
		}
		&__number {
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 600;
			font-size: 16px;
			color: $globalTextColorMain;
		}
	}
}

.button {
	grid-gap: 8px;
	gap: 8px;
	height: 50px;
	padding: 0 24px;
	border-radius: 12px;
	font-weight: 600;
	font-size: 16px;
	line-height: 110%;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 110px;
	cursor: pointer;
	transition: all 0.15s ease;
	background: $globalMainBlue;
	border: none;
	color: #fff;
	&:disabled {
		background: #eaeaef;
		opacity: 1;
		color: #9996be;
		border: none;
	}
	&_cancel {
		background: transparent;
		border: 1px solid $globalStrokeAndDivider;
		color: $globalTextSecondary;
	}
}
