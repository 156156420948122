.header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	margin-bottom: 16px;
	&__button {
		height: 40px;
		padding: 8px 16px;
	}
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;

	&--center {
		text-align: center;
	}

	@media screen and (max-width: 991px) {
		font-size: 21px;
	}

	&--smaller {
		font-size: 20px;

		@media screen and (max-width: 991px) {
			font-size: 20px;
		}
	}

	&--mb0 {
		margin-bottom: 0;
	}
}
