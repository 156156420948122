@import 'assets/styles/scss/variables';

$transition: 0.2s;

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}

.crypto {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 16px;
	margin-top: 16px;
}

.append {
	flex: 0 0 calc(25% - 12px);
	max-width: 100%;
	padding: 24px;
	background: $globalWhite;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 2px dashed $globalStrokeAndDivider;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	min-height: 272px - 48px;
	transition: 0.2s;
	&__icon {
		width: 40px;
		height: 40px;
		background: #4285F5;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $transition;
	}
	&__text {
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0;
		color: #1C1C1E;
		transition: $transition;
	}
	&__union {
		position: absolute;
		bottom: 0;
		right: 0;
		transition: $transition;
	}
	& path {
		transition: $transition;
	}
	&:hover {
		border: 2px dashed $globalWhite;
		background: $globalMainBlue;
		.append__icon {
			background: $globalWhite;
			svg, path {
				stroke: $globalMainBlue;
			}
		}
		.append__union {
			svg, path {
				fill: $globalWhite;
			}
		}
		.append__text {
			color: $globalWhite;
		}
	}
}

.main {
	flex: 1 0;
	padding: 32px;
	background-color: $button-hover-close;
	width: 100%;

	&__wrapper {
		background-color: $globalWhite;
		padding: 24px;
		padding-bottom: 0;
		border-radius: 12px;
		box-shadow: 0 2px 2px 0 $boxShadowColor;
	}

	@media screen and (max-width: 1360px) {
		padding: 24px;
	}
}

@media screen and (max-width: 1300px) {
	.append {
		flex-basis: calc(calc(100% / 3) - 11px) ;
		max-width: calc(calc(100% / 3) - 11px);
	}
}

@media screen and (max-width: 800px) {
	.append {
		flex-basis: calc(50% - 8px);
		max-width: calc(50% - 8px);
	}
}

@media screen and (max-width: 600px) {
	.append {
		flex-basis: 100%;
		max-width: 100%;
	}
}
