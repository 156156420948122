// 📌 Utils

.capitalize {
	text-transform: capitalize;
}

// 📌 ImagePreview

.image-preview__close-button {
	position: absolute;
	left: 100%;
	transform: translateY(-100%);
	width: 44px;
	height: 44px;

	svg {
		width: 100%;
		height: 100%;

		path {
			stroke: #fff;
		}
	}
}
.info-name--size {
	font-size: 14px;
	color: var(--text-color-main);
	font-weight: 500;
}

.action-button--size {
	height: 50px;
}

.unactive--color {
	pointer-events: none;
	color: gray;
}

.image_preview__image {
	display: block;
	max-width: calc(100vw - 88px);
	max-height: calc(100vh - 88px);
}

.select-block--error {
	&--error {
		.select {
			border: 1px solid #fd3232;
		}
	}
}

.tr.active_kyc::after {
	opacity: 1;
}

.token--center {
	justify-content: center !important;
}
.token--color_gray {
	color: var(--text-color2);
}

.tr::after {
	content: '';
	position: absolute;
	left: 0px;
	top: -18px;
	width: 100%;
	height: calc(100% + 36px);
	z-index: -2;
	opacity: 0;
	// border-radius: 12px;
	background: #ececec;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.select-block {
	&.disabled {
		.select__current {
			opacity: 0.8;
			cursor: default;
		}
	}
}

.tooltip--width-170 {
	width: 170px;
}

.report-frame {
	grid-template-columns: 540px 1fr;
	height: 400px;
}

.phone-input {
	&--container {
		display: flex;
		align-items: baseline;
	}
	&--code-select {
		flex-basis: 112px;
		margin-bottom: 0px;
		.select__drop {
			width: 200px;
		}
		.select-block__title {
			margin-bottom: 0px;
		}
	}
	&--input {
		flex: 1;
	}
	&.input--error {
		.input-item {
			border: 1px solid var(--red-color);
		}
	}
}

.cropper-crop-box, .cropper-view-box {
	border-radius: 50%;
}

.cropper-view-box {
	box-shadow: 0 0 0 1px #39f;
	outline: 0;
}

.crop-element {
	&, & > div {
		width: 100%;
	}
	.crop-img-btn {
		margin: 15px 0px;
	}
	.crop-image-result-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
