@import 'reset.scss';
@import 'ui-kit.scss';
@import 'ui-change.scss';
@import 'swiper.scss';

:root {
	--block-bg: #fff;

	--main-color: #4285f5;

	--text-color-main: #202020;
	--text-color2: #9996be;
	--text-color: #777e90;

	--border-color-main: #eaeaef;
	--border-color2: #dadada;

	--icon-color: #9996be;

	--border-width: 1px;
	--border-radius: 4px;

	--red-color: #fd3232;
	--green-color: #00ba88;
}

* {
	-webkit-font-smoothing: antialiased;
}

body {
	background: var(--block-bg);
	font-family: 'Plus Jakarta Sans', sans-serif;
	line-height: 1.5;
	font-size: 16px;
	font-weight: 400;
	color: var(--text-color-main);
	-webkit-font-smoothing: antialiased;
}

button,
input,
label,
textarea {
	font-family: 'Plus Jakarta Sans', sans-serif;
}

img {
	width: auto;
	max-width: 100%;
}

svg {
	width: auto;
	display: block;
	max-width: 100%;
}

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

main {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.flex {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
}

.flex-gap-10 {
	gap: 10px;
}

.flex-s {
	justify-content: flex-start !important;
}

.flex-m {
	align-items: center;
}

.flex-t {
	align-items: flex-start;
}

.flex-b {
	align-items: flex-end;
}

.flex-sb {
	justify-content: space-between;
}

.flex-c {
	justify-content: center;
}

.flex-sa {
	justify-content: space-around;
}

.flex-e {
	justify-content: flex-end;
}

.flex-ww {
	flex-wrap: wrap;
}

.header {
	display: flex;
	align-items: center;
	height: 64px;
	padding: 0 32px;
	background: var(--block-bg);
	border-bottom: 1px solid var(--border-color-main);

	@media screen and (max-width: 991px) {
		height: auto;
		padding: 12px 16px !important;
		.header-balance,
		.select {
			display: none;
		}
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 1440px;
		height: 100%;
		margin: 0 auto;
		@media screen and (max-width: 720px) {
			flex-wrap: wrap;
			gap: 4px 12px;
		}
	}

	&--type2 {
		padding: 0 32px 0 29px;

		.header__container {
			width: 100%;
		}
		@media screen and (max-width: 991px) {
			.header-right {
				width: 100%;
			}
		}
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}
}

.header-left {
	display: flex;
	align-items: center;
	padding: 0 25px 0 0;
	.select-block {
		margin-bottom: 0;
	}

	@media screen and (max-width: 991px) {
		padding: 0 16px 0 0;
	}

	&--type2 {
		height: 100%;
		.logo {
			width: 208px;
			height: 100%;
			padding-right: 25px;
			border-right: 1px solid var(--border-color-main);
		}

		@media screen and (max-width: 991px) {
			border: none;
		}
		@media screen and (max-width: 720px) {
			width: 100%;
			justify-content: flex-start;
		}
	}
}

.header-right {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
}

.nav-btn {
	display: none;
	margin-left: auto;
	width: 24px;
	&__open {
		svg path {
			stroke: var(--text-color-main);
		}
	}
	&__close {
		svg path {
			fill: var(--text-color-main);
		}
	}
	@media screen and (max-width: 991px) {
		display: flex;

		&__close {
			display: none;
		}

		&.active {
			.nav-btn__close {
				display: flex;
			}

			.nav-btn__open {
				display: none;
			}
		}
	}
}

.nav-open {
	position: relative;
	overflow: hidden;
}

.logo {
	display: flex;
	align-items: center;
	width: 183px;
	@media screen and (max-width: 991px) {
		width: 160px !important;
		border: none !important;
		padding: 0 !important;
	}
}

.authorization-section {
	position: relative;
	display: flex;
	align-items: center;
	min-height: unset;
	flex: 1;
	padding: 75px 0;
	overflow: hidden;
	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}
	&__figure {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 630px;
		height: 567px;
		border-radius: 50%;
		background: #4285f5;
		opacity: 0.1;
		filter: blur(180px);
	}
}

.authorization-box {
	position: relative;
	max-width: 464px;
	width: 100%;
	margin: 0 auto;
	z-index: 2;
	@media screen and (max-width: 767px) {
		max-width: 100%;
	}
}

.form {
	border-radius: 20px;
	background: var(--block-bg);
	border-radius: 12px;
	padding: 40px 32px;
	&__body {
		margin-top: 32px;
	}
	&--authorization {
		box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.1);
	}
	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}
}

.form-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
	&--center {
		text-align: center;
	}
	@media screen and (max-width: 991px) {
		font-size: 21px;
	}
	&--smaller {
		font-size: 20px;

		@media screen and (max-width: 991px) {
			font-size: 20px;
		}
	}
	&--type2 {
		margin-bottom: 16px;
	}
}

.form-subtitle {
	font-weight: 700;
	font-size: 18px;
	line-height: 150%;
	@media screen and (max-width: 767px) {
		font-size: 17px;
	}
}

.form-text {
	margin-top: 8px;
	line-height: 1.6;
	color: var(--text-color2);
	&--center {
		text-align: center;
	}
	&--mt-0 {
		margin-top: 0;
	}
}

.form-group {
	margin-top: 32px;
	&--type2 {
		margin-top: 24px;
	}
}

.form-options {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.form-img {
	display: flex;
	width: 120px;
	margin: 0 auto 18px auto;
}

.form-footer {
	display: flex;
	justify-content: center;
	margin-top: 32px;
	gap: 16px;
	&--less-mt {
		margin-top: 24px;
	}

	&--column {
		flex-direction: column;
		align-items: center;
		gap: 32px;
	}

	&--mob-column {
		@media screen and (max-width: 575px) {
			flex-direction: column;
		}
	}
}

.input-name {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
}

.input-notification {
	margin-top: 4px;
	font-size: 14px;
	line-height: 165%;
	font-weight: 400;

	&--error {
		color: var(--red-color);
	}
}

.link {
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--main-color);
	transition: 0.2s linear;

	&:hover,
	&:focus {
		color: darken(#4285f5, 10%);
	}

	&--center {
		text-align: center;
		justify-content: center;
	}

	&--type2 {
		justify-content: center;
		text-transform: uppercase;
	}

	svg {
		min-width: 23px;
	}
	&--type3 {
		font-size: 16px;
		font-weight: 400;
	}
}

.password-options {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.password-strength {
	position: relative;
	display: flex;
	margin-top: 16px;
	gap: 4px;
	height: 8px;

	&__line {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 8px;
		background: var(--border-color-main);
		border-radius: 12px;
	}

	&__item {
		position: relative;
		display: none;
		width: 25%;
		height: 8px;
		border-radius: 12px;
		z-index: 2;

		&--weak {
			background: #fd3232;
		}

		&--strong {
			background: #00ba88;
		}

		&.active {
			display: block;
		}
	}
}

.support-btn {
	position: fixed;
	right: -47px;
	bottom: 79px;
	transform: rotate(-90deg);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 138px;
	height: 47px;
	background: var(--main-color);
	border-radius: 12px 12px 0px 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: #fff;
	z-index: 99;

	&__icon {
		display: flex;
		transform: rotate(90deg);
	}
}

.tfa {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 24px 0;

	&__qr {
		width: 160px;
		margin: 0 auto;
	}

	&__code {
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
}

.main-section {
	display: flex;
	flex: 1;
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
}

.content-container {
	display: flex;
	max-width: 1310px;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin: 0 auto;
	gap: 24px 20px;
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
	.main-content--type2 {
		padding-right: 0;
	}
}

.sidebar {
	width: 237px;
	padding: 16px 12px;
	border-right: 1px solid var(--border-color-main);
	@media screen and (max-width: 991px) {
		display: none;
		width: 100%;
		padding: 16px;
		&.active {
			display: block;
		}
	}
	.back-page {
		margin: 8px 0 16px 0;
	}
}

.sidebar-title {
	margin-bottom: 35px;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	@media screen and (max-width: 991px) {
		margin-bottom: 16px;
	}
}

.sidebar-nav {
	&__list {
		> li {
			a,
			button {
				display: flex;
				align-items: center;
				height: 50px;
				gap: 16px;
				padding: 0 16px;
				border-radius: 12px;
				font-weight: 600;
				font-size: 16px;
				line-height: 160%;
				color: var(--text-color-main);

				&:hover {
					color: var(--main-color);
				}

				&.active {
					background: var(--main-color);
					color: #fff;

					.sidebar-nav-icon {
						svg path {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
}

.sidebar-nav-text {
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sidebar-nav-icon {
	display: flex;
	width: 24px;
	flex: 0 0 24px;

	svg path {
		stroke: var(--main-color);
	}
}

.main-content {
	flex: 1 0 0;
	padding: 24px 40px;
	@media screen and (max-width: 1400px) {
		padding: 24px;
	}
	@media screen and (max-width: 991px) {
		width: 100%;
		flex: 0 0 100%;
		padding: 16px;
	}

	&--type2 {
		padding-top: 107px;

		@media screen and (max-width: 991px) {
			padding-top: 16px;
		}
	}
	&--type3 {
		max-width: 100%;
		padding: 24px 0 !important;
	}
	&--full-width {
		max-width: 100%;
	}
	.projects-custom {
		@media screen and (max-width: 1050px) {
			grid-template-columns: 1fr 1fr !important;
		}
		@media screen and (max-width: 860px) {
			grid-template-columns: 1fr !important;
		}
	}
}

.content-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;

	&__actions {
		display: flex;
		align-items: center;
		gap: 16px 24px;

		@media screen and (max-width: 767px) {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;

			.button {
				width: 100%;
			}
		}
		&--buttons {
			gap: 16px 8px;
			@media screen and (max-width: 767px) {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				.button {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 991px) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 16px;
		.form-title {
			padding: 0;
		}
	}
}

.content-search {
	width: 260px;

	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.btn-icon {
	display: flex;
	justify-content: center;
	max-width: 16px;
}

.table-block {
	margin-top: 32px;
	&--mt-40 {
		margin-top: 40px !important;
	}
}

.table {
	@media screen and (max-width: 1380px) {
		word-break: break-word;
	}
	&--type2 {
		border: 1px solid #eaeaef;
		border-radius: 16px;
		text-align: left;
		.tr {
			margin: 0 !important;
			gap: 0;
			border-bottom: 1px solid #eaeaef;
			align-items: stretch;
			@media screen and (max-width: 1199px) {
				padding: 0;
			}
		}
		.td {
			padding: 0 16px;
			min-height: 55px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-right: 1px solid #eaeaef;
			&:last-child {
				border-right: none;
			}
			@media screen and (max-width: 1199px) {
				padding: 8px 16px;
				&:last-child {
					border-right: 1px solid #eaeaef;
				}
				&:nth-child(2n) {
					border-right: none;
				}
			}
		}
		.td-name {
			font-weight: 600;
			font-size: 14px;
			line-height: 165%;
			color: #202020;
		}
		.table-header {
			.td {
				background: #f2f6ff;
				&:first-child {
					border-radius: 16px 0 0 0;
				}
				&:last-child {
					border-radius: 0 16px 0 0;
				}
			}
		}
		.table-body {
			margin-top: 0;
			.tr {
				border-bottom: 1px solid #eaeaef;
				&:last-child {
					border-bottom: none;
				}
			}
			.td {
				font-weight: 500;
				font-size: 14px;
				line-height: 165%;
				color: #202020;
				&--fw-600 {
					font-weight: 600;
				}
			}
		}
	}
	&--currency-flow {
		& .tr {
			grid-template-columns: repeat(6, 1fr);
		}
	}
	&--balances {
		& .tr {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&--user-profile-white-list {
		& .tr {
			grid-template-columns: 2.68fr 4.04fr 2.5fr 1.8fr;
		}
		.table-header {
			.td {
				&:last-child {
					padding-right: 16px;
				}
			}
		}
		.table-body {
			padding-right: 16px;
			height: 180px;
			overflow-y: auto;
			overflow-x: hidden;

			scrollbar-color: #eaeaef;
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 3px;
				background: var(--text-color2);
				cursor: pointer;
			}
			@media screen and (max-width: 1199px) {
				height: 360px;
			}
		}
	}
	&--profile-approval-management {
		& .tr {
			grid-template-columns: 0.32fr 2.37fr 1.5fr 1.55fr 1.1fr 1.77fr 1fr;
		}
	}

	&--profile-approval-management-user-profile {
		& .tr {
			grid-template-columns: 2.5fr 2fr 1fr;
		}
	}

	&--profile-bank-account {
		& .tr {
			grid-template-columns: 1.22fr 1fr 1fr 3fr 0.6fr;
		}
		.token {
			&__name {
				font-weight: 500;
				font-size: 14px;
				line-height: 165%;
			}
		}
	}
	&--settings-tickets {
		.tr {
			position: relative;
			min-height: 57px;
			grid-template-columns: 60px 1fr 1fr 1fr 1fr 1fr;
			@media screen and (max-width: 1199px) {
				padding-left: 24px;
			}
		}
		.table-body {
			margin: 0;
			.tr + .tr {
				margin-top: 0;
				border-top: 1px solid var(--border-color-main);
				@media screen and (max-width: 1199px) {
					padding-top: 8px;
				}
			}
		}
		.td {
			&:first-child {
				padding-left: 24px;
			}
		}
	}
	&--transactions-crypto,
	&--transactions-fiat {
		& .tr {
			grid-template-columns: 50px 0.7fr 0.7fr 1.4fr 1.1fr 0.8fr 0.7fr 1fr 0.7fr 50px;
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
	}
	&--transactions-approvements {
		& .tr {
			grid-template-columns:
				30px 0.8fr 0.95fr 0.65fr 0.62fr 0.94fr minmax(92px, 0.9fr)
				0.85fr 0.65fr 0.63fr 0.59fr 0.4fr 50px;
		}
		.table-header {
			.td {
				word-break: normal;
			}
		}
		@media screen and (max-width: 1199px) {
			.td {
				word-break: normal;
			}
		}
	}

	&--callbacks {
		& .tr {
			grid-template-columns: 0.33fr 0.75fr 1.37fr 1.67fr 1.65fr 0.87fr 0.8fr 1.11fr minmax(
					200px,
					1fr
				);
		}
		.table-buttons {
			justify-content: space-between;
			@media screen and (max-width: 1199px) {
				max-width: 200px;
			}
		}
	}

	&--reports {
		& .tr {
			grid-template-columns: 2.25fr 2.5fr 2.52fr 1.75fr 1.46fr;
		}
	}

	&--accounts-fiat {
		& .tr {
			grid-template-columns: 1.95fr 1.25fr 1.4fr 1.5fr 1.4fr 3.2fr;
			@media screen and (max-width: 560px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
	}

	&--accounts-сrypto {
		& .tr {
			grid-template-columns: 1.95fr 1.25fr 1.4fr 1.5fr 1.4fr 3.2fr;
			@media screen and (max-width: 560px) {
				grid-template-columns: repeat(1, 1fr) !important;
			}
		}
	}

	&--currency-settings-currencies {
		& .tr {
			grid-template-columns: 1fr 1fr;
		}
	}

	&--currency-settings-limitation {
		& .tr {
			grid-template-columns: 3.05fr 2.2fr 2.4fr 1.5fr 1.3fr;
		}
	}
	&--account-settings-limitation-new {
		& .tr {
			grid-template-columns: 1.5fr 1.5fr 1.5fr 1.75fr 1.2fr 1.2fr 1.25fr 0.85fr;
		}
	}

	&--invoice {
		& .tr {
			grid-template-columns: 0.22fr 1.57fr 1.45fr 0.75fr 0.67fr 0.86fr 0.82fr 0.78fr 0.66fr 1.58fr 0.75fr;
		}
	}

	&--merchant-client {
		& .tr {
			grid-template-columns:
				0.57fr 0.82fr 0.67fr 1.97fr 1.26fr 1.07fr minmax(90px, 0.75fr)
				0.75fr 0.7fr 0.7fr 0.6fr;
		}
	}
	&--merchant-client-edit {
		& .tr {
			grid-template-columns: 0.58fr 0.84fr 0.7fr 2fr 0.9fr 1.1fr 0.77fr 0.9fr 0.94fr 1fr 0.62fr;
		}
		.td-value {
			word-break: break-all;
		}
	}
	&--transaction-history-client {
		& .tr {
			grid-template-columns: 0.72fr 1.42fr 3.2fr 1.48fr 0.75fr 0.75fr 0.55fr;
		}
	}
	&--merchant-orders {
		& .tr {
			grid-template-columns: 0.2fr 1.3fr 0.57fr 0.59fr 0.65fr 0.62fr 0.86fr 0.95fr 0.8fr 0.7fr 0.49fr 0.8fr;
		}
	}
}

// 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px

.table-buttons-wrap {
	display: flex;
	justify-content: space-between;
	gap: 16px;
}

.table-buttons {
	position: relative;
	gap: 8px;
	button + button {
		margin-left: 0;
	}
	&--gap16 {
		gap: 16px;
	}
}

.table-buttons-drop {
	position: absolute;
	right: 0;
	top: calc(100% + 8px);
	width: 180px;
	border-radius: 12px;
	border: 1px solid #eaeaef;
	display: none;
	z-index: 10;
	&.active {
		display: block;
	}
	&__item {
		padding: 8px 16px;
		display: block;
		height: 39px;
		color: #202020;
		text-align: left;
		border-bottom: 1px solid #eaeaef;
		&:hover {
			color: var(--main-color);
			transition: 0.3s;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.tr-status {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 8px;
	&--green {
		background: #00ba88;
	}
	&--red {
		background: #fd3232;
	}
}

.td-status {
	&--green {
		color: #00ba88 !important;
	}
	&--grey {
		color: #9996be !important;
	}
	&--red {
		color: #fd3232 !important;
	}
}

.d-block {
	display: block;
}

.header-admin {
	display: flex;
	align-items: center;
	gap: 16px;

	&__avatar {
		padding: 8px;
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f2f6ff;
		border-radius: 12px;
		@media screen and (max-width: 1199px) {
			display: none;
		}

		svg,
		img {
			max-width: 100%;
		}
	}

	&__name {
		color: var(--text-color-main);
		text-align: left;

		span {
			display: block;
			font-weight: 600;
			font-size: 16px;
			@media screen and (max-width: 720px) {
				font-size: 14px;
			}

			&:nth-child(2) {
				font-weight: 400;
				font-size: 12px;
				@media screen and (max-width: 720px) {
					font-size: 12px;
				}
			}
		}
	}
}

.table-plug {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__icon {
		max-width: 120px;
		margin-bottom: 12px;
	}

	&__text {
		margin-bottom: 32px;
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
}

.popup-window {
	background: rgba(153, 150, 190, 0.7);
	backdrop-filter: blur(4px);
	&__inside {
		@media screen and (max-width: 720px) {
			padding: 16px;
		}
	}
}

.popup {
	padding: 40px 32px;
	width: 465px;

	&__close {
		width: 24px;
		height: 24px;
	}
	&--width-480 {
		width: 480px;
	}
	&--width-540 {
		width: 540px;
	}
	&--width-660 {
		width: 660px;
	}
	@media screen and (max-width: 575px) {
		padding: 32px 16px 24px 16px;
	}
}

.popup-header {
	&__title {
		font-size: 20px;
		& + .popup-text {
			margin-top: 8px;
		}
	}
}

.popup-body {
	margin-top: 8px;
	&--type2 {
		margin-top: 32px;
	}
	&--type3 {
		margin-top: 24px;
	}
	&--mt-16 {
		margin-top: 16px;
	}
}

.popup-text {
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: var(--text-color2);
	line-height: 160%;
	&--fz-14 {
		font-size: 14px;
	}
	&--type2 {
		text-align: left;
	}
	&--mt-8 {
		margin-top: 8px;
	}
	ol {
		list-style: decimal;
		li {
			line-height: 165%;
		}
	}
}

.popup-button {
	margin-top: 26px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	&--type2 {
		flex-direction: row;
		@media screen and (max-width: 575px) {
			flex-direction: column;
		}
	}
}

.back-page {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	gap: 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color2);
}

.add-project-container {
	max-width: 845px;
	margin: 0 auto;
}

.fields-wrap {
	margin-top: 32px;
	display: grid;
	grid-template-columns: 1.05fr 1fr;
	gap: 48px;
	&--type2 {
		grid-template-columns: 1fr 387px;
		max-width: 1071px;
	}
	@media screen and (max-width: 1199px) {
		gap: 32px;
	}

	@media screen and (max-width: 720px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.fields-list {
	&__title {
		margin-bottom: 24px;
		font-weight: 600;
		font-size: 18px;
	}
}

.fields-button {
	margin-top: 40px;
	display: flex;
	justify-content: flex-end;
	gap: 24px;
	&--mt0 {
		margin-top: 0;
	}
	&--center {
		align-items: center;
	}
}

.tabs {
	padding-bottom: 15px;
	border-bottom: 1px solid var(--border-color-main);
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	&__item {
		padding: 0 15px;
		position: relative;
		min-width: 160px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color2);
		transition: 0.3s;
		@media screen and (max-width: 720px) {
			padding: 0 10px;
			min-width: unset;
			flex: 0 0 100px;
		}

		&:hover {
			color: #202020;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -16px;
			display: none;
			width: 100%;
			height: 4px;
			background: var(--main-color);
			border-radius: 2px 2px 0px 0px;
		}

		&.active {
			color: var(--text-color-main);

			&::before {
				display: block;
			}
		}
	}
}

.line-info-block {
	margin-bottom: 24px;
}

.line-info {
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color-main);
	word-break: break-all;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
	}

	&--type2 {
		justify-content: space-between;
	}

	&__name {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color-main);
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}
}

.tfa-block {
	max-width: 520px;
	margin: 40px 0;
	padding-bottom: 24px;
	border-bottom: 1px solid var(--border-color-main);

	&__title {
		margin-bottom: 4px;
		font-weight: 600;
		font-size: 18px;
		color: var(--text-color-main);
	}

	&__box {
		display: flex;
		align-items: flex-start;
		gap: 20px;
	}

	&__text {
		max-width: 464px;
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}
}

.content-title {
	margin-bottom: 32px;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	color: var(--text-color-main);
}

.projects {
	margin-top: 32px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 24px;

	@media screen and (max-width: 1150px) {
		grid-template-columns: 1fr;
		gap: 16px;
	}
}

.project {
	position: relative;
	padding: 32px 24px;
	background: #ffffff;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	@media screen and (max-width: 767px) {
		padding: 24px 16px;
	}
	&.active {
		box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);
	}

	&__top {
		margin-bottom: 24px;
	}

	&__head {
		display: flex;
		align-items: flex-start;
	}

	&__icon {
		display: flex;
		width: 61px;
		margin-right: 16px;
	}

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
	}

	&__wrapper-btn {
		position: relative;
		margin-left: auto;
	}

	&__btn {
		// position: absolute;
		// top: 32px;
		// right: 24px;
		display: flex;
		width: 24px;
		height: 24px;
		align-items: center;
		gap: 4px;
		span {
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #9996be;
		}
	}

	&__info {
		padding-left: 77px;
		display: flex;
		justify-content: space-between;
	}

	&__date {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}

	&__status {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		padding-top: 16px;
		border-top: 1px solid var(--border-color-main);
	}

	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
	}

	&__number {
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
	}

	.dropdown {
		right: 0;
		width: 220px;
	}
}

.project--inactive {
	.project__title,
	.project__date,
	.project__text,
	.project__number {
		color: var(--text-color2);
	}

	.project__icon {
		opacity: 0.5;
	}
}

.token {
	display: flex;
	align-items: center;

	&__icon {
		display: flex;
		width: 34px;
		margin-right: 16px;
	}

	&__name {
		margin-right: 8px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color-main);
	}

	&__transcription {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color2);
	}

	&--type2 {
		.token {
			&__icon {
				display: flex;
				width: 40px;
				margin-right: 16px;
			}
			&__name {
				font-weight: 500;
				font-size: 14px;
			}
		}
	}

	&--type3 {
		.token {
			&__icon {
				display: flex;
				width: 34px;
				margin-right: 16px;
			}
			&__name {
				font-weight: 500;
				font-size: 14px;
			}
		}
	}
	&--type4 {
		&__icon {
			display: flex;
			width: 34px;
			margin-right: 16px;
		}

		.token {
			&__name {
				font-weight: 600;
				font-size: 16px;
			}
		}
	}
	&--type5 {
		.token {
			&__icon {
				display: flex;
				width: 40px;
				margin-right: 16px;
				img {
					width: 100%;
					object-fit: cover;
				}
			}
			&__name {
				font-weight: 700;
				font-size: 18px;
			}
		}
	}
}

.select-info,
.input-info {
	margin-top: 8px;
	display: flex;
	gap: 8px;

	&--right {
		text-align: right;
		justify-content: flex-end;
	}

	&__name {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}

	&__number {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 16px;
		color: var(--text-color-main);

		button {
			color: var(--main-color);
		}
	}
	&__number-price {
		font-size: 14px;
		font-weight: 400;
		color: var(--main-color);
	}
}

.dropdown {
	position: absolute;
	top: 25px;
	display: none;
	width: 100%;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	background-color: #fff;

	&.active {
		display: block;
	}
}

.dropdown-item {
	border-bottom: 1px solid var(--border-color-main);
	transition: all 0.3s ease;

	&:hover {
		background-color: #f4f4f4;
	}

	&:first-child {
		border-radius: 12px 12px 0 0;
	}

	&:last-child {
		border-radius: 0 0 12px 12px;
		border-bottom: none;
	}

	&__link {
		display: block;
		padding: 13px 16px;
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__icon {
		display: flex;
		width: 24px;
	}

	&__name {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color-main);
	}
}

.tabs-buttons {
	margin-top: 24px;
	padding: 16px 20px;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 8px;
	background: #f2f6ff;
	border-radius: 12px;
}

.tabs-buttons-wrap {
	margin-top: 24px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	.input {
		margin-bottom: 0;
	}
	.tabs-buttons {
		margin-top: 0;
	}
}

.table-filter {
	margin-top: 24px;
	margin-bottom: 24px;
	display: grid;
	align-items: flex-end;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 12px;
	.input-item--right-icon {
		padding-right: 40px;
	}
	> * {
		margin-bottom: 0;
	}
	@media screen and (max-width: 1300px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (max-width: 575px) {
		grid-template-columns: 1fr;
	}
	&--transactions-page {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		@media screen and (max-width: 1199px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media screen and (max-width: 820px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--callbacks {
		//grid-template-columns: 1.75fr 1.75fr 2.65fr 1.75fr 50px 170px;
		grid-template-columns: 1.65fr 1.65fr 1.55fr 1.55fr 1.2fr 50px 160px;
		.button {
			padding: 0 8px;
		}

		@media screen and (max-width: 1340px) {
			display: flex;
			flex-wrap: wrap;
			.select-block,
			.input {
				width: calc((100% - 36px) / 4);
			}
		}
		@media screen and (max-width: 991px) {
			.select-block,
			.input {
				width: calc((100% - 12px) / 2);
			}
		}
		@media screen and (max-width: 767px) {
			.select-block,
			.input {
				width: 100%;
			}
		}
	}
	&--reports {
		grid-template-columns: 5.25fr 3.15fr 2fr;
		@media screen and (max-width: 1300px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
	&--invoice {
		grid-template-columns: 1fr 1fr 1fr 50px 170px;
		@media screen and (max-width: 1199px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	&--orders {
		grid-template-columns: 1fr 1fr;
		@media screen and (max-width: 575px) {
			grid-template-columns: 1fr;
		}
	}
}

.filter-btns {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	@media screen and (max-width: 1199px) {
		justify-content: flex-start;
	}
}

.hint-value {
	position: relative;
	display: inline-flex;
	&:hover {
		.tooltip {
			display: block;
		}
	}
}

.block-title-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.block-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 150%;
	&--type2 {
		margin-top: 40px;
	}
	&--fz-18 {
		font-size: 18px;
	}
	&--mb16 {
		margin-bottom: 16px;
	}
	&--mb8 {
		margin-bottom: 8px;
	}
	&--line2 {
		line-height: 1.65;
	}
}

.buttons-wrap {
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	gap: 16px;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	@media screen and (max-width: 560px) {
		width: 100%;
	}
	.button {
		@media screen and (max-width: 560px) {
			width: 100%;
		}
	}
}

.qr-code {
	margin: 32px auto;
	text-align: center;
	img {
		max-width: 100%;
	}
}

.copy-address {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	font-size: 14px;
	word-break: break-word;
	background: #f2f6ff;
	border-radius: 12px;
	font-weight: 500;
}

.item-wrap {
	margin-top: 32px;
	margin-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.item-line {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	&--type2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: flex-start;
		@media screen and (max-width: 520px) {
			grid-template-columns: repeat(1, 1fr);
			gap: 0;
			.select-block__name {
				min-height: auto;
			}
		}
	}
	@media screen and (max-width: 520px) {
		width: 100%;
		> * {
			width: 100%;
		}
	}
}

.item {
	max-width: 100%;
	width: 200px;
	.input,
	.select-block {
		margin-bottom: 0;
	}
	@media screen and (max-width: 520px) {
		width: 100%;
	}
}

.status-block {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 4px;
	font-weight: 500;
}

.status-value {
	font-weight: 500;
	font-size: 14px;
	&--green {
		color: #00ba88 !important;
	}
}

.approvements-list {
	margin-bottom: 40px;
	margin-top: 36px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px;
	@media screen and (max-width: 860px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.approvements-item {
	display: flex;
	justify-content: space-between;
	gap: 8px;
	font-size: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color-main);
	@media screen and (max-width: 860px) {
		flex-wrap: wrap;
	}
	&__title {
		color: var(--text-color2);
	}
	&__value {
		display: flex;
		gap: 16px;
		@media screen and (max-width: 860px) {
			flex-wrap: wrap;
		}
	}
	&__status {
		text-align: right;
		margin-left: auto;
	}
	&--status {
		justify-content: flex-start;
		gap: 16px;
	}
}

.approvements-column {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.approvements-user-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px 40px;
	margin-bottom: 40px;
	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
	.approvements-user {
		margin-top: 0;
	}
}

.approvements-user {
	margin-top: 30px;
	padding: 24px;
	display: flex;
	gap: 16px;
	border: 1px solid var(--border-color-main);
	border-radius: 12px;
	&__icon {
		min-width: 46px;
	}
	&__name {
		font-weight: 600;
		font-size: 16px;
	}
	&__value {
		font-size: 20px;
		font-weight: 600;
	}
	&__date {
		font-size: 12px;
		margin-bottom: 12px;
	}
	&__text {
		font-size: 16px;
		line-height: 160%;
		&--type2 {
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
		}
	}
	@media screen and (max-width: 800px) {
		padding: 16px;
	}
}

.inside {
	width: 1310px;
	max-width: 100%;
	padding: 0 15px;
	text-align: center;
	margin: 0 auto;
	box-sizing: border-box;
	&--type2 {
		width: 930px;
	}
}

.header-login-button {
	display: flex;
	gap: 8px;
	@media screen and (max-width: 720px) {
		width: 100%;
		justify-content: center;
	}
}

.form-info {
	padding-top: 4px;
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: #fd3232;
}

.first-screen-section {
	background: url(../images/first-screen-bg.png) no-repeat center bottom;
	@media screen and (max-width: 920px) {
		background: none;
	}

	&--type2 {
		background: none;
	}
}

.first-screen {
	padding-top: 80px;
	@media screen and (max-width: 920px) {
		padding-top: 50px;
	}
	&--type2 {
		position: relative;
		padding-top: 225px;
		display: flex;
		text-align: left;
		@media screen and (max-width: 920px) {
			padding-top: 50px;
			flex-wrap: wrap;
		}
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 660px;
			height: 600px;
			transform: translate(-50%, -50%);
			background: #4285f5;
			opacity: 0.1;
			filter: blur(90px);
		}
		.first-screen-info {
			width: 540px;
			@media screen and (max-width: 920px) {
				margin-bottom: 24px;
				width: 100%;
			}
		}
		.first-screen-title {
			margin-left: 0;
			margin-right: 0;
			font-size: 44px;
			@media screen and (max-width: 920px) {
				font-size: 24px;
				text-align: center;
			}
		}
		.first-screen-text {
			margin-left: 0;
			margin-right: 0;
			@media screen and (max-width: 920px) {
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
		}
		.first-screen-button {
			justify-content: flex-start;
			@media screen and (max-width: 920px) {
				justify-content: center;
			}
		}
		.first-screen-photo {
			margin-top: 0;
			margin-left: -60px;
			@media screen and (max-width: 920px) {
				margin-left: 0;
				margin: 0 auto;
				text-align: center;
			}
		}
	}
}

.first-screen-title {
	margin: 0 auto;
	margin-bottom: 24px;
	max-width: 850px;
	font-weight: 700;
	font-size: 34px;
	line-height: 120%;
	@media screen and (max-width: 720px) {
		font-size: 24px;
	}
}

.first-screen-text {
	margin: 0 auto;
	margin-bottom: 42px;
	max-width: 410px;
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: #9996be;
}

.first-screen-button {
	display: flex;
	justify-content: center;
	gap: 8px;
	.button {
		width: 180px;
	}
}

.first-screen-photo {
	margin-top: 68px;
	text-align: center;
}

.user-info {
	margin-top: 16px;
	max-width: 628px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.user-info-item {
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color-main);
	&__title {
		margin-bottom: 8px;
		font-weight: 400;
		font-size: 16px;
		color: #9996be;
	}
	&__text {
		font-weight: 500;
		font-size: 16px;
	}
	&__subtitle {
		margin-bottom: 24px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		color: #202020;
	}
}

.user-info-item-double {
	display: flex;
	.user-info-item {
		width: 50%;
	}
}

.info {
	max-width: 628px;
	margin-top: 16px;
	&--width-411 {
		max-width: 411px;
	}
	&--width-520 {
		max-width: 520px;
	}
}

.info-title {
	margin-bottom: 32px;
	font-weight: 700;
	font-size: 20px;
}

.notification-setting {
	margin-top: 42px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	gap: 26px;
}

.notification-setting-item {
	padding-bottom: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	border-bottom: 1px solid var(--border-color-main);
}

.notification-setting-title {
	font-weight: 600;
	font-size: 18px;
}

.notification-setting-info {
	p {
		margin-bottom: 6px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
	}
	span {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: #9996be;
	}
}

.user-profile-white-list-button {
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
}

.header-admin-wrap {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 32px;
	border-left: 1px solid var(--border-color-main);
	&.active {
		.header-admin-drop {
			display: block;
		}
		.header-admin__arrow {
			transform: rotate(180deg);
		}
	}

	@media screen and (max-width: 991px) {
		padding-left: 0;
		border-left: none;
	}
}

.header-admin-drop {
	position: absolute;
	right: 0;
	top: calc(100% + 15px);
	width: 220px;
	background: #ffffff;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	z-index: 100;
	display: none;
	@media screen and (max-width: 720px) {
		right: unset;
		left: 0;
	}
}

.header-admin-drop-item {
	cursor: pointer;
	padding: 13px 16px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 400;
	font-size: 16px;
	border-bottom: 1px solid #eaeaef;
	@media screen and (max-width: 720px) {
		font-size: 15px;
	}
	svg {
		@media screen and (max-width: 720px) {
			max-width: 20px;
		}
	}

	&:last-child {
		border: none;
	}
}

.header-balance {
	padding-right: 16px;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	// border-right: 1px solid #EAEAEF;
	text-align: right;
	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 170%;
	}
	span {
		font-weight: 700;
		font-size: 16px;
	}

	@media screen and (max-width: 991px) {
		text-align: left;
		border: none;
	}
}

.mobile-project-info {
	display: none;
	justify-content: space-between;
	align-items: center;
	gap: 8px 16px;
	margin-bottom: 16px;
	.select-block {
		margin-bottom: 0;
	}
	@media screen and (max-width: 991px) {
		display: flex;
		flex-wrap: wrap;
	}
}

.fields-item {
	&--type2 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.fields-button {
			padding-bottom: 24px;
		}
	}
	&--type3 {
		display: flex;
		flex-direction: column;

		.fields-button {
			justify-content: center;
			margin-top: 172px;
			@media screen and (max-width: 720px) {
				margin-top: 50px;
			}
		}
	}
}

// PROFILE BANK ACCOUNT

.account-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 32px;
	margin-bottom: 40px;
}

.account-box {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	padding: 24px;
	background: #f2f6ff;
	border-radius: 12px;
	@media screen and (max-width: 1175px) {
		flex-direction: column;
	}
}

.account-item {
	&__line {
		display: flex;
		align-items: center;
		gap: 24px;
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
	&__name {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color2);
	}
	&__value {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: var(--text-color-main);
		word-break: break-all;
	}
	&__buttons {
		display: flex;
		align-items: center;
		gap: 16px;
	}
	&__btn {
		display: flex;
		width: 23px;
	}
}

.upload-file {
	&__buttons {
		&--mt8 {
			margin-top: 8px;
		}
	}
}

.api-container {
	padding-top: 40px;

	.block-text {
		max-width: 523px;
	}
}

.api-item {
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
}

.block-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	color: var(--text-color2);
	&--mb32 {
		margin-bottom: 32px;
	}
	&--mb24 {
		margin-bottom: 24px;
	}
	&--mb16 {
		margin-bottom: 16px;
	}
	&--fs-14 {
		font-size: 14px;
	}
	&--line-165 {
		line-height: 1.65;
	}
}

.api-item-wrapper {
	display: flex;
	align-items: flex-end;
	gap: 16px;
	flex-wrap: wrap;
	.input {
		max-width: 523px;
	}
}

.input-description {
	margin-top: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color2);
	&--error {
		color: #fd3232;
	}
}

.action-buttons {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	&__item {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		&--blue {
			color: #4285f5;
		}
		&--red {
			color: #fd3232;
		}
	}
	&__icon {
		display: flex;
		width: 24px;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.api-item-descr {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: var(--text-color-main);
}

.api-item-descr-block {
	margin-bottom: 32px;
}

.priority-label {
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	&--type1 {
		background: #00ba88;
	}
}

/*---Comissions-----*/

.comissions-list {
	margin-top: 32px;
}

.comission-item {
	border-bottom: 1px solid var(--border-color-main);
	padding-bottom: 24px;
	&__title {
		display: flex;
		gap: 0 18px;
		font-weight: 600;
	}
	&__text {
		margin-top: 8px;
	}
	& + .comission-item {
		margin-top: 24px;
	}
}

.request-title {
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
}

.request-info {
	padding: 16px 24px;
	height: 240px;
	font-size: 16px;
	line-height: 160%;
	background: #f2f6ff;
	border-radius: 12px;

	overflow-y: auto;
	overflow-x: hidden;
	word-break: break-word;
	scrollbar-color: #eaeaef;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: var(--text-color2);
		cursor: pointer;
	}
}

.analytics-list {
	padding: 40px 0;
	display: flex;
	gap: 28px;
	border-bottom: 1px solid #eaeaef;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
	}
	&--type3 {
		margin-bottom: 40px;
		border: none;
		padding: 0;
	}
}

.analytics-item {
	@media screen and (max-width: 720px) {
		width: 100%;
	}
	&__title {
		margin-bottom: 18px;
		font-weight: 600;
		font-size: 18px;
	}
	&__schedule {
		img {
			max-width: 100%;
		}
	}
}

.analytics-item-info {
	margin-bottom: 24px;
	display: flex;
	gap: 66px;
	&__item {
		p {
			font-size: 14px;
		}
		span {
			font-weight: 600;
			font-size: 20px;
		}
	}
}

.analytics-data-wrap-title {
	margin-top: 40px;
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
}

.analytics-data-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 24px 40px;
	@media screen and (max-width: 720px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.analytics-data {
	padding: 24px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	@media screen and (max-width: 720px) {
		width: 100%;
		margin-bottom: 24px;
	}
	&--type2 {
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media screen and (max-width: 720px) {
			flex-wrap: wrap;
		}
	}
	&--type3 {
		text-align: center;
		* {
			justify-content: center;
		}
	}
	&__title {
		margin-bottom: 12px;
		font-weight: 600;
		font-size: 18px;
	}
	&__number {
		font-weight: 600;
		font-size: 20px;
		color: var(--main-color);
		p {
			display: flex;
			align-items: center;
		}
		span {
			font-weight: 400;
			font-size: 14px;
			color: var(--text-color-main);
			&.slash {
				padding: 0 14px;
				font-weight: 600;
				font-size: 20px;
				color: #9996be;
			}
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 64px;
	}
	&__schedule {
		margin-top: 32px;
		text-align: center;
		img {
			max-width: 100%;
		}
	}
}

.analytics-data-list {
	max-width: 100%;
	@media screen and (max-width: 720px) {
		width: 100%;
	}
	&:last-child {
		margin-right: 100px;
		@media screen and (max-width: 720px) {
			margin-right: 0;
		}
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;
		li {
			display: flex;
			align-items: center;
			gap: 16px;
			p {
				font-weight: 500;
				font-size: 14px;
				span {
					color: #9996be;
				}
			}
		}
	}
}

.withdrawal-info {
	margin-bottom: 8px;
	&__title {
		margin-bottom: 4px;
		font-size: 14px;
		line-height: 165%;
	}
	&__info {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		line-height: 165%;
		color: #9996be;
	}
}

.progress-line {
	position: relative;
	margin: 8px 0;
	width: 100%;
	height: 4px;
	background: #eaeaef;
	border-radius: 20px;
	&__fill {
		position: absolute;
		width: auto;
		height: 100%;
		left: 0;
		top: 0;
		border-radius: 20px;
		background: #4285f5;
	}
}

.withdrawal-fee-choice {
	margin-top: 24px;
}

.withdrawal-fee-choice-title {
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 165%;
}

.withdrawal-fee-choice-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
}

.withdrawal-fee-choice-item {
	padding: 15px;
	background: #ffffff;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	text-align: center;
	&.active {
		border-color: #4285f5;
		.withdrawal-fee-choice-item__time {
			color: #4285f5;
		}
	}
	&__time {
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: #9996be;
	}
	&__price {
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
	}
}

.deposit-info-pop {
	margin-bottom: 24px;
	padding: 24px;
	background: #f2f6ff;
	border-radius: 12px;
	@media screen and (max-width: 575px) {
		margin-bottom: 16px;
		padding: 16px;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 8px;
		@media screen and (max-width: 520px) {
			gap: 16px;
		}
		li {
			display: flex;
			justify-content: space-between;
			gap: 8px;
			font-weight: 500;
			font-size: 14px;
			word-break: break-word;
			@media screen and (max-width: 520px) {
				flex-wrap: wrap;
				> * {
					width: 100%;
					text-align: left;
					justify-content: flex-start;
				}
			}
			p {
				&.fw-600 {
					font-weight: 600;
				}
				&.fz-16 {
					font-size: 16px;
				}
			}
			span {
				color: #9996be;
			}
			strong {
				color: #202020;
			}
		}
	}
}

.input-text {
	position: absolute;
	top: 50%;
	right: 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #9996be;

	button {
		padding: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 1;
		color: #4285f5;
	}
}

.button-add {
	margin-top: 4px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 600;
	font-size: 16px;
	color: var(--main-color);
}

.copy-wrap {
	display: flex;
	align-items: center;
	gap: 8px;
	&--right {
		margin-left: auto;
		justify-content: flex-end;
		text-align: right;
	}
	button {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		font-weight: 500;
		color: var(--main-color);
	}
	svg {
		min-width: 24px;
	}
}

.exchange-switch {
	margin: 0 auto;
	display: flex;
}

.checkout-url {
	margin-top: 38px;
	&__title {
		margin-bottom: 8px;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
	}
	&__text {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 160%;
		color: #9996be;
	}
	&__item {
		display: flex;
		gap: 8px;
		@media screen and (max-width: 760px) {
			flex-wrap: wrap;
		}
	}
	&__link {
		max-width: 520px;
		padding: 12px 16px;
		display: flex;
		gap: 8px;

		border: 1px solid #eaeaef;
		border-radius: 12px;

		@media screen and (max-width: 760px) {
			max-width: 100%;
			width: 100%;
		}

		span {
			display: block;
			width: calc(100% - 30px);

			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.blog-list-title {
	margin-top: 40px;
	margin-bottom: 32px;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	text-align: left;
}

.blog-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
	@media screen and (max-width: 1220px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 720px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.blog-list-item {
	border-radius: 12px;
	text-align: left;
	transition: 0.3s;
	&:hover {
		box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.1);
	}
	&__photo {
		display: block;
		height: 240px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 12px 12px 0px 0px;
		}
	}
	&__info {
		padding: 16px 24px;
		height: 253px;
		border: 1px solid #eaeaef;
		border-top: none;
		border-radius: 0px 0px 12px 12px;
		transition: 0.3s;
		@media screen and (max-width: 1440px) {
			height: auto;
		}
	}
	&__title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
		a {
			color: #202020;
			&:hover {
				color: var(--main-color);
			}
		}
	}
	&__date {
		margin-bottom: 8px;
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: #9996be;
	}
	&__text {
		font-size: 16px;
		line-height: 160%;
		color: #9996be;
	}
}

.blog-list-section {
	padding-bottom: 40px;
}

.blog-page-section {
	padding: 32px 0;
}

.blog-header {
	margin-bottom: 16px;
	text-align: left;
	&__title {
		margin-bottom: 16px;
		font-weight: 700;
		font-size: 24px;
		line-height: 150%;
	}
	&__date {
		font-weight: 400;
		font-size: 14px;
		line-height: 165%;
		color: #9996be;
	}
}

.blog-page {
	text-align: left;
	img {
		margin-bottom: 40px;
		max-width: 100%;
		border-radius: 12px;
	}
	h3 {
		margin-bottom: 24px;
		font-size: 20px;
		font-weight: 700;
	}

	h4 {
		margin-bottom: 24px;
		font-size: 18px;
		font-weight: 600;
	}

	p + h3 {
		margin-top: 40px;
	}

	p + img {
		margin-top: 40px;
	}

	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;

		strong {
			font-weight: 700;
		}

		span {
			font-style: italic;
		}

		& + p {
			margin-top: 24px;
		}
	}

	ul,
	ol {
		margin: 24px 0;
		li {
			font-size: 16px;
			line-height: 160%;
		}
		::marker {
			color: var(--text-color-main);
			font-size: 1em;
		}
	}

	ul {
		list-style-type: disc;
		list-style-position: inside;
	}

	ol {
		list-style-type: numeric;
		list-style-position: inside;
	}

	a {
		padding-bottom: 1px;
		color: var(--main-color);
		border-bottom: 1px solid var(--main-color);
		box-sizing: border-box;

		&:hover {
			border-color: transparent;
		}
	}
}

.invoices {
	margin: 0 auto;
	padding: 32px 0;
	width: 540px;
	max-width: 100%;
}

.invoices-header {
	margin-bottom: 24px;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eaeaef;
	&__invoices {
		font-weight: 400;
		font-size: 18px;
		line-height: 150%;
		color: #9996be;
	}
}

.invoices-info {
	padding: 0 24px;
	&__item {
		margin-bottom: 8px;
		padding-bottom: 8px;
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		border-bottom: 1px solid #eaeaef;
		text-align: left;
		@media screen and (max-width: 440px) {
			flex-wrap: wrap;
			p {
				width: 100%;
			}
		}
	}
}

.invoices-timer-titel {
	padding: 0 24px;
	margin-top: 24px;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	text-align: left;
}

.invoices-timer {
	padding: 0 24px;
	display: grid;
	grid-template-columns: 1fr 1fr 3px 1fr 1fr 3px 1fr 1fr;
	align-items: center;
	gap: 6px;
	&__item {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 24px;
		border: 1px solid #eaeaef;
		border-radius: 12px;
		@media screen and (max-width: 480px) {
			height: 40px;
			font-size: 20px;
		}
	}
}

.invoices-total-info {
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	p {
		display: flex;
		justify-content: space-between;
		gap: 4px;
	}
	.fize2 {
		font-weight: 600;
		font-size: 16px;
	}
}

.main-info {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 32px;
	padding-bottom: 100px;
}

.main-info-category {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.input {
		margin-bottom: 0;
	}
}

.category-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 150%;
	color: #202020;
}

.input-line {
	display: flex;
	gap: 24px;
	&--type2 {
		@media screen and (max-width: 1100px) {
			flex-wrap: wrap;
			.input {
				width: 48%;
			}
		}
	}
	@media screen and (max-width: 650px) {
		flex-direction: column;
		gap: 16px;
		.input {
			width: 100%;
		}
	}
}

.category-title-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.kyc-document-interactionist {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 523px;
	min-height: 58px;
	padding: 16px;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	background: #f2f6ff;
}

.kyc-document-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
}

.kyc-document-buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.kyc-document-btn {
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	color: #4285f5;
	&--icon {
		display: flex;
		width: 24px;
	}
}

.balance-block {
	width: 100%;
	padding: 24px;
	border: 1px solid #eaeaef;
	border-radius: 12px;
	display: flex;
	align-items: center;
	gap: 24px;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-basis: 74px;
		min-width: 74px;
		height: 74px;
		background: #f2f6ff;
		border-radius: 12px;
	}
	&__name {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		color: #202020;
	}
	&__count {
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #202020;
	}

	@media screen and (max-width: 620px) {
		padding: 16px;
	}
}

.order-line {
	margin-bottom: 16px;
	padding-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eaeaef;
	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: var(--text-color2);
	}
}

.input-info-left {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	gap: 8px;
	button {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: #4285f5;
	}
	span {
		font-weight: 400;
		font-size: 16px;
		line-height: 160%;
		color: #9996be;
	}
}

.balances-section {
	padding: 65px 0;
}

.balances {
	margin-top: 26px;
}

.balances-info {
	margin-bottom: 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	text-align: left;
}

.green {
	color: #00ba88;
}

.red {
	color: #fd3232;
}

.grey {
	color: var(--text-color2);
}

.blue {
	color: #4285f5;
}

@import 'ui-modifiers.scss';
