@import 'assets/styles/scss/variables';

.select {
	position: relative;
	display: flex;
	align-content: center;
	background: #fff;
	z-index: 3;
	height: 50px;
	border: 1px solid $globalStrokeAndDivider;
	border-radius: 12px;
	&__button {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 8px;
	}
	&_active {
		z-index: 5;
		.select__drop {
			display: block;
		}
	}
	&__drop {
		overflow: hidden;
		display: none;
		position: absolute;
		left: 0;
		top: calc(100% + 10px);
		width: 100%;
		background: #ffffff;
		z-index: 1;
		border: 1px solid var(--border-color-main);
		border-radius: 12px;
		padding: 0;
		&Scroll {
			overflow: auto;
			scrollbar-color: #b6b6b6 transparent;
			scrollbar-width: thin;
			max-height: 188px;
		}
	}
	&Block {
		margin-bottom: 24px;
		&__name {
			margin-bottom: 8px;
			font-size: 14px;
			font-weight: 500;
			min-height: 21px;
		}
	}
	&__current {
		color: $globalTextSecondary;
		grid-gap: 10px;
		gap: 10px;
		padding: 0 16px;
		font-weight: 400;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		&Arrow {
			flex: 0 0 12px;
			max-width: 12px;
			justify-content: flex-end;
			margin-left: auto;
		}
	}
	&Info {
		margin-top: 8px;
		display: flex;
		gap: 8px;
		&__name {
			font-weight: 400;
			font-size: 14px;
			line-height: 165%;
			color: $globalTextSecondary;
		}
		&__number {
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 600;
			font-size: 16px;
			color: $globalTextColorMain;
		}
	}
}

.token {
	display: flex;
	align-items: center;
	text-align: left;
	&__icon {
		display: flex;
		width: 34px;
		margin-right: 12px;
	}
	&__name {
		margin-right: 8px;
		font-weight: 600;
		font-size: 16px;
		color: $globalTextColorMain;
	}
	&__transcription {
		font-weight: 400;
		font-size: 16px;
		color: $globalTextColorMain;
	}
}
