@import 'assets/styles/scss/variables';

.request {
	margin-top: 36px;
}

.title {
	margin-top: 36px;
	font-weight: 700;
	font-size: 20px;
	line-height: 150%;
}

.approvements {
	display: grid;
	grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
	grid-gap: 16px 64px;
	gap: 16px 64px;
	margin-top: 16px;
	&__column {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		gap: 16px;
	}
	&__item {
		display: flex;
		font-size: 16px;
		justify-content: flex-start;
		grid-gap: 16px;
		gap: 16px;
		align-items: center;
		&:not(:last-of-type) {
			border-bottom: 1px solid $button-border;
			padding-bottom: 16px;
		}
		&Status {
			text-align: right;
			margin-left: auto;
		}
	}
	& span {
		display: flex;
		grid-gap: 16px;
		gap: 16px;
	}
}

.requestInfo {
	padding: 16px 24px;
	font-size: 16px;
	line-height: 160%;
	background: $button-hover-close;
	border-radius: 12px;
	overflow-y: auto;
	overflow-x: hidden;
	word-break: break-word;
	scrollbar-color: $button-border;
	scrollbar-width: thin;
}
