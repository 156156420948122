@import 'assets/styles/scss/variables';

.table__buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
}

.tdCheck {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	svg {
		width: 18px;
		height: 18px;
		path {
			fill: $globalGreen;
		}
	}
}

.td_hiddenName {
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 165%;
	color: $globalTextSecondary;
	position: relative;
	display: none;
}

.token {
	display: flex;
	align-items: center;
	text-align: left;
	&__icon {
		display: flex;
		width: 34px;
		margin-right: 12px;
		&Type2 {
			width: 28px;
		}
	}
	&__name {
		margin-right: 8px;
		font-weight: 600;
		font-size: 16px;
		color: $globalTextColorMain;
	}

	&__transcription {
		font-weight: 400;
		font-size: 16px;
		color: $globalTextSecondary;
	}
	&Type2 {
		.token {
			&__icon {
				display: flex;
				width: 40px;
				margin-right: 12px;
			}
			&__name {
				font-weight: 500;
				font-size: 14px;
			}
			&__transcription {
				font-size: 14px;
			}
		}
	}
}

.switch {
	display: flex;
	align-items: center;
	&__toggler {
		position: relative;
		display: block;
		border: 1px solid $uiSwitchColor;
		border-radius: 13px;
		cursor: pointer;
		width: 36px;
		height: 20px;
		border-color: $globalStrokeAndDivider;
		background: $globalStrokeAndDivider;
		&:before {
			content: '';
			width: 14px;
			height: 14px;
			left: 3px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background: $globalWhite;
			border-radius: 100%;
			transition: 0.3s;
		}
	}
	input:checked ~ .switch__toggler {
		border-color: $globalMainBlue;
		background:  $globalMainBlue;
		&:before {
			left: 19px;
			background: $globalWhite;
		}
	}
	&__label {
		& + .switch__text {
			margin-left: 10px;
		}
	}
	&__text {
		font-size: 14px;
		font-weight: 500;
		& + .switch__label {
			margin-left: 10px;
		}
	}
	&Type2 {
		.switch__toggler {
			background: $uiSwitchColor;
			&:before {
				background: #fff;
			}
		}
		input:checked ~ .switch__toggler {
			background: $uiMainColor;
			&:before {
				background: #fff;
			}
		}
	}
}

.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	appearance: none;
}

.td {
	&Right {
		text-align: right;
	}
	&Value a {
		display: flex;
		align-items: center;
		gap: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 165%;
		color: $globalMainBlue;
		transition: 0.2s linear;

		&:hover,
		&:focus {
			color: darken(#4285f5, 10%);
		}

		&Type2 {
			justify-content: center;
			text-transform: uppercase;
		}

		svg {
			min-width: 23px;
		}
	}
}

@media screen and (max-width: 1199px) {
	.tr {
		grid-template-columns: 1fr minmax(100px, 1fr);
		grid-gap: 12px;
		gap: 12px;
		border-bottom: 1px solid #EAEAEF;
		padding-bottom: 16px;
	}
	.td {
		padding: 0;
		border-bottom: unset;
		display: block;
		&_hiddenName {
			display: block;
			text-align: left;
		}
		&Right {
			justify-content: left;
		}
	}
	.table__buttons {
		justify-content: left;
		display: flex;
	}
}

@media screen and (max-width: 620px) {
	.tr {
		display: flex;
		flex-wrap: wrap;
		grid-template-columns: repeat(1, 1fr);
	}
	.td {
		position: relative;
		width: 100%;
	}
}

@media screen and (max-width: 620px) {
	.tr {
		display: flex;
		flex-wrap: wrap;
		grid-template-columns: repeat(1, 1fr);
	}
	.td {
		position: relative;
		width: 100%;
	}
}
