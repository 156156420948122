@import 'assets/styles/scss/variables';

.header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 16px;
	margin-bottom: 16px;
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}
