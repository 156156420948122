@import 'assets/styles/scss/variables';

.approvement {
	display: flex;
	justify-content: space-between;
	grid-gap: 8px;
	gap: 8px;
	font-size: 14px;
	padding-bottom: 16px;
	border-bottom: 1px solid $button-border;
	&Last {
		border-bottom: none;
	}
	& p,
	& a {
		display: block;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		text-align: right;
	}
	& &__title {
		color: $globalTextSecondary;
		white-space: nowrap;
		min-width: 180px;
		text-align: left;
	}
}
