@import 'assets/styles/scss/variables';

.table {
	margin-top: 0;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 16px;
	gap: 16px;
	margin-bottom: 16px;
	&__title {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
	}
	.mb_0 {
		margin-bottom: 0;
	}
}
