@import 'assets/styles/scss/variables';

.tableBlock {
	padding-bottom: 24px;
}

.table {
	grid-template-columns: 1fr;
	border: none;
	padding-bottom: 24px;
	display: grid;
	align-items: flex-end;
	gap: 40px;
}
