/*fonts*/
@font-face {
	font-family: 'PlusJakartaSans';
	src: url('../../fonts/PlusJakartaSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'PlusJakartaSans';
	src: url('../../fonts/PlusJakartaSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
/*-fonts*/
/*options*/
*,
::after,
::before {
	box-sizing: border-box;
}

body {
	font-family: 'PlusJakartaSans', sans-serif;
	font-weight: 400;
	line-height: normal;
	margin: 0px;
	padding: 0px;
	background: #1c1c1e;
}

body::-webkit-scrollbar {
	width: 8px;
}

body::-webkit-scrollbar-thumb {
	background: linear-gradient(0deg, #00ede1 -1.47%, #006ce2 98.32%);
}

body::-webkit-scrollbar-track {
	background: #1c1c1e;
}

p {
	width: 100%;
	margin: 0px;
}

h1,
h2,
h3,
h4,
h5 {
	width: 100%;
	margin: 0px;
}

img {
	max-width: 100%;
}

ul,
ol {
	padding: 0px;
	margin: 0px;
}

li {
	list-style-type: none;
}

a {
	outline: none;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
	outline: none;
}

select {
	opacity: 1;
	border-radius: 0;
	font-weight: 400;
	font-family: 'PlusJakartaSans', sans-serif;
}
select:hover,
select:focus {
	outline: none;
	text-decoration: none;
}
textarea {
	font-weight: 400;
	font-family: 'PlusJakartaSans', sans-serif;
	resize: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	opacity: 1;
	resize: none;
	border-radius: 0;
}
textarea::-webkit-input-placeholder {
	color: #000;
}
textarea::-moz-placeholder {
	color: #000;
}
textarea:-ms-input-placeholder {
	color: #000;
}
textarea:-moz-placeholder {
	color: #000;
}
textarea:hover,
textarea:focus {
	outline: none;
	text-decoration: none;
}

input {
	font-weight: 400;
	font-family: 'PlusJakartaSans', sans-serif;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	opacity: 1;
	border-radius: 0;
}
input::-webkit-input-placeholder {
	color: #000;
}
input::-moz-placeholder {
	color: #000;
}
input:-ms-input-placeholder {
	color: #000;
}
input:-moz-placeholder {
	color: #000;
}
input:hover,
input:focus {
	outline: none;
	text-decoration: none;
}

button {
	font-family: 'PlusJakartaSans', sans-serif;
	font-weight: 600;
	cursor: pointer;
	border: none;
	background: none;
	padding: 0px;
}
button:hover,
button:focus {
	outline: none;
	text-decoration: none;
}

html,
body {
	height: 100%;
}

.container {
	height: 100%;
	width: 100%;
	max-width: 1310px;
	padding: 0 15px;
	margin: 0 auto;
}

.container.two {
	max-width: 650px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

main {
	flex: 1 0 auto;
}

footer {
	flex: 0 0 auto;
}

@media (max-width: 1440px) {
	.container {
		max-width: 1170px;
	}
}
@media (max-width: 1200px) {
	.container {
		max-width: 800px;
	}
}
@media (max-width: 800px) {
	.container {
		max-width: 500px;
		padding: 0px 20px;
	}
}
/*-options*/
/*footer*/
.footer {
	position: relative;
	padding: 24px 0px 8px 0px;
	background: #1c1c1e;
	margin-top: 40px;
}
.footer::before {
	content: '';
	position: absolute;
	z-index: -1;
	left: 50%;
	top: -200px;
	transform: translateX(-110%);
	width: 300px;
	height: 300px;
	border-radius: 50%;
	background: linear-gradient(0deg, #00ede1 -1.47%, #007aff 98.32%);
	opacity: 0.4;
	filter: blur(250px);
}

.footer-wrap {
	position: relative;
}

.footer-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.footer-terms {
	font-weight: 400;
	font-size: 12px;
	line-height: 160%;
	color: rgba(165, 176, 196, 0.6);
	margin-top: 24px;
}
.footer-terms a {
	color: rgba(165, 176, 196, 0.6);
	text-decoration: underline;
	transition: 0.1s;
}

.footer-terms a:hover {
	color: #00ede1;
}

.footer-buttons {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
}

.footer-support {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 170%;
	color: #a5b0c4;
	margin-right: 40px;
	transition: 0.1s;
}

.footer-support:hover {
	color: #00ede1;
}

.footer-lang {
	position: relative;
	z-index: 1;
}

.footer-lang_point {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 400;
	font-size: 14px;
	line-height: 125%;
	color: #a5b0c4;
	padding: 4px 8px 4px 12px;
	border-radius: 28px;
	transition: 0.3s;
}
.footer-lang_point svg {
	fill: #a5b0c4;
	margin-left: 4px;
}

.footer-lang:hover .footer-lang_point {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #1c1c1e;
}

.footer-lang_wrap {
	position: absolute;
	left: 0;
	bottom: 100%;
	width: 100%;
	padding-bottom: 9px;
	display: none;
}

.footer-lang_list {
	position: relative;
	width: 100%;
	padding: 20px 0px;
}
.footer-lang_list::after {
	content: '';
	position: absolute;
	z-index: -1;
	left: 4px;
	top: 4px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	background: linear-gradient(#0e0e0f, #0e0e0f) padding-box,
		linear-gradient(to top, #00ede1, #006ce2) border-box;
	border-radius: 20px;
	border: 1px solid transparent;
}
.footer-lang_list::before {
	content: '';
	position: absolute;
	z-index: -2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 24px;
	background: #0e0e0f;
}
.footer-lang_list li {
	margin-bottom: 16px;
}
.footer-lang_list li:last-child {
	margin-bottom: 0px;
}
.footer-lang_list a {
	display: block;
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	line-height: 125%;
	color: #ffffff;
	text-align: center;
	transition: 0.1s;
}
.footer-lang_list li.active a {
	color: #00ede1;
}

.footer-lang_list a:hover {
	color: #00ede1;
}

@media (max-width: 800px) {
	.footer-box {
		align-items: flex-start;
		justify-content: flex-start;
	}

	.footer-support {
		margin-right: 20px;
	}

	.footer-logo {
		margin-top: 5px;
	}
	.footer-logo img {
		width: 130px;
		height: 25px;
	}
}
/*-footer*/
/*payment*/
.payment {
	margin-top: 48px;
}

.payment-top {
	position: relative;
	display: flex;
	justify-content: flex-end;
	min-height: 120px;
}

.payment-back {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 170%;
	color: #a5b0c4;
	transition: 0.1s;
}
.payment-back svg {
	fill: #a5b0c4;
	margin-right: 4px;
	transition: 0.1s;
}

.payment-back:hover {
	color: #00ede1;
}
.payment-back:hover svg {
	fill: #00ede1;
}

.payment-label {
	position: absolute;
	left: 0;
	bottom: 0;
	max-width: 220px;
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
	white-space: pre-wrap;
}
.payment-label span {
	color: #a2adbe;
}

.payment-logo {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 88px;
	height: 88px;
	background: linear-gradient(180deg, rgba(97, 121, 238, 0.3) 0%, rgba(97, 121, 238, 0) 100%);
	border-radius: 50%;
}
.payment-logo:before {
	content: '';
	position: absolute;
	z-index: -1;
	left: -2px;
	top: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	border-radius: 50%;
	background: linear-gradient(180deg, rgba(97, 121, 238, 0.5) 0%, rgba(97, 121, 238, 0) 100%);
}
.payment-logo img {
	width: 56px;
	height: 56px;
	border-radius: 50%;
}

.payment-info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.payment-site {
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
}

.payment-price {
	font-weight: 600;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.03em;
	color: #00ede1;
	margin-top: 8px;
}

.payment-order li {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #a2adbe;
	margin-top: 8px;
}
.payment-order li > span {
	display: block;
	margin-left: 6px;
}

.payment-box {
	margin-top: 24px;
}
.payment-box *:first-child {
	margin-top: 0px;
}

.payment-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 8px;
	grid-column-gap: 8px;
}

.payment-list.two {
	max-width: 306px;
	grid-template-columns: 1fr;
	margin: auto;
}

.payment-list_point {
	position: relative;
	min-height: 78px;
	background: rgba(26, 26, 28, 0.85);
	border: 1px solid #444445;
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
	padding: 16px 16px 16px 72px;
	cursor: pointer;
	transition: 0.1s;
}

.payment-list_point:hover {
	background: #0e0e0f;
}

.payment-list_logo {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
}
.payment-list_logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.payment-list_name {
	display: block;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
	white-space: pre-wrap;
}

.payment-list_number {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 170%;
	color: #a2adbe;
}

.payment-time {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}

.payment-time_line {
	width: 100%;
	height: 8px;
	background: rgba(165, 176, 196, 0.23);
	border-radius: 6px;
}
.payment-time_line span {
	display: block;
	height: 100%;
	background: linear-gradient(270deg, #00ede1 0%, #007aff 100%);
	border-radius: 6px;
}

.payment-time_text {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 170%;
	color: #ffffff;
	margin-top: 12px;
}
.payment-time_text span {
	display: block;
	color: #00ede1;
	margin-left: 8px;
}

.payment-link {
	margin: 40px auto 0px auto;
}

.payment-tooltip {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
}
.payment-tooltip svg {
	fill: #a5b0c4;
}

.payment-tooltip_text {
	position: absolute;
	z-index: 1;
	background: rgba(0, 108, 226, 0.3);
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
	color: #ffffff;
	text-align: center;
	width: fit-content;
	white-space: nowrap;
	padding: 7px 12px;
	bottom: 135%;
	left: 50%;
	font-size: 11px;
	transform: translateX(-50%);
	opacity: 0;
	transition: opacity 0.3s;
	margin-left: 0px;
}

.payment-tooltip_text::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	border-width: 5px;
	border-style: solid;
	border-color: rgba(0, 108, 226, 0.3) transparent transparent transparent;
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
}

.payment-tooltip:hover svg {
	fill: #00ede1;
}
.payment-tooltip:hover .payment-tooltip_text {
	visibility: visible;
	opacity: 1;
}

.payment-send {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}

.payment-code {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 136px;
	height: 136px;
}
.payment-code img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.payment-content {
	width: 437px;
}

.payment-sub {
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
}

.payment-us {
	display: block;
	width: fit-content;
	font-weight: 600;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.03em;
	color: #00ede1;
	border-bottom: 1px dashed #00ede1;
	margin-top: 8px;
}

.payment-number {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #a5b0c4;
	margin-top: 8px;
}

.payment-addess {
	width: 100%;
	margin-top: 24px;
}

.payment-addess_label {
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #a5b0c4;
	margin-bottom: 12px;
}

.payment-addess_wrap {
	position: relative;
	display: block;
	width: 100%;
}

.payment-addess_input {
	width: 100%;
	height: 54px;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
	background: rgba(26, 26, 28, 0.85);
	border: 1px solid #444445;
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
	outline: none;
	padding: 0px 50px 0px 16px;
	pointer-events: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.payment-addess_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 2;
	right: 16px;
	bottom: 15px;
}
.payment-addess_btn svg {
	fill: #a5b0c4;
	transition: 0.1s;
}

.payment-addess_btn:hover svg {
	fill: #00ede1;
}

.payment-addess_wrap.active .payment-addess_input {
	color: #00ede1;
}
.payment-addess_wrap.active .payment-addess_btn svg {
	fill: #00ede1;
}

.payment-addess_tooltip {
	position: absolute;
	right: 0;
	top: -40px;
	background: rgba(0, 108, 226, 0.3);
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
	color: #ffffff;
	text-align: center;
	width: fit-content;
	white-space: nowrap;
	padding: 7px 12px;
	font-size: 11px;
	opacity: 0;
	transform: translateY(5px);
	transition: 0.3s;
}

.payment-addess_tooltip.active {
	opacity: 1;
	transform: translateY(0);
}

.payment-error {
	position: relative;
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #ff5a35;
	padding-left: 24px;
	margin-top: 24px;
}
.payment-error svg {
	position: absolute;
	left: 0;
	top: 3px;
}

.payment-notify {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 306px;
	margin: 40px auto 0px auto;
}

.payment-notify_label {
	color: #a5b0c4;
	font-size: 14px;
	display: block;
	font-weight: 400;
	line-height: 170%;
	margin-bottom: 8px;
}

.payment-notify_error {
	position: absolute;
	right: 0;
	bottom: -22px;
	font-size: 14px;
	color: #e200ac;
	display: none;
}

.payment-notify_input {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 16px;
}

.payment-notify_point {
	width: 100%;
	height: 48px;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #1c1c1e;
	padding: 0px 16px;
	background: #ffffff;
	border: 1px solid #bfc9d9;
	border-radius: 4px;
}

.payment-notify_point:focus {
	border: 2px solid #006ce2;
}

.payment-notify_input.active .payment-notify_error {
	display: block;
}
.payment-notify_input.active .payment-notify_label {
	color: #e200ac;
}
.payment-notify_input.active .payment-notify_point {
	border: 2px solid #e200ac;
}

.payment-notify_btn {
	margin-top: 8px;
}

.payment-thank {
	position: relative;
	z-index: 1;
	display: block;
	max-width: 306px;
	padding: 16px 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	color: #a5b0c4;
	margin: 24px auto 0px auto;
	border-radius: 10px;
	overflow: hidden;
}
.payment-thank::after {
	content: '';
	position: absolute;
	z-index: -1;
	left: 1px;
	top: 1px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	background: rgb(26, 26, 28);
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
}
.payment-thank:before {
	content: '';
	position: absolute;
	z-index: -2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, #00ede1 -1.47%, #007aff 98.32%);
}

.payment-conf {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.payment-title {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 36px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #ffffff;
}
.payment-title img {
	width: 50px;
	height: 50px;
	margin-right: 12px;
}

.payment-text {
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #ffffff;
	max-width: 306px;
	margin-top: 40px;
}
.payment-text span {
	color: #a5b0c3;
}

.payment-history {
	max-width: 370px;
	margin: 40px auto 0px auto;
}

.payment-inv {
	display: block;
	font-weight: 600;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.03em;
	color: #ffffff;
	text-align: center;
}

.payment-history_list {
	margin-top: 40px;
}

.payment-history_point {
	position: relative;
	padding-left: 64px;
	margin-bottom: 40px;
}
.payment-history_point::before {
	content: '';
	position: absolute;
	left: 19px;
	top: 44px;
	width: 1px;
	height: calc(100% - 8px);
	background: #a5b0c4;
}

.payment-history_point:last-child {
	margin-bottom: 0px;
}
.payment-history_point:last-child::before {
	display: none;
}

.payment-history_img {
	position: absolute;
	left: 0;
	top: 0;
	width: 40px;
	height: 40px;
}

.payment-history_name {
	display: block;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
}

.payment-history_number {
	display: block;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: #00ede1;
	margin-top: 4px;
}

.payment-history_date {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #a5b0c4;
	margin-top: 4px;
}

.payment-history_id {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 160%;
	color: #a5b0c4;
	overflow: hidden;
}
.payment-history_id span {
	font-weight: 400;
	font-size: 12px;
	line-height: 160%;
	color: #00ede1;
	border-bottom: 1px dashed #00ede1;
	width: 100%;
	margin-left: 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.payment-warng {
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid #444445;
	backdrop-filter: blur(7.5px);
	-webkit-backdrop-filter: blur(7.5px);
	border-radius: 10px;
	padding: 16px 16px 16px 72px;
	margin-top: 24px;
}

.payment-warng_icon {
	position: absolute;
	left: 16px;
	top: 16px;
}

.payment-warng_text {
	display: block;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #ffffff;
}
.payment-warng_text strong {
	font-weight: 700;
	color: #00ede1;
}

.payment-btn {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	font-weight: 600;
	font-size: 18px;
	line-height: 125%;
	color: #ffffff;
	border-radius: 28px;
	padding: 13px 28px 14px 28px;
	border: 1px solid #a5b0c4;
	overflow: hidden;
	transition: 0.3s;
}

.payment-btn:hover {
	border: 1px solid #006ce2;
}

@media (max-width: 800px) {
	.payment {
		margin-top: 40px;
	}

	.payment-list {
		grid-template-columns: 1fr;
	}

	.payment-list_point {
		padding: 10px 10px 10px 72px;
	}

	.payment-top {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 100%;
		padding-top: 100px;
	}

	.payment-label {
		position: static;
		max-width: 100%;
		text-align: center;
		margin-bottom: 8px;
	}

	.payment-info {
		align-items: center;
	}

	.payment-order li {
		justify-content: center;
	}

	.payment-send {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.payment-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 430px;
		margin-top: 24px;
	}

	.payment-addess_input {
		font-size: 14px;
	}

	.payment-addess_label {
		font-size: 14px;
	}

	.payment-title {
		font-size: 24px;
	}
	.payment-title img {
		width: 36px;
		height: 36px;
	}
}
